import React, { useState, useEffect, useContext } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import InputAdornment from '@mui/material/InputAdornment'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Modal, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import '../dashboard/filters.css'
import { strFetch } from '../../utils/api'
import _ from 'lodash'
import { useSearchParams, useNavigate } from 'react-router-dom'
import useDemoMode from 'hooks/useDemoMode'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import useGlobal from 'hooks/useGlobal'
import { track } from 'mixpanel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />
const regulations = ['Little to none', 'Moderate', 'Heavy', 'Restricted']
const destinations = [
  'beach',
  'mountain',
  'ski',
  'college',
  'river',
  'lake',
  'national park',
  'state park',
  'theme park',
  'historical',
  'city',
]

const marketSizes = ['small', 'medium', 'large']

// 0 is not available as we don't need to show 
// Studios that are out in the market
const bedroomCounts = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
]

const regions = [
  'northeast',
  'new england',
  'mid-atlantic',
  'midwest',
  'south',
  'mountain west',
  'pacific',
]

const states = [
  {
    state: 'Iowa',
    abbreviation: 'IA',
    region: 'Midwest',
  },
  {
    state: 'Illinois',
    abbreviation: 'IL',
    region: 'Midwest',
  },
  {
    state: 'Indiana',
    abbreviation: 'IN',
    region: 'Midwest',
  },
  {
    state: 'Kansas',
    abbreviation: 'KS',
    region: 'Midwest',
  },
  {
    state: 'Michigan',
    abbreviation: 'MI',
    region: 'Midwest',
  },
  {
    state: 'Minnesota',
    abbreviation: 'MN',
    region: 'Midwest',
  },
  {
    state: 'Missouri',
    abbreviation: 'MO',
    region: 'Midwest',
  },
  {
    state: 'North Dakota',
    abbreviation: 'ND',
    region: 'Midwest',
  },
  {
    state: 'Nebraska',
    abbreviation: 'NE',
    region: 'Midwest',
  },
  {
    state: 'Ohio',
    abbreviation: 'OH',
    region: 'Midwest',
  },
  {
    state: 'South Dakota',
    abbreviation: 'SD',
    region: 'Midwest',
  },
  {
    state: 'Wisconsin',
    abbreviation: 'WI',
    region: 'Midwest',
  },
  {
    state: 'Connecticut',
    abbreviation: 'CT',
    region: 'Northeast',
  },
  {
    state: 'Delaware',
    abbreviation: 'DE',
    region: 'Northeast',
  },
  {
    state: 'Massachusetts',
    abbreviation: 'MA',
    region: 'Northeast',
  },
  {
    state: 'Maine',
    abbreviation: 'ME',
    region: 'Northeast',
  },
  {
    state: 'Maryland',
    abbreviation: 'MD',
    region: 'Northeast',
  },
  {
    state: 'New Hampshire',
    abbreviation: 'NH',
    region: 'Northeast',
  },
  {
    state: 'New Jersey',
    abbreviation: 'NJ',
    region: 'Northeast',
  },
  {
    state: 'New York',
    abbreviation: 'NY',
    region: 'Northeast',
  },
  {
    state: 'Pennsylvania',
    abbreviation: 'PA',
    region: 'Northeast',
  },
  {
    state: 'Rhode Island',
    abbreviation: 'RI',
    region: 'Northeast',
  },
  {
    state: 'Vermont',
    abbreviation: 'VT',
    region: 'Northeast',
  },
  {
    state: 'Alabama',
    abbreviation: 'AL',
    region: 'South',
  },
  {
    state: 'Arkansas',
    abbreviation: 'AR',
    region: 'South',
  },
  {
    state: 'District of Columbia',
    abbreviation: 'DC',
    region: 'South',
  },
  {
    state: 'Florida',
    abbreviation: 'FL',
    region: 'South',
  },
  {
    state: 'Georgia',
    abbreviation: 'GA',
    region: 'South',
  },
  {
    state: 'Kentucky',
    abbreviation: 'KY',
    region: 'South',
  },
  {
    state: 'Louisiana',
    abbreviation: 'LA',
    region: 'South',
  },
  {
    state: 'Mississippi',
    abbreviation: 'MS',
    region: 'South',
  },
  {
    state: 'North Carolina',
    abbreviation: 'NC',
    region: 'South',
  },
  {
    state: 'Oklahoma',
    abbreviation: 'OK',
    region: 'South',
  },
  {
    state: 'South Carolina',
    abbreviation: 'SC',
    region: 'South',
  },
  {
    state: 'Tennessee',
    abbreviation: 'TN',
    region: 'South',
  },
  {
    state: 'Texas',
    abbreviation: 'TX',
    region: 'South',
  },
  {
    state: 'Virginia',
    abbreviation: 'VA',
    region: 'South',
  },
  {
    state: 'West Virginia',
    abbreviation: 'WV',
    region: 'South',
  },
  {
    state: 'Alaska',
    abbreviation: 'AK',
    region: 'West',
  },
  {
    state: 'Arizona',
    abbreviation: 'AZ',
    region: 'West',
  },
  {
    state: 'California',
    abbreviation: 'CA',
    region: 'West',
  },
  {
    state: 'Colorado',
    abbreviation: 'CO',
    region: 'West',
  },
  {
    state: 'Hawaii',
    abbreviation: 'HI',
    region: 'West',
  },
  {
    state: 'Idaho',
    abbreviation: 'ID',
    region: 'West',
  },
  {
    state: 'Montana',
    abbreviation: 'MT',
    region: 'West',
  },
  {
    state: 'New Mexico',
    abbreviation: 'NM',
    region: 'West',
  },
  {
    state: 'Nevada',
    abbreviation: 'NV',
    region: 'West',
  },
  {
    state: 'Oregon',
    abbreviation: 'OR',
    region: 'West',
  },
  {
    state: 'Utah',
    abbreviation: 'UT',
    region: 'West',
  },
  {
    state: 'Washington',
    abbreviation: 'WA',
    region: 'West',
  },
  {
    state: 'Wyoming',
    abbreviation: 'WY',
    region: 'West',
  },
]

interface DashboardFilterProps {
  filters: any
  filterdStates?: string[]
  useBedrooms: boolean
}

const DashboardFiltersMapMode = (props: DashboardFilterProps) => {
  let isDemo = useDemoMode()

  const defaultFilterStateGroupsOpen = {
    West: false,
    Midwest: false,
    Northeast: false,
    South: false,
  }

  const defaultFilterStateGroupsChecked = {
    West: false,
    Midwest: false,
    Northeast: false,
    South: false,
  }
  const sendDataToGTM = useGTMDispatch()
  const [showDemoModal, setShowDemoModal] = useState(false)

  const [filterCities, setFilterCities] = useState<string[]>([])
  const [filterStateGroupsOpen, setFilterStateGroupsOpen] = useState<any>(
    defaultFilterStateGroupsOpen
  )
  const [filterStateGroupsChecked, setFilterStateGroupsChecked] = useState<any>(
    defaultFilterStateGroupsChecked
  )
  const { state: global, dispatch } = useGlobal()

  let navigate = useNavigate()

  useEffect(() => {
    ; (async function getData() {
      if (isDemo) {
        return
      }
      const strippedFilters = _.omit(props.filters, 'cities')
      const result = await strFetch(`/getFilterCities`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filterdStates: props.filterdStates,
          filters: strippedFilters,
        }),
      })
      const cityRows = result.map((city: any) => city.city_name)
      setFilterCities(cityRows.sort())
    })()
  }, [props.filterdStates])

  useEffect(() => {
    dispatch.setFilters('marketSize', null)
    dispatch.search()
  }, [])

  function sendToGTM() {
    sendDataToGTM({
      event: 'DemoGetStarted',
    })
  }

  function doDashSearch() {
    dispatch.search();
    track('Dashboard Search Map Mode', {
      user: {
        name: localStorage.getItem('userName'),
        email: localStorage.getItem('email'),
      }
    })
  }

  function clearDash() {
    dispatch.clearFilters()
    // dispatch.setFilters('regulationLevels', [])
    // dispatch.setFilters('minPrice', null)
    // dispatch.setFilters('maxPrice', null)
  }

  return (
    <Container
      maxWidth={'md'}
      style={{paddingTop: 20}}
    >
      <Modal
        disableAutoFocus={true}
        open={showDemoModal}
        onClose={() => setShowDemoModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Get Started!
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Sign up to filter by city
          </Typography>
          <Button
            variant='contained'
            style={{ marginTop: 20 }}
            onClick={() => {
              sendToGTM()
              setShowDemoModal(false)
              navigate('/login')
            }}
          >
            Get Started
          </Button>
        </Box>
      </Modal>
      <Accordion className='dashboard-filterer-container-map-mode'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Stack sx={{ width: '100%' }} spacing={2}>
        <Stack
          direction={{ xs: 'column', md: 'column' }}
          sx={{ width: '100%' }}
          spacing={2}
          justifyContent='space-evenly'
        >
          <Autocomplete
            size='small'
            sx={{ flexGrow: 1, flexBasis: 0 }}
            multiple
            id='destination-types'
            options={destinations}
            disableCloseOnSelect
            value={_.get(global, 'filters.destinationType', [])}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ textTransform: 'capitalize' }}
            renderInput={(params) => (
              <TextField {...params} label='Destination Types' />
            )}
            onChange={(event: any, value: string[]) => {
              dispatch.setFilters('destinationType', value)
            }}
          />

          <Autocomplete
            size='small'
            sx={{ flexGrow: 1, flexBasis: 0 }}
            multiple
            id='market-sizes'
            value={global.filters.marketSize || []}
            options={marketSizes}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ textTransform: 'capitalize' }}
            renderInput={(params) => (
              <TextField {...params} label='Market Size' />
            )}
            onChange={(event: any, value: string[]) => {
              dispatch.setFilters('marketSize', value)
            }}
          />

          <Autocomplete
            size='small'
            sx={{ flexGrow: 1, flexBasis: 0, minWidth: 175 }}
            multiple
            id='regulations'
            options={regulations}
            disableCloseOnSelect
            value={
              global.filters.regulationLevels
                ? global.filters.regulationLevels
                : []
            }
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ textTransform: 'capitalize' }}
            renderInput={(params) => (
              <TextField {...params} label='Regulation Amount' />
            )}
            onChange={(event: any, value: string[]) => {
              dispatch.setFilters('regulationLevels', value)
            }}
          />
          <TextField
            id='price-from'
            placeholder='Min price'
            size='small'
            sx={{ flexGrow: 1, flexBasis: 0 }}
            type='number'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            value={global.filters.minPrice ? global.filters.minPrice : ''}
            variant='outlined'
            onChange={(event: any) => {
              const parsedValue = _.isNil(event.target.value)
                ? 0
                : event.target.value.replace(/[^0-9]/g, '')
              dispatch.setFilters('minPrice', parsedValue)
            }}
          />
          <TextField
            id='price-to'
            placeholder='Max price'
            size='small'
            sx={{ flexGrow: 1, flexBasis: 0 }}
            type='number'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            value={global.filters.maxPrice ? global.filters.maxPrice : ''}
            variant='outlined'
            onChange={(event: any) => {
              const parsedValue = _.isNil(event.target.value)
                ? 0
                : event.target.value.replace(/[^0-9]/g, '')
              dispatch.setFilters('maxPrice', parsedValue)
            }}
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: '100%' }}
          spacing={2}
          justifyContent='space-evenly'
        >
        <Button
            className='linear-gradient-styling'
            variant='outlined'
            onClick={clearDash}
            size='medium'
            sx={{ flexGrow: 1, flexBasis: 0, minWidth: 100 }}
          >
            Clear
          </Button>
          <Button
            className='linear-gradient-styling'
            variant='contained'
            onClick={doDashSearch}
            size='medium'
            sx={{ flexGrow: 1, flexBasis: 0, minWidth: 100 }}
          >
            Apply Filters
          </Button>
        </Stack>

      </Stack>
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
}

export { DashboardFiltersMapMode }
