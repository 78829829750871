export const CheckmarkFilled = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.2955 9.60549L11.7275 15.6055C11.5395 15.8525 11.2485 15.9985 10.9385 16.0005H10.9315C10.6245 16.0005 10.3345 15.8585 10.1445 15.6165L7.71249 12.5095C7.37249 12.0755 7.44849 11.4465 7.88349 11.1065C8.31749 10.7655 8.94749 10.8415 9.28749 11.2775L10.9205 13.3635L14.7045 8.39449C15.0385 7.95549 15.6655 7.86949 16.1065 8.20449C16.5455 8.53949 16.6305 9.16649 16.2955 9.60549ZM12.0005 2.00049C6.47749 2.00049 2.00049 6.47749 2.00049 12.0005C2.00049 17.5225 6.47749 22.0005 12.0005 22.0005C17.5235 22.0005 22.0005 17.5225 22.0005 12.0005C22.0005 6.47749 17.5235 2.00049 12.0005 2.00049Z'
        fill='#4DDCB7'
      />
    </svg>
  )
}
