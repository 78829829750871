import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import SearchIcon from '@mui/icons-material/Search'
import _ from 'lodash'
import { strFetch } from 'utils/api'
import useGlobal from 'hooks/useGlobal'
import { useLocation, useNavigate } from 'react-router-dom';

export default function FreeSolo() {
  const [searchResults, setSearchResults] = useState<any>([])
  const { state: global, dispatch } = useGlobal()
  const [currentSearch, setCurrentSearch] = useState<string>()
  let navigate = useNavigate()
  let location = useLocation()

  const updateSearchResults = _.debounce(async (term: String) => {
    const result = await strFetch(`/search/city/${term.split(',')[0].trim()}`, {
      headers: { 'Content-Type': 'application/json' },
    })
    setSearchResults(result)
  }, 200)

  function keyPress(e:any) {
    if(e.keyCode === 13){
      setCurrentSearch(e.target.value)

      const term = e.target.value.split(',')[0].trim();
      const state = e.target.value.split(',')[1].trim();

      strFetch(`/search/city/${term}`, {
        headers: { 'Content-Type': 'application/json' },
      }).then((val: any[]) => {
        if (val) {
          let filteredVals = val.filter(c => term === c.city_name && (state === c.state || state === c.state_abbr))
          if (filteredVals) {
            if (_.includes(['/market-history'], location.pathname)) {
              navigate(`/market-history?city=${filteredVals[0].city_name}&state=${filteredVals[0].state}`)
            } else {
              navigate(`/city?city=${filteredVals[0].city_name}&state=${filteredVals[0].state}`)
            }
            dispatch.setStateAndCityFilters(filteredVals[0].city_name, filteredVals[0].state)
          }
        }
      })
    }
 }
  return (
    <Autocomplete
    sx={{ width: '250px' }}
    freeSolo
      id='search-city'
      disableClearable
      onChange={(event, value) => {
        if (_.includes(['/market-history'], location.pathname)) {
          navigate(`/market-history?city=${value.city_name}&state=${value.state}`)
        } else {
          navigate(`/city?city=${value.city_name}&state=${value.state}`)
        }
        dispatch.setStateAndCityFilters(value.city_name, value.state)
      }}
      inputValue={currentSearch}
      options={searchResults}
      filterOptions={createFilterOptions({ limit: 20 })}
      getOptionLabel={(option: any) => {
        if (currentSearch) {
          return `${currentSearch}`
        }
        if (option.zip_code) {
          return `${option.city_name}, ${option.state_abbr}, ${option.zip_code}`
        } else {
          return `${option.city_name}, ${option.state_abbr}`
        }
      }
      }
      onKeyDown={(e) => keyPress(e)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search city, state'
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: <SearchIcon fontSize='small' />,
          }}
          onChange={(event) => {
            setCurrentSearch('')
            const value = _.get(event, 'target.value', '')
            if (!value) return
            updateSearchResults(value)
          }}
        />
      )}
      size='small'
      renderOption={(props, option, { inputValue }) => {
        let key;
        if (option.zip_code) {
          key = `${option.zip_code} - ${option.city_name}, ${option.state_abbr}`
        } else {
          key = `${option.city_name}, ${option.state_abbr}`
        }
        const matches = match(key, inputValue)
        const parts = parse(key, matches)

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}