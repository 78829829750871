import GppBadRoundedIcon from '@mui/icons-material/GppBadRounded'

const Heavy = () => {
  return (
    <div
      style={{
        backgroundColor: '#ffedcc',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
      }}
    >
      <GppBadRoundedIcon fontSize='large' sx={{ color: '#f59f00' }} />
    </div>
  )
}

export default Heavy
