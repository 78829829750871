import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from 'react-router-dom'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import CheckBox from '@mui/icons-material/CheckBox'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { strFetch } from '../../utils/api'
import logo from '../../images/logo.png'

const theme = createTheme({
  palette: {
    primary: {
      main: '#4DDBB7',
      contrastText: 'white',
    },
    secondary: {
      main: '#FF9A6C',
    },
  },
})

export default function Support() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [isSent, setIsSent] = useState(false)

  const handleSubmit = async () => {
    const data = {
      firstName,
      lastName,
      email,
      phone,
      message,
    }
    const result = await strFetch(
      '/support/email',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
        },
      },
      false
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs'>
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            noWrap
            component='div'
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          ></Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: 'black',
                fontSize: '30px',
                fontWeight: '700',
                marginRight: '4px',
              }}
            >
              Contact
            </span>
            <span
              style={{
                fontSize: '30px',
                fontWeight: '700',
                color: 'rgb(51,208,209)',
              }}
            >
              Support
            </span>
          </div>

          <Typography
            component='p'
            style={{ color: '8C96AE', fontSize: '15px' }}
          >
            Fill in the information below to contact our experts.
          </Typography>
          <Box
            style={{
              border: 'solid 1px #aaa',
              padding: '16px',
              borderRadius: '8px',
              background: 'white',
            }}
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='firstName'
              label='First name'
              name='firstName'
              autoComplete='First name'
              onChange={(event) => {
                setFirstName(event.target.value)
              }}
              autoFocus
            />

            <TextField
              margin='normal'
              required
              fullWidth
              id='lastName'
              label='Last name'
              name='lastName'
              autoComplete='Last name'
              onChange={(event) => {
                setLastName(event.target.value)
              }}
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='username'
              label='Email Address'
              name='username'
              autoComplete='email'
              onChange={(event) => {
                let isValid = event.target.value.match(
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
                if (isValid) {
                  setEmail(event.target.value)
                }
              }}
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='phone'
              label='Phone Number'
              type='phone'
              id='phone'
              onChange={(event) => {
                setPhone(event.target.value)
              }}
              autoComplete='phone'
            />

            <TextField
              id='outlined-multiline-static'
              label='Message'
              multiline
              rows={4}
              sx={{ width: '100%', marginTop: '16px' }}
              onChange={(event) => {
                setMessage(event.target.value)
              }}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disabled={!(firstName && lastName && email && !isSent)}
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                handleSubmit()
                setIsSent(true)
              }}
            >
              {isSent ? 'Message Sent!' : 'Send Message'}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
