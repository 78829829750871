import { Box, Button, Divider, Grid, SwipeableDrawer, Card, ButtonGroup, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';
import { theme } from "styles/themes";
import { strFetch } from "utils/api";

const COLORS = ['#0000FF', '#4B0082', '#FF7F00', '#FF0000'];

export default function MarketHistory(props: any) {
    const navigate = useNavigate()

    const [open, setIsOpen] = useState<boolean>(false)
    const [infoType, setInfoType] = useState<string>('')
    const [history, setHistories] = useState<any>([])
    const [averageHistories, setAverageHistories] = useState<any[]>([])
    const [bedroomHistories, setBedroomHistories] = useState<any[]>([])
    const [overallMarketMode, setOverallMarketMode] = useState<boolean>(true)
    const [bedroomCounts, setBedroomCounts] = useState<string[]>([])
    const [bedroomCountSelected, setBedroomCountSelected] = useState<string>()
    const [visualizationSelected, setVisualizationSelected] = useState<string>('Line Chart')
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        strFetch(`/market-history/${props.state}/${props.cityName}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((history) => {
            setHistories(history.averageMarketHistory)
            setBedroomHistories(history.bedroomMarketHistory)
            setAverageHistories(history.averageMarketHistory)
            const bedroomCountsToUse = history.bedroomMarketHistory.map((a: { bedroomCount: number; }) => a.bedroomCount)
                .filter((item: any, i: any, ar: string | any[]) => ar.indexOf(item) === i).sort(function (a: number, b: number) {
                    return a - b;
                });;

            setBedroomCounts(bedroomCountsToUse)
            setBedroomCountSelected(bedroomCountsToUse[0])
            setLoading(false)
        })

    }, [props.cityName, props.state])

    useEffect(() => {
        if (!overallMarketMode) {
            setHistories(bedroomHistories.filter(a => a.bedroomCount === bedroomCountSelected))
        }
    }, [bedroomCountSelected])


    // TODO Review this DIONTRE, you may not need them all
    function getInfoDescription(label: string): string {
        if (label === 'revenue') {
            return 'The yearly revenue at the selected percentile for all properties that are listed at least 200 days a year. Yearly revenue for properties is calculated by aggregating the daily booking rate across all booked dates. Revenue does not include cleaning fees, taxes, or site fees.'
        }
        if (label === 'roi' || label === 'gross roi') {
            return 'ROI for a bedroom count in a market is determined by dividing the average revenue by the average valuation for that bedroom in the given market.'
        }
        if (label === 'valuation') {
            return 'The average home valuation of properties with the given bedroom count that we have identified as short term rentals in this market. '
        }
        if (label === 'active listing count') {
            return 'The total number of active Airbnb and VRBO listings with the given bedroom count in this market.'
        }
        if (label === 'bedroom count toggle') {
            return 'Select "Market" to view market-wide estimates. Select "Bedroom Count" to view estimates at the bedroom-count level.'
        }
        if (label === 'occupancy') {
            return 'The average yearly occupancy for a given property grouping'
        }
        if (label === 'adr') {
            return 'Average Daily Rate'
        }
        if (label === 'bedrooms') {
            return 'The bedroom count for a given property group'
        }
        if (label === 'regulation') {
            //   return getDescription()
        }
        if (label === 'realtor') {
            return 'This is our recommended STR realtor that knows the regulation of this market as well as owns active short term rental properties.'
        }
        if (label === 'lender') {
            return 'This is our recommended lender who specializes in loan products for short term rentals.'
        }

        return 'N/A'
    }

    function getByPercentileNumber(isROI: boolean) {
        if (props.revenuePercentile === 50) {
            return isROI ? 'roi50' : 'revenue50';
        }
        if (props.revenuePercentile === 60) {
            return isROI ? 'roi60' : 'revenue60';
        }
        if (props.revenuePercentile === 70) {
            return isROI ? 'roi70' : 'revenue70';
        }
        if (props.revenuePercentile === 75) {
            return isROI ? 'roi75' : 'revenue75';
        }
        if (props.revenuePercentile === 80) {
            return isROI ? 'roi80' : 'revenue80';
        }
        if (props.revenuePercentile === 90) {
            return isROI ? 'roi90' : 'revenue90';
        }
        if (props.revenuePercentile === 95) {
            return isROI ? 'roi95' : 'revenue95';
        }
        return '';
    }

    function getByPercentileNumberADR() {
        if (props.revenuePercentile === 50) {
            return 'adr50';
        }
        if (props.revenuePercentile === 60) {
            return 'adr60';
        }
        if (props.revenuePercentile === 70) {
            return 'adr70';
        }
        if (props.revenuePercentile === 75) {
            return 'adr75';
        }
        if (props.revenuePercentile === 80) {
            return 'adr80';
        }
        if (props.revenuePercentile === 90) {
            return 'adr90';
        }
        if (props.revenuePercentile === 95) {
            return 'adr95';
        }
        return '';
    }

    function getByPercentileNumberOccupancy() {
        if (props.revenuePercentile === 50) {
            return 'occupancy50';
        }
        if (props.revenuePercentile === 60) {
            return 'occupancy60';
        }
        if (props.revenuePercentile === 70) {
            return 'occupancy70';
        }
        if (props.revenuePercentile === 75) {
            return 'occupancy75';
        }
        if (props.revenuePercentile === 80) {
            return 'occupancy80';
        }
        if (props.revenuePercentile === 90) {
            return 'occupancy90';
        }
        if (props.revenuePercentile === 95) {
            return 'occupancy95';
        }
        return '';
    }
    const moneyFormatter = (amount: any) => {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
        })

        return formatter.format(amount)
    }


    const percentageFormatter = (value: any) => {
        return Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 2,
        }).format(value)
    }

    const enNumberFormatter = (value: any) => {
        return new Intl.NumberFormat('en').format(value);
    }
    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, payload }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        console.log(value, payload)
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <>
            <Grid container spacing={2}>
                <SwipeableDrawer
                    anchor={'right'}
                    open={open}
                    onClose={() => setIsOpen(false)}
                    onOpen={() => setIsOpen(true)}
                >
                    <Box sx={{ width: 300 }} role='presentation'>
                        <div style={{ textAlign: 'center' }}>
                            <h2>{infoType.toUpperCase()}</h2>
                        </div>
                        <Divider></Divider>

                        <div style={{ padding: 20 }}>
                            {getInfoDescription(infoType.trim().toLowerCase())}
                        </div>
                    </Box>
                </SwipeableDrawer>

                {!loading && averageHistories.length === 0 &&
                    <div>
                        No Data For This Market. Please check back later.
                    </div>
                }

                {loading &&
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '20%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress style={{ margin: 'auto' }} size={100} />
                    </Box>
                }

                {averageHistories.length > 0 && !loading &&
                    <Grid item md={6} xs={12}>
                        <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: 24 }}>
                                {props.cityName + ', ' + props.state}
                            </div>
                            <div style={{ fontSize: 24, float: 'right' }}>
                                <Button onClick={() => {
                                    navigate(`/city?city=${props.cityName}&state=${props.state}`)
                                }}>
                                    <div>
                                        See Market Profile
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </Grid>
                }
            </Grid>

            {averageHistories.length > 0 && !loading &&
                <>
                    <Grid>
                        <Stack spacing={1} direction='row' sx={{ mt: 2, mb: 2 }}>
                            <ButtonGroup
                                aria-label='outlined primary button group'
                                size='small'
                                sx={{ height: 40 }}
                            >
                                <Button
                                    onClick={() => {
                                        setOverallMarketMode(true)
                                        setHistories(averageHistories)
                                    }}
                                    variant={overallMarketMode ? 'contained' : 'outlined'}
                                >
                                    Average
                                </Button>
                                <Button
                                    onClick={() => {
                                        setOverallMarketMode(false)
                                        setHistories(bedroomHistories.filter(a => a.bedroomCount === bedroomCountSelected))
                                    }}
                                    variant={overallMarketMode ? 'outlined' : 'contained'}
                                >
                                    Bedroom Count
                                </Button>
                                <FormControl sx={{ width: 200, height: 40, marginLeft: 10 }}>
                                    <InputLabel sx={{ marginBottom: '0px' }}>Visualization</InputLabel>

                                    <Select
                                        sx={{ height: 40, color: theme.palette.mode === 'dark' ? 'white' : 'black', backgroundColor: theme.palette.mode === 'dark' ? '' : 'white' }}
                                        name='visualizations'
                                        labelId="demo-simple-select-label"
                                        id="visualizations"
                                        value={visualizationSelected ?? 'Line Chart'}
                                        defaultValue={visualizationSelected}
                                        label="Visualization"
                                        onChange={(event: SelectChangeEvent) => {
                                            setVisualizationSelected(event.target.value)
                                        }}
                                    >
                                        {['Line Chart', 'Area Chart', 'Bar Chart', 'Pie Chart'].map(val => {
                                            return <MenuItem value={val}>{val}</MenuItem>
                                        })}

                                    </Select>
                                </FormControl>
                            </ButtonGroup>
                            {!overallMarketMode &&
                                <FormControl sx={{ width: 200, height: 40 }}>
                                    <InputLabel sx={{ marginBottom: '0px' }}>Bedroom Count</InputLabel>

                                    <Select
                                        sx={{ height: 40, color: theme.palette.mode === 'dark' ? 'white' : 'black', backgroundColor: theme.palette.mode === 'dark' ? '' : 'white' }}
                                        name='bedroomCounts'
                                        labelId="demo-simple-select-label"
                                        id="bedrooms"
                                        value={bedroomCountSelected ?? '1'}
                                        defaultValue={bedroomCountSelected}
                                        label="Bedroom Count"
                                        onChange={(event: SelectChangeEvent) => {
                                            setBedroomCountSelected(event.target.value)
                                        }}
                                    >
                                        {bedroomCounts.map(val => {
                                            return <MenuItem value={val}>{val}</MenuItem>
                                        })}

                                    </Select>
                                </FormControl>
                            }
                            {/* <Button
                                onClick={() => {
                                    window.print()
                                }}
                                variant={'contained'}
                                color={'secondary'}
                            >
                                Export to PDF
                            </Button> */}
                        </Stack>

                    </Grid>
                    <Grid id={'market-history-id'} container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Card style={dataCardMedium}>
                                <div>Revenue</div>
                                {visualizationSelected === 'Line Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <LineChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={moneyFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={moneyFormatter} />
                                            <Line dataKey={getByPercentileNumber(false)} stroke="#0000FF" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                }

                                {visualizationSelected === 'Area Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <AreaChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={moneyFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={moneyFormatter} />
                                            <Area type="monotone" dataKey={getByPercentileNumber(false)} stroke="#0000FF" fill="#0000FF" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                }

                                {visualizationSelected === 'Bar Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <BarChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={moneyFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={moneyFormatter} />
                                            <Bar dataKey={getByPercentileNumber(false)} fill="#0000FF" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                }

                                {visualizationSelected === 'Pie Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <PieChart width={400} height={400}>
                                            <Pie
                                                data={history}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                dataKey={getByPercentileNumber(false)}
                                            >
                                                {history.map((entry: any, index: number) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={moneyFormatter} />
                                        </PieChart>
                                    </ResponsiveContainer>
                                }
                            </Card>
                        </Grid>
                        {/* <Grid item md={6} xs={12}>
                            <Card style={dataCardMedium}>
                                <div>ROI</div>
                                <ResponsiveContainer width="100%" aspect={3}>
                                    <LineChart height={200} data={history}>
                                        <CartesianGrid />

                                        <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                        <YAxis tickFormatter={percentageFormatter} />
                                        <Tooltip formatter={percentageFormatter} />
                                        <Line dataKey={getByPercentileNumber(true)} stroke="#00FF00" />
                                    </LineChart>
                                </ResponsiveContainer>


                            </Card>
                        </Grid> */}
                        <Grid item md={6} xs={12}>
                            <Card style={dataCardMedium}>
                                <div>Average Daily Rate</div>
                                {visualizationSelected === 'Line Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <LineChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={moneyFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={moneyFormatter} />
                                            <Line dataKey={getByPercentileNumberADR()} stroke="#FF0000" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                }
                                {visualizationSelected === 'Area Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <AreaChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={moneyFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={moneyFormatter} />
                                            <Area type="monotone" dataKey={getByPercentileNumberADR()} stroke="#FF0000" fill="#FF0000" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                }
                                {visualizationSelected === 'Bar Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <BarChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={moneyFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={moneyFormatter} />
                                            <Bar dataKey={getByPercentileNumberADR()} fill="#FF0000" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                }

                                {visualizationSelected === 'Pie Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <PieChart width={400} height={400}>
                                            <Pie
                                                data={history}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={80}
                                                fill="#FF0000"
                                                dataKey={getByPercentileNumberADR()}
                                            >
                                                {history.map((entry: any, index: number) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={moneyFormatter} />
                                        </PieChart>
                                    </ResponsiveContainer>
                                }
                            </Card>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Card style={dataCardMedium}>
                                <div>Occupancy</div>
                                {visualizationSelected === 'Line Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <LineChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={percentageFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={percentageFormatter} />
                                            <Line dataKey={getByPercentileNumberOccupancy()} stroke="#FF7F00" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                }
                                {visualizationSelected === 'Area Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <AreaChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={percentageFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={percentageFormatter} />
                                            <Area type="monotone" dataKey={getByPercentileNumberOccupancy()} stroke="#FF7F00" fill="#FF7F00" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                }
                                {visualizationSelected === 'Bar Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <BarChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={percentageFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={percentageFormatter} />
                                            <Bar dataKey={getByPercentileNumberOccupancy()} fill="#FF7F00" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                }

                                {visualizationSelected === 'Pie Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <PieChart width={400} height={400}>
                                            <Pie
                                                data={history}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={80}
                                                fill="#FF7F00"
                                                dataKey={getByPercentileNumberOccupancy()}
                                            >
                                                {history.map((entry: any, index: number) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={percentageFormatter} />
                                        </PieChart>
                                    </ResponsiveContainer>
                                }
                            </Card>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Card style={dataCardMedium}>
                                <div>Active Listing Count</div>
                                {visualizationSelected === 'Line Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <LineChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={enNumberFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={enNumberFormatter} />
                                            <Line dataKey='activeListingCount' stroke="#4B0082" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                }
                                {visualizationSelected === 'Area Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <AreaChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={enNumberFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={enNumberFormatter} />
                                            <Area type="monotone" dataKey='activeListingCount' stroke="#4B0082" fill="#4B0082" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                }
                                {visualizationSelected === 'Bar Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <BarChart height={200} data={history} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                            <CartesianGrid />
                                            <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                            <YAxis tickFormatter={enNumberFormatter} />
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={enNumberFormatter} />
                                            <Bar dataKey='activeListingCount' fill="#4B0082" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                }

                                {visualizationSelected === 'Pie Chart' &&
                                    <ResponsiveContainer width="100%" aspect={3}>
                                        <PieChart width={400} height={400}>
                                            <Pie
                                                data={history}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={80}
                                                fill="#FF7F00"
                                                dataKey={'activeListingCount'}
                                            >
                                                {history.map((entry: any, index: number) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip labelStyle={{ color: 'black' }} formatter={enNumberFormatter} />
                                        </PieChart>
                                    </ResponsiveContainer>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </>
            }

        </>
    )
}

const dataCardMedium = {
    marginBottom: '12px',
    padding: '20px',
}