import { Box, CircularProgress, Container } from '@mui/material'
import { useEffect, useState } from 'react';
import { strFetch } from 'utils/api'
import {useLocation, useNavigate} from "react-router-dom";

export default function Authenticator() {
    const [showSpinner, setShowSpinner] = useState(true)
    const [responseText, setResponseText] = useState('')

    const search = useLocation().search
    let navigate = useNavigate()

    useEffect(() => {
        setTimeout( () => {
            const stripeId = new URLSearchParams(search).get('stripe-id')

            strFetch(`/authenticate-invoice/${stripeId}`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              
            }).then(data => {
                if (data.status) {
                    navigate('/?subscription-success=true')

                } else {
                    setResponseText('Payment unsuccessful, Please see your email for more information.')
                    setShowSpinner(false)
                }
            })
        
        }, 5000)
       
      }, []);
    return (
      <Container component='main'>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: 'rgb(51,208,209)',
                fontSize: '40px',
                fontWeight: '700',
                marginRight: '4px',
              }}
            >
              Checking
            </span>
            <span
              style={{ fontSize: '40px', fontWeight: '700', color: 'black' }}
            >
              Payment
            </span>
          </div>
          {showSpinner && (
            <CircularProgress style={{ marginTop: '50px' }} size={60} />
          )}
          {!showSpinner && (
            <>
              <div style={{ marginTop: 30 }}>{responseText}</div>
              <a href='/' style={{ marginTop: 15 }}>
                Return to app
              </a>
            </>
          )}
        </Box>
      </Container>
    )
}
