import React, { useEffect, useRef, useState } from "react";
import { TextField, Button, Container, Grid, LinearProgress, CircularProgress } from "@mui/material";
import Message from "./Message";
import { MessageDto } from "./MessageDto";
import SendIcon from "@mui/icons-material/Send";
import logo from '../../images/logo.png'
import { strFetch } from "utils/api";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import useBreakpoint from "hooks/useBreakpoint";

const Chat: React.FC<any> = (props: { setIsOpened: Function }) => {
    const breakpoint = useBreakpoint()

    const [isWaiting, setIsWaiting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
    const [input, setInput] = useState<string>("");

    const hiddenFileInput = useRef(null);
    const messagesEnd = useRef(null);

    const handleClick = (event: any) => {
        (hiddenFileInput as any).current.value = null;
        (hiddenFileInput as any).current.click();
    };

    useEffect(() => {
        ; (async function getData() {
            const result = await strFetch('/open-ai/thread/', {
                headers: { 'Content-Type': 'application/json' },
            })
            if (!result.success) {
                await strFetch('/open-ai/thread/', {
                    headers: { 'Content-Type': 'application/json' },
                    method: 'PUT'
                })
            }
            populateMessages()
        })()
    }, [])

    async function populateMessages() {
        setIsLoading(true)
        setIsWaiting(true)
        setMessages([])

        const result = await strFetch('/open-ai/messages/', {
            headers: { 'Content-Type': 'application/json' },
        })
        const messageList = result.messages

        setIsLoading(false)
        setIsWaiting(false)
        if (messageList.data.length === 0) {
            setMessages([
                {
                    content: "Hi, I'm Investy your STR AI buddy! How can I help?",
                    isUser: false,
                },
            ]);
        } else {
            const localMessages = [{
                content: "Hi, I'm Investy your STR AI buddy! How can I help?",
                isUser: false,
            }]

            for (let message of messageList.data.sort((a: { created_at: number; }, b: { created_at: number; }) => a.created_at - b.created_at)) {
                localMessages.push(
                    createNewMessage(message.content[0]["text"].value, message.role === 'user')
                )
            }
            setMessages(localMessages);
        }

        (messagesEnd.current as any).scrollIntoView({ behavior: 'smooth' });
    }

    const createNewMessage = (content: string, isUser: boolean) => {
        const newMessage = new MessageDto(isUser, content);
        return newMessage;
    };

    const handleSendMessage = async (fileId: string = '', inputForFileUpload = '') => {
        setIsWaiting(true);

        messages.push(createNewMessage(fileId ? inputForFileUpload : input, true));
        setMessages([...messages]);


        // Send a message to the thread
        const runResult = await strFetch('/open-ai/messages/', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                content: fileId ? inputForFileUpload : input,
                fileIds: fileId ? [fileId] : []
            })
        });

        setInput("");

        let runStatus = await strFetch('/open-ai/runs/' + runResult.runId, {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET'
        });

        (messagesEnd.current as any).scrollIntoView({ behavior: 'smooth' });

        // Wait for the response to be ready
        while (runStatus.status === "in_progress" || runStatus.status === "queued") {
            console.log("waiting...");
            await new Promise((resolve) => setTimeout(resolve, 5000));

            runStatus = await strFetch('/open-ai/runs/' + runResult.runId, {
                headers: { 'Content-Type': 'application/json' },
                method: 'GET'
            })
        }

        setIsWaiting(false);

        await populateMessages();

        // const result = await strFetch('/open-ai/messages/', {
        //     headers: { 'Content-Type': 'application/json' },
        // })

        // Find the last message for the current run
        // const lastMessages = result.messages.data
        //     .filter((message: any) => message.run_id === runResult.runId && message.role === "assistant")

        // Print the last message coming from the assistant
        // if (lastMessages && lastMessages.length > 0) {
        //     let mess = messages;
        //     for (let lastMessage of lastMessages.data.sort((a: { created_at: number; }, b: { created_at: number; }) => a.created_at - b.created_at)) {
        //         let c = createNewMessage(lastMessage.content[0]["text"].value, false)
        //         mess.push(c)
        //     }
        //     setMessages(mess)
        // }

        (messagesEnd.current as any).scrollIntoView({ behavior: 'smooth' });

        // let extra3Calls = 0
        // while (extra3Calls <= 3) {
        //     console.log("waiting...");
        //     await new Promise((resolve) => setTimeout(resolve, 5000));

        //     const result = await strFetch('/open-ai/messages/', {
        //         headers: { 'Content-Type': 'application/json' },
        //     })
        //     console.log(result)

        //     // Find the last message for the current run
        //     const lastMessage = result.messages.data
        //         .filter((message: any) => message.run_id === runResult.runId && message.role === "assistant")
        //         .pop()

        //     // Print the last message coming from the assistant
        //     if (lastMessage) {
        //         setMessages([...messages, createNewMessage(lastMessage.content[0]["text"].value, false)]);
        //     }

        //     extra3Calls = extra3Calls + 1;
        // }
    };

    // detect enter key and send message
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && input) {
            handleSendMessage();
        }
    };

    const handleFileChange = async (event: any) => {
        setIsWaiting(true);

        let formData = new FormData()
        formData.append('file', event.target.files[0])

        setInput(`Please analyze this file - ${event.target.files[0].name} and use it moving forward.`)

        const fileResult = await strFetch('/open-ai/files', {
            "Content-Type": "multipart/form-data",
            method: 'POST',
            body: formData,
        })

        if (fileResult.fileId) {
            handleSendMessage(fileResult.fileId, `Please analyze this file - ${event.target.files[0].name} and use it moving forward.`)
        }

    };

    return (
        <Container>
            <div style={{ marginTop: '12px', textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'baseline', alignContent: 'center' }}>
                    <div style={{ alignSelf: 'center', fontSize: '24px', fontWeight: '400' }}>Investy</div>
                    <div style={{ alignSelf: 'center', fontSize: '12px', marginLeft: '8px', marginRight: '4px' }}>Powered By</div>
                    <img src={logo} alt='LOGO2' style={{ maxHeight: '46px' }} />
                    {(!['xs', 'sm'].includes(breakpoint!)) &&
                        <div style={{ marginLeft: 'auto' }}>
                            <Button variant="outlined" size="medium" color="primary" onClick={() => props.setIsOpened(false)}>
                                <CloseFullscreenIcon fontSize="medium" />
                            </Button>
                        </div>
                    }
                </div>

            </div>
            <hr></hr>
            <Grid container paddingBottom={2} style={{ overflow: 'scroll', height: '350px', display: 'grid' }}>
                {messages.map((message, index) => (
                    <Grid item
                        style={{ marginLeft: message.isUser ? "auto" : "0" }}
                        key={index}
                    >
                        <Message key={index} message={message} />
                    </Grid>
                ))}
                <div style={{ float: "left", clear: "both" }}
                    ref={messagesEnd}>
                </div>
                {isLoading &&
                    <CircularProgress size='60px' style={{ margin: 'auto' }} color="primary" />
                }
                {isWaiting && !isLoading &&
                    <CircularProgress size='30px' style={{ margin: 'auto' }} color="primary" />
                }
            </Grid>
            <div
                style={{
                    bottom: '0',
                    display: 'flex',
                    gap: '12px',
                    justifyContent: 'center',
                    // position: 'fixed', 
                    marginBottom: '12px',
                    marginTop: '12px',
                    width: '100%'
                }}>

                <div style={{ width: '100%' }}>
                    <TextField
                        label="Type your message"
                        variant="outlined"
                        disabled={isWaiting}
                        value={input}
                        fullWidth
                        style={{ height: 'auto' }}
                        size="small"
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyPress}
                    />
                </div>
                <div>
                    <input
                        type="file"
                        accept=".csv, .xlsx"
                        style={{ display: 'none' }}
                        id="file_upload"
                        ref={hiddenFileInput}
                        onChange={handleFileChange}
                    />

                    <label htmlFor="file_upload">
                        <Button variant="contained" size="medium" color="primary" onClick={handleClick} disabled={isWaiting}>
                            {<AttachFileIcon fontSize="medium" />}
                        </Button>
                    </label>


                </div>
                <div>
                    <Button variant="contained" size="medium" color="primary" onClick={() => handleSendMessage()} disabled={isWaiting || !input}>
                        {<SendIcon fontSize="medium" />}
                    </Button>
                </div>

            </div>
            <div>
                <Button variant="contained" size="medium" color="secondary" onClick={() => populateMessages()}>
                    Expecting More Results?

                </Button>
                {(['xs', 'sm'].includes(breakpoint!)) &&
                    <span style={{ float: 'right' }}>
                        <Button variant="outlined" size="medium" color="primary" onClick={() => props.setIsOpened(false)}>
                            <CloseFullscreenIcon fontSize="medium" />
                        </Button>
                    </span>
                }
            </div>
        </Container>
    );
};

export default Chat;