import * as React from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import useGlobal from 'hooks/useGlobal'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default function SnackbarWrapper() {
  const {
    state: { snackbar },
    dispatch: { setSnackbar },
  } = useGlobal()
  return (
    <BottomSnackbar setSnackbar={setSnackbar} {...snackbar}></BottomSnackbar>
  )
}

interface SnackbarProps {
  setSnackbar: Function
  severity: any
  message: string
  autoHideDuration: number
  isOpen: boolean
}

function BottomSnackbar(props: SnackbarProps) {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    props.setSnackbar({ isOpen: false })
  }
  return (
    <Snackbar
      open={props.isOpen}
      autoHideDuration={props.autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={props.severity}
        sx={{ width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}
