import { Button, Card, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { strFetch } from 'utils/api'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { Market, MarketDetail, MarketExtended } from 'routes/responses';
import CityProfileTable from 'routes/city-profile/map-table';
import { Link } from 'react-router-dom'
import useGlobal from 'hooks/useGlobal'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import useBreakpoint from 'hooks/useBreakpoint'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SavedMarkets() {
  const { state: global, dispatch } = useGlobal()
  const [savedMarkets, setSavedMarkets] = useState<Market[]>([])
  const [open, setOpen] = React.useState(false);
  const [marketDetail, setMarketDetail] = React.useState<MarketDetail>();
  const breakpoint = useBreakpoint()

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })

  const handleClickOpen = (market: MarketDetail) => {
    setMarketDetail(market)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    strFetch(`/saved-markets`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }).then((data) => setSavedMarkets(data))

  }, [])


  function deleteSavedMarket() {
    
    strFetch(`/saved-markets`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        city: marketDetail?.city,
        state: marketDetail?.state
      }),
    }).then(() => {
      let savedMarketRows = savedMarkets.filter((row) => {
        return marketDetail?.city !== row.marketDetails.city &&
          marketDetail?.state !== row.marketDetails.state
      })
      setSavedMarkets(savedMarketRows)
    }

    )
  }



  function getRevenue(isROI: boolean, market: MarketDetail) {
    const revenuePercentile = global.marketProfileRevenuePercentile;
    if (revenuePercentile === 50) {
      return isROI ? market?.roi.fiftieth : market?.revenue.fiftieth;
    }
    if (revenuePercentile === 60) {
      return isROI ? market?.roi.sixtieth : market?.revenue.sixtieth;
    }
    if (revenuePercentile === 70) {
      return isROI ? market?.roi.seventieth : market?.revenue.seventieth;
    }
    if (revenuePercentile === 75) {
      return isROI ? market?.roi.seventyFifth : market?.revenue.seventyFifth;
    }
    if (revenuePercentile === 80) {
      return isROI ? market?.roi.eightieth : market?.revenue.eightieth;
    }
    if (revenuePercentile === 90) {
      return isROI ? market?.roi.ninetieth : market?.revenue.ninetieth;
    }
    if (revenuePercentile === 95) {
      return isROI ? market?.roi.ninetyFifth : market?.revenue.ninetyFifth;
    }
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Remove Market"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you would like to remove this market from your dashboard?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => {handleClose(); deleteSavedMarket()}}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Grid item lg={12} md={12} xs={12} style={{
        flexWrap: 'wrap',
        display: 'inline-flex',
        justifyContent: 'start',
        gap: '20px'
        }}>
        {savedMarkets.map(market => {
          return (
            <div style={{ marginBottom: 30, width: !['xs', 'sm', 'md'].includes(breakpoint!) ? '40vw' : '100%' }}>
              <div style={{ textAlign: 'right' }}>
                <DeleteIcon
                  style={{ color: 'gray', cursor: 'pointer' }}
                  onClick={() => {
                    handleClickOpen(market.marketDetails);
                  }}

                ></DeleteIcon>
                <Link
                  to={`/city?c=${market.marketDetails.city}&s=${market.marketDetails.state}`}
                  onClick={() => {
                    dispatch.setStateAndCityFilters(market.marketDetails.city, market.marketDetails.state)
                  }}
                >
                  <span>
                    <EqualizerIcon style={{ color: 'green', marginLeft: '10px' }}></EqualizerIcon>
                  </span>
                </Link>
              </div>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >

                  <div style={{
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '30px',
                    color: '#000000',
                  }}>
                    {market.marketDetails.city}, {market.marketDetails.state}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Card style={{
                    height: 'auto',
                    marginTop: '20px',
                    background: '#F8F9FD',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '15px'
                  }}>

                    <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'start', flexWrap: 'wrap', gap: 40, marginLeft: 20, marginTop: 20 }}>
                      <Card style={card}>
                        <div style={smallCardHeader}>REVENUE</div>
                        <div style={smallCardValue}>{formatter.format(getRevenue(false, market.marketDetails) as number)}</div>
                      </Card>
                      <Card style={card}>
                        <div style={smallCardHeader}>VALUATION</div>
                        <div style={smallCardValue}>{market.marketDetails.valuation ? formatter.format(market.marketDetails.valuation) : 'N/A'}</div>
                      </Card>
                      <Card style={card}>
                        <div style={smallCardHeader}>ROI</div>
                        <div style={smallCardValue}>{getRevenue(true, market.marketDetails) ? Math.trunc(getRevenue(true, market.marketDetails) as number * 100) + '%' : 'N/A'}</div>
                      </Card>
                      <Card style={card}>
                        <div style={smallCardHeader}>ACTIVE LISTING COUNT</div>
                        <div style={smallCardValue}>{market.marketDetails.activeListingCount}</div>
                      </Card>
                    </div>
                    <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'start', flexWrap: 'wrap', marginTop: 30, gap: 40, marginLeft: 20 }}>
                      <Card style={card}>
                        <div style={smallCardHeader}>ADR</div>
                        <div style={smallCardValue}>{formatter.format(market.marketDetails.adr)}</div>
                      </Card>
                      <Card style={card}>
                        <div style={smallCardHeader}>OCCUPANCY</div>
                        <div style={smallCardValue}>{Math.trunc(market.marketDetails.occupancy * 100)}%</div>
                      </Card>
                      <Card style={card}>
                        <div style={smallCardHeader}>REGULATION</div>
                        <div style={smallCardValue}>{market.regulationAmount}</div>
                      </Card>
                    </div>

                    {market.marketDetails.description &&
                      <div style={{ width: '90%', marginLeft: 20 }}>
                        <Accordion style={{ marginTop: '20px' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <div style={{
                              fontWeight: 600,
                              fontSize: '20px',
                              lineHeight: '130%',
                              color: '#000000'
                            }}>Description</div>
                          </AccordionSummary>

                          <AccordionDetails>
                            <p>{market.marketDetails.description}</p>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    }

                    <div style={{ width: '90%', marginLeft: 20, marginBottom: 20 }}>
                      <Accordion style={{ marginTop: '20px' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div style={{
                            fontWeight: 600,
                            fontSize: '20px',
                            lineHeight: '130%',
                            color: '#000000'
                          }}>Revenue by Bedroom Count</div>
                        </AccordionSummary>

                        <AccordionDetails>
                          <CityProfileTable
                            searchCity={market.marketDetails.city}
                            searchState={market.marketDetails.state}
                            bedroomDetails={market.bedroomDetails}
                            revenuePercentile={75}
                          ></CityProfileTable>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Card>
                </AccordionDetails>
              </Accordion>

            </div>
          )
        })}
      </Grid>



    </Grid>

  )
}

const card = {
  background: '#FFFFFF',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '4px',
  width: '40%',
  height: 100
}

const smallCardHeader = {
  textAlign: 'center' as 'center',
  marginTop: '10px',
  fontWeight: 300,
  color: 'rgba(0, 0, 0, 0.87)'
}

const smallCardValue = {
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '30px',
  textAlign: 'center' as 'center',
  color: '#242424',
  marginTop: '10px'
}