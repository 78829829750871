import useBreakpoint from "hooks/useBreakpoint";
import { useState } from "react";
import { amenityOptions, itemOptions, viewOptions } from "utils/Amenities";

function viewLayout(label: string, value: string, isFlex: boolean) {

    return (
        <div style={{display: isFlex ? 'flex' : 'grid'}}>
            <div style={{fontWeight: 600, marginRight: '12px', }}>{label}</div>
            <div>{value}</div>
        </div>
    )
}

function amenitiesLayout(heading: string, shouldShowHidden: boolean, propertyForProfile: any, options: any[], setShouldShowHidden: Function) {
    return (
        <>
            <div style={{marginTop: '20px'}}>
                <span style={{fontSize: '18px', fontWeight: 600}}>
                    {heading}
                </span>
                <span style={{marginLeft: '20px'}}>
                    <button style={{color: 'blue', cursor: 'pointer'}} onClick={() => setShouldShowHidden(!shouldShowHidden)}>
                        {shouldShowHidden ? 'Hide Excluded' : 'Show Excluded'}
                    </button>
                </span>
            </div>

            <div style={{gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', display: 'grid'}}>
                {shouldShowHidden &&
                    options.map((view: any) => {
                        return (
                            <div style={{color: propertyForProfile[view] === true ? 'green' : 'red'}}>
                                {view}
                            </div>
                        )
                    })
                }
                {!shouldShowHidden &&
                    options.map((view: any) => {
                        if (propertyForProfile[view] === true) {
                            return (
                                <div >
                                    {view}
                                </div>
                            )
                        }
                    })
                }
            </div>
        </>
    )
}

export default function PropertyInfo(props: any) {
    const { propertyForProfile } = props
    const breakpoint = useBreakpoint()

    const [showAllHiddenViews, setShowAllHiddenViews] = useState<boolean>(false);
    const [showAllHiddenItems, setShowAllHiddenItems] = useState<boolean>(false);
    const [showAllHiddenAmenities, setShowAllHiddenAmenities] = useState<boolean>(false);

    console.log(propertyForProfile)
    return (
        <>
            <div style={{gridTemplateColumns: !['xs', 'sm'].includes(breakpoint!) ? 'repeat(3, minmax(0, 1fr))' : '', display: 'grid'}}>
                <div >
                    {viewLayout('City', propertyForProfile.city_name, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Bedrooms', propertyForProfile.bedroom_count, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Cancellation Policy', propertyForProfile.cancellation_policy_id, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Guest Count', propertyForProfile.guest_count, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Pets Allowed', propertyForProfile.pets_allowed, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Security Cameras', propertyForProfile.security_cameras, !['xs', 'sm'].includes(breakpoint!))}
                </div>
                <div style={{marginLeft: !['xs', 'sm'].includes(breakpoint!) ? '12px' : ''}}>
                    {viewLayout('State', propertyForProfile.state, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Bathrooms', propertyForProfile.bathroom_count, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Cleaning Fee', propertyForProfile.cleaning_fee ?? 'N/A', !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Host', propertyForProfile.host, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Rating', propertyForProfile.rating, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Self Check-in', propertyForProfile.self_checkin, !['xs', 'sm'].includes(breakpoint!))}
                </div>
                <div style={{marginLeft: !['xs', 'sm'].includes(breakpoint!) ? '12px' : ''}}>
                    {viewLayout('Days Available', propertyForProfile.days_available, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Beds', propertyForProfile.bed_count, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('First Review', propertyForProfile === 'N/A' ? 'N/A' : new Date(propertyForProfile.first_review).toLocaleDateString(), !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Property Type', propertyForProfile.mapped_property_type ?? 'N/A', !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Review Count', propertyForProfile.review_count, !['xs', 'sm'].includes(breakpoint!))}
                    {viewLayout('Suitable for Events', propertyForProfile.suitable_for_events, !['xs', 'sm'].includes(breakpoint!))}
                </div>
            </div>
            <hr style={{marginTop: '20px', marginBottom: '20px'}}></hr>
            {amenitiesLayout('Views', showAllHiddenViews, propertyForProfile, viewOptions, setShowAllHiddenViews)}
            {amenitiesLayout('Items', showAllHiddenItems, propertyForProfile, itemOptions, setShowAllHiddenItems)}
            {amenitiesLayout('Amenities', showAllHiddenAmenities, propertyForProfile, amenityOptions, setShowAllHiddenAmenities)}

        </>
    )
}