const standardConfig = {
  comoURL: (() =>
  process.env.REACT_APP_COMO_URL ||
  new Error('COMO_URL env variable not set!'))(),
}

const config = (env: string) => {
  return standardConfig
}

export default config(process.env.NODE_ENV)
