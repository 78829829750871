export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const breakpoints: {
  [key in number]: Breakpoint
} = {
  0: 'xs',
  600: 'sm',
  960: 'md',
  1280: 'lg',
  1920: 'xl',
}

export default breakpoints
