import mixpanel from 'mixpanel-browser'
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production

export function initMixpanel() {
  mixpanel.init('c1b05adf24bb7a4be01060fdfdd363f9', { ignore_dnt: true })
}

// Wrapper function in case we want to include params with all calls
export function track(eventName = '', params = {}) {
  mixpanel.track(eventName, params)
}
