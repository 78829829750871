import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'

const Moderate = () => {
  return (
    <div
      style={{
        backgroundColor: '#dce6f9',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
      }}
    >
      <WarningAmberRoundedIcon fontSize='large' sx={{ color: '#5686E1' }} />
    </div>
  )
}

export default Moderate
