import { useContext } from 'react'
import {
  GlobalContext,
  TGlobalState,
  TGlobalDispatch,
} from 'providers/GlobalProvider'

type TGlobalProvider = {
  state: TGlobalState
  dispatch: TGlobalDispatch
}

export default function useGlobal() {
  const context = useContext<TGlobalProvider>(GlobalContext as any)
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider')
  }
  return context
}
