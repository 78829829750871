import config from '../config'

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

async function strFetch(url, params = {}, requireSubscription = true) {
  // This is called on every refresh and if the user does not have subscription 
  // disregard kicking them to the pricing page. 
  let dontKickBackError = url === '/user-features';
  const isDemo = sessionStorage.getItem('demo') === 'true'

  url = `${config.comoURL}${url}`
  params.withCredentials = true
  params.credentials = 'include'
  
  if (params.headers) {
    params.headers.isDemo = isDemo

  }

  const result = await (await fetch(url, params)).json().catch((e) => {
    return e
  })
  if (result.isAuthenticated === false) {
    window.location = '/login'
    return
  }
  if (requireSubscription && result.hasActiveSubscription === false && !isDemo  && !dontKickBackError) {
    window.location = '/pricing'
    return
  }
  return result
}

async function strFetchBlob(url, params = {}) {
  url = `${config.comoURL}${url}`
  params.withCredentials = true
  params.credentials = 'include'
  const result = await (await fetch(url, params)).blob()
  return result
}

export { strFetch, strFetchBlob, getCookie }
