import * as React from 'react'
import * as _ from 'lodash'
import { Route, Routes, Navigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Dashboard from './dashboard'
import CityProfile from '../routes/city-profile'
import Landing from './Landing'
import Pricing from '../routes/pricing'
import Support from '../routes/support'
import SideNav from './side-nav'
import './App.css'
import BottomSnackbar from './snackbar'
import ManageAccount from '../routes/manage-account'
import ChangePlanRoute from '../routes/manage-account/change-plan'
import useBreakpoint from 'hooks/useBreakpoint'
import { initMixpanel } from 'mixpanel'
import useGlobal from 'hooks/useGlobal'
import SavedMarkets from 'routes/saved-market'
import DashboardMapView from './dashboard-map-view'
import HistoryIndex from 'routes/market-history'
import { theme } from 'styles/themes'
import Chat from './chatbot/Chat'
import logo from '../images/investy.png'
import { strFetch } from 'utils/api'

initMixpanel()

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => {
  const breakpoint = useBreakpoint()
  return {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...(!['xs', 'sm'].includes(breakpoint!) && {
        marginLeft: `${drawerWidth}px`,
        width: `calc(100% - ${drawerWidth}px)`,
      }),
    }),
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export default function App() {
  const breakpoint = useBreakpoint()
  const { state, dispatch: global } = useGlobal()
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const [hasActiveSubscription, setHasActiveSubscription] = React.useState<boolean>(false);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

  React.useEffect(() => {
    ; (async function getData() {
      const result = await strFetch(
        '/user/info',
        {
          headers: { 'Content-Type': 'application/json' },
        },
        false
      )
      if (result.stripeData.subscribed) {
        setHasActiveSubscription(true)
      } else {
        setHasActiveSubscription(false)
      }

      setIsAdmin(result.isAdmin)
    })()
  }, [])
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', backgroundColor: theme.palette.mode === 'dark' ? '' : '#efefef !important' }}>
      <Routes>
        <Route path='/' element={<SideNav></SideNav>}></Route>
        <Route
          path='/dashboard/:subscription-success'
          element={<SideNav></SideNav>}
        ></Route>
        <Route path='/saved-markets' element={<SideNav></SideNav>}></Route>
        <Route path='/city' element={<SideNav></SideNav>}></Route>
        <Route path='/market-history' element={<SideNav></SideNav>}></Route>
        <Route path='/map-mode' element={<SideNav></SideNav>}></Route>
        {/* <Route path='/report' element={<SideNav></SideNav>}></Route> */}
        <Route path='/support' element={<SideNav></SideNav>}></Route>
        <Route path='/pricing' element={<SideNav />}></Route>
        <Route path='/manage-account' element={<SideNav />}></Route>
        <Route
          path='/manage-account/change-plan/:plan'
          element={<SideNav />}
        ></Route>
      </Routes>
      <BottomSnackbar></BottomSnackbar>
      <Main open={state.sideNavOpen}>
        <DrawerHeader />
        <Routes>
          <Route
            path=''
            element={
              <Dashboard
                columns={state.selectedColumns}
                filters={state.filters}
                filterOrderBy={state.filterOrderBy}
                filterOrder={state.filterOrder}
                useBedrooms={state.useBedrooms}
                revenuePercentile={state.revenuePercentile}
              />
            }
          ></Route>
          <Route
            path='/map-mode'
            element={
              <DashboardMapView
                columns={state.selectedColumns}
                filters={state.filters}
                filterOrderBy={state.filterOrderBy}
                filterOrder={state.filterOrder}
                useBedrooms={state.useBedrooms}
                revenuePercentile={state.revenuePercentile}
              />
            }
          ></Route>
          <Route path='/saved-markets' element={<SavedMarkets></SavedMarkets>}></Route>
          <Route path='/city' element={<CityProfile></CityProfile>}></Route>
          <Route path='/market-history' element={<HistoryIndex></HistoryIndex>}></Route>
          <Route path='/support' element={<Support />}></Route>
          <Route path='/manage-account' element={<ManageAccount />}></Route>
          <Route
            path='/manage-account/change-plan/:plan'
            element={<ChangePlanRoute />}
          ></Route>
          <Route path='/pricing' element={<Pricing />}></Route>
          <Route path='/dashboard' element={<Navigate to='/' />} />
        </Routes>
      </Main>
      {
        (isOpened && (hasActiveSubscription || isAdmin)) &&
        <div style={{
          zIndex: 100,
          borderRadius: '15px',
          border: 'solid 1px rgba(0, 0, 0, 0.25)',
          background: 'white',
          position: 'fixed',
          bottom: '0',
          right: '0',
          marginRight: '20px',
          marginBottom: '20px',
          width: ['xs', 'sm'].includes(breakpoint!) ? '90%' : '500px',
          height: '550px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}>
          <div style={{ position: 'relative' }}>
            <Chat setIsOpened={setIsOpened}/>
          </div>
        </div>
      }
      {
        (!isOpened && (hasActiveSubscription || isAdmin)) &&
        <div style={{
          zIndex: 100,
          borderRadius: '100px',
          border: 'solid 1px rgba(0, 0, 0, 0.25)',
          background: 'white',
          position: 'fixed',
          bottom: '0',
          right: '0',
          marginRight: '20px',
          marginBottom: '20px',
          width: '100px',
          height: '100px',
          cursor: 'pointer',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
        onClick={() => setIsOpened(true)}>
          <div style={{ position: 'relative', display: 'grid', justifyContent: 'center', marginTop: '10px'}}>
            <img src={logo} alt='LOGO2' style={{ maxHeight: '50px', maxWidth: '50px' }} />
            <div style={{fontSize: '18px'}}>Investy</div>
          </div>
        </div>
      }
    </Box>
  )
}
