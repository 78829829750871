import React, { useState, useEffect, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { styled, createTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Avatar from '@mui/material/Avatar'
import ListItemButton from '@mui/material/ListItemButton'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { useNavigate } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { useLocation } from 'react-router-dom'
import './SideNav.css'
import { strFetch, strFetchBlob } from '../utils/api'
import _ from 'lodash'
import { SmallButton } from './ui-components'
import { Link, Modal, Stack, SwipeableDrawer, Chip, Card } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import { theme } from '../styles/themes'
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import TimelineIcon from '@mui/icons-material/Timeline';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined'
import logo from '../images/logo.png'
import useBreakpoint from 'hooks/useBreakpoint'
import useDemoMode from 'hooks/useDemoMode'
import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CitySearch from '../routes/city-profile/city-search'
import RevenuePercentileSlider from 'routes/city-profile/revenue-percentile-slider'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import useGlobal from 'hooks/useGlobal'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { track } from '../mixpanel'

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => {
  const breakpoint = useBreakpoint()
  return {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...(!['xs', 'sm'].includes(breakpoint!) && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
      }),
    }),
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: 36,
      width: 36,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export default function SideNav() {
  let isDemo = useDemoMode()

  const sendDataToGTM = useGTMDispatch()
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false)
  const [showDemoModal, setShowDemoModal] = useState<boolean>(false)
  const [showPropertyFinder, setShowPropertyFinder] = useState<boolean>(false)

  const [profileOpen, setProfileOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [sliderOpen, setSliderOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(true)
  const { state: global, dispatch } = useGlobal()

  useEffect(() => {
    (async function getData() {
      const result = await strFetch(`/user-features`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      dispatch.setEnableSavedMarkets(result.saved_markets)
    })()
  }, [])
  // Quick and dirty solution. This should be done in
  // a more react way
  useEffect(() => {
    if (
      searchParams.get('subscription-success') === 'true' &&
      !localStorage.getItem('str_samt_sent')
    ) {
      setShowConfirmationModal(true)
      setTimeout(() => {
        sendDataToGTM({
          event: 'Purchase',
          value: localStorage.getItem('str_samt'),
        })
      }, 1000)
      localStorage.setItem('str_samt_sent', 'true')
    }
  }, [sendDataToGTM])

  const handleDrawerOpen = (global: any) => {
    dispatch.setSideNavOpen(true)
  }

  const handleDrawerClose = (global: any) => {
    dispatch.setSideNavOpen(false)
  }

  let navigate = useNavigate()

  const getHeaderTextByRoute = (route: any) => {
    switch (route) {
      case '/':
        return 'Discover New Markets'
      case '/manage-account':
        return 'Manage Subscription'
      case '/saved-markets':
        return 'Dashboard'
      case '/market-history':
        return 'Market History'
      // case '/report':
      //   return 'Comparison Report'
      case '/city':
        return 'Market Profile'
    }
  }

  const download = async (global: any) => {
    if (isDemo) {
      setShowDemoModal(true)
      return
    }
    let blob
    let columns
    let data
    if (window.location.pathname === '/') {
      columns = Object.keys(global.tableRowsForExport[0])
      data = global.tableRowsForExport
    } else {
      columns = Object.keys(global.comparisonReportRows[0])
      data = global.comparisonReportRows
    }

    blob = await strFetchBlob(`/download`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        columns: columns,
        data: data,
      }),
    })

    var a = document.createElement('a')
    document.body.appendChild(a)

    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = 'STRInsightsExport.csv'
    a.click()
    a.remove()
  }

  const logOut = async () => {
    await strFetch('/logout')
    localStorage.removeItem('userName')
    localStorage.removeItem('email')
    navigate('/login')
  }

  let location = useLocation()
  const breakpoint = useBreakpoint()
  const [didCheckBreakpoint, setDidCheckBreakpoint] = useState(false)
  const [open, setIsOpen] = useState<boolean>(false)
  const [infoType, setInfoType] = useState<string>('')

  useEffect(() => {
    if (!didCheckBreakpoint) {
      if (!['xs', 'sm', undefined].includes(breakpoint!)) {
        dispatch.setSideNavOpen(true)
      } else {
        dispatch.setSideNavOpen(false)
      }
      if (breakpoint) {
        setDidCheckBreakpoint(true)
      }
    }
  }, [breakpoint])

  function sendToGTM() {
    sendDataToGTM({
      event: 'DemoGetStarted',
    })
  }
  function getInfoDescription(label: string): string {
    if (label === 'revenue percentile slider') {
      return 'The revenue percentile that is used to determine revenue estimates. For example, if the revenue percentile is set to 75, revenue estimates represent listings that are performing better than 75% of all other listings in the given market.'
    }
    if (label === 'market profile') {
      return 'This view shows all shows all properties with the selected bedroom counts in this market. Note that all properties for which we have data are shown, not just properties included in revenue calculations.'
    }
    return 'N/A'
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <SwipeableDrawer
        anchor={'right'}
        open={open}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        <Box sx={{ width: 300 }} role='presentation'>
          <div style={{ textAlign: 'center' }}>
            <h2>{infoType.toUpperCase()}</h2>
          </div>
          <Divider></Divider>

          <div style={{ padding: 20 }}>
            {getInfoDescription(infoType.trim().toLowerCase())}
          </div>
        </Box>
      </SwipeableDrawer>
      <Modal
        disableAutoFocus={true}
        open={showDemoModal}
        onClose={() => setShowDemoModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Get Started!
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Please Sign Up to Start Finding the Next Emerging Market!
          </Typography>
          <Button
            variant='contained'
            style={{ marginTop: 20 }}
            onClick={() => {
              sendToGTM()
              setShowDemoModal(false)
              navigate('/login')
            }}
          >
            Get Started
          </Button>
        </Box>
      </Modal>
      <Modal
        disableAutoFocus={true}
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Payment Successful!
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Thank you for subscribing to STR Insights. Be sure to check your
            inbox or spam folder under the email you subscribed for a welcome
            email and further information of how to effectively use the tool.
          </Typography>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => {
              setShowConfirmationModal(false)
              navigate('/')
            }}
          >
            Proceed
          </Button>
        </Box>
      </Modal>
      <Modal
        disableAutoFocus={true}
        open={showPropertyFinder}
        onClose={() => setShowPropertyFinder(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card
          sx={learnMoreModalStyle}
          style={{
            width: ['xs', 'sm'].includes(breakpoint!) ? '90%' : 'auto',
          }}
        >
          <Stack direction='row' alignItems='center'>
            <p>
              Did you know that the team at STR Insights can help you find an investment property that fits your investment goals? Our data driven and personalized consulting approach will help you invest with confidence to find the most profitable investment short term rental properties to add to your portfolio. With STR Insights Property Finder, you'll have access to:
            </p>
          </Stack>
          <Stack direction='row' alignItems='center'>
            <ol>
              <li>One-on-one consulting with our team</li>
              <li>The hottest and most up-to-date market trends </li>
              <li>A customized property feed list catered to your investment criteria goals </li>
            </ol>
          </Stack>
          <Stack direction='row' alignItems='center'>
            <p>
              Whether you're a seasoned investor or just getting started, STR Insights is the partner you need that will save you time in finding short-term rental investments that maximize your returns. Click the link below to learn more.
            </p>
          </Stack>
          <Button
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            size='large'
            style={{
              width: '170px',
              background:
                'linear-gradient(305.02deg, #FF9A02 4.13%, #FF866C 110.93%)',
            }}
            onClick={() => {
              track('Property Finder Interest', {
                user: {
                  name: localStorage.getItem('userName'),
                  email: localStorage.getItem('email'),
                },
              })
              window.open('https://www.strinsights.com/property-finder')
            }}
          >
            Get Started
          </Button>
        </Card>
      </Modal>
      <CssBaseline />
      <AppBar
        position='fixed'
        open={global.sideNavOpen}
        style={{ background: theme.palette.mode === 'dark' ? '' : '#F8F9FD', boxShadow: 'none', color:  theme.palette.mode === 'dark' ? '' : 'black' }}
      >
        <Toolbar sx={{ display: 'inline-flex', justifyContent: 'flex-start' }}>
          <Stack direction='row' alignItems='center'>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch.setSideNavOpen(!global.sideNavOpen)}
              edge='start'
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' noWrap component='div'>
              {!['xs', 'sm', undefined].includes(breakpoint) && (
                <>
                  <span>{getHeaderTextByRoute(location.pathname)}</span>
                  {location.pathname === '/city' && (
                    <IconButton
                      onClick={() => {
                        setInfoType('market profile')
                        setIsOpen(true)
                      }}
                    >
                      <InfoIcon fontSize={'small'} />
                    </IconButton>
                  )}
                  {location.pathname === '/market-history' && (
                    <IconButton
                      onClick={() => {
                        setInfoType('market history')
                        setIsOpen(true)
                      }}
                    >
                      <InfoIcon fontSize={'small'} />
                    </IconButton>
                  )}
                </>
              )}
            </Typography>

          </Stack>
          {_.includes(['/'], location.pathname) && !isDemo && (
            <Link
              style={{ marginLeft: 'auto' }}
              component={RouterLink}
              to={'/map-mode'}
              underline='none'
            >
              <Button variant="outlined" startIcon={<MapOutlinedIcon />}>
                Map Mode
              </Button>
            </Link>

          )}
          {_.includes(['/map-mode'], location.pathname) && !isDemo && (
            <Link
              style={{ marginLeft: 'auto' }}
              component={RouterLink}
              to={'/'}
              underline='none'
            >
              <Button variant="contained" startIcon={<MapOutlinedIcon />}>
                Default Mode
              </Button>
            </Link>

          )}
          {_.includes(['/city', '/saved-markets', '/market-history'], location.pathname) && (
            <>
              {['xs', 'sm', undefined].includes(breakpoint) && (
                <>
                  {searchOpen && (
                    <>
                      <FilterListIcon
                        color='success'
                        fontSize='medium'
                        onClick={() => {
                          setSliderOpen(true)
                          setSearchOpen(false)
                        }}
                      />

                      <span id='top-tool-search'>
                        <CitySearch />
                      </span>
                    </>
                  )}
                  {sliderOpen && (
                    <>
                      <SearchIcon
                        fontSize='medium'
                        onClick={() => {
                          setSliderOpen(false)
                          setSearchOpen(true)
                        }}
                      />
                      <span id='top-tool-search'>
                        <div style={{ textAlign: 'center' }}>
                          Revenue Percentile
                        </div>
                        <RevenuePercentileSlider />
                      </span>
                    </>
                  )}
                </>
              )}
              {!['xs', 'sm', undefined].includes(breakpoint) && (
                <>
                  <span id='top-tool-search'>
                    <CitySearch />
                  </span>

                  <span id='top-tool-search'>
                    <RevenuePercentileSlider />
                  </span>
                  <span style={{ marginBottom: 24, marginLeft: 18 }}>
                    <IconButton onClick={() => {
                      setInfoType('revenue percentile slider')
                      setIsOpen(true)
                    }}>
                      <InfoIcon
                        fontSize={'small'}
                      />
                    </IconButton>
                  </span>
                </>
              )}
            </>
          )}

          {_.includes(['/', '/map-mode', '/report'], location.pathname) && !isDemo && (
            <span
              id='top-tool-bar-right-buttons'
              style={{ marginLeft: '12px' }}
            >
              <Button
                variant='outlined'
                size='small'
                onClick={() => {
                  download(global)
                }}
                startIcon={<FileDownloadRoundedIcon />}
                disabled={
                  !global.tableRows.length &&
                  !global.cityProfileReportRows.length
                }
              >
                Export Data
              </Button>
              {(_.includes(['/', '/map-mode'], location.pathname) || isDemo) && (
                <IconButton
                  size='small'
                  onClick={dispatch.setConfigureColumnsOpen}
                >
                  <SettingsIcon />
                </IconButton>
              )}
            </span>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={breakpoint === 'xs' ? 'temporary' : 'persistent'}
        anchor='left'
        open={global.sideNavOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        elevation={0}
        PaperProps={{ sx: { border: 'none' } }}
        onClose={() => dispatch.setSideNavOpen(false)}
      >
        <DrawerHeader>
          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ mr: 2, display: 'flex' }}
          >
            { theme.palette.mode !== 'dark' &&
              <img src={logo} alt='LOGO2' style={{ maxHeight: '46px' }} />
            }
          </Typography>
          <IconButton onClick={() => handleDrawerClose(global)}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider variant='middle' light />
        <List>
          {!isDemo && (
            <ListItemButton
              alignItems='flex-start'
              onClick={() => setProfileOpen(!profileOpen)}
              sx={{
                px: 3,
                '&:hover, &:focus': { '& svg': { opacity: 1 } },
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Stack direction='row'>
                <ListItemIcon sx={{ mt: 0.5 }}>
                  <Avatar
                    {...(localStorage.getItem('userName') &&
                      stringAvatar(localStorage.getItem('userName')!))}
                  ></Avatar>
                </ListItemIcon>
                <Stack>
                  <ListItemText
                    primary={localStorage.getItem('userName')}
                    sx={{ m: 0 }}
                  />
                  <ListItemText secondary='Member' sx={{ m: 0 }} />
                </Stack>
              </Stack>
              <KeyboardArrowDown
                sx={{
                  mr: -1,
                  opacity: 0,
                  transform: profileOpen ? 'rotate(-180deg)' : 'rotate(0)',
                  transition: '0.2s',
                }}
              />
            </ListItemButton>
          )}

          {profileOpen && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '12px',
                paddingLeft: '24px',
              }}
            >
              <SmallButton variant='outlined' sx={{ marginTop: '10px' }}>
                <Link
                  component={RouterLink}
                  to={'/manage-account'}
                  underline='none'
                >
                  Manage Subscription
                </Link>
              </SmallButton>
              <SmallButton
                variant='outlined'
                startIcon={<LogoutIcon />}
                color={'error'}
                onClick={() => {
                  logOut()
                }}
                sx={{ marginTop: '10px' }}
              >
                Log Out
              </SmallButton>
            </div>
          )}
        </List>
        <Divider variant='middle' light />
        <List>
          {global.enableSavedMarkets &&
            <RouterLink
              to='/saved-markets'
              style={{
                textDecoration: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
              }}
            >
              <ListItem style={{ background: location.pathname === '/saved-markets' ? 'lightgray' : 'unset' }} button key={'Dashboard'}>
                <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                  <FactCheckOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={'Dashboard'} />
              </ListItem>
            </RouterLink>
          }

          <RouterLink
            to='/'
            style={{
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
            }}
          >
            <ListItem style={{ background: location.pathname === '/' ? 'lightgray' : 'unset' }} button key={'Browse Markets'}>
              <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                <SpeedRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={'Browse Markets'} />
            </ListItem>
          </RouterLink>
          <RouterLink
            to='/city'
            style={{
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
            }}
          >
            <ListItem style={{ background: location.pathname === '/city' ? 'lightgray' : 'unset' }} button key={'Market Profile'}>
              <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                <ApartmentRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={'Market Profile'} />
            </ListItem>
          </RouterLink>
          <RouterLink
            to='/market-history'
            style={{
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
            }}
          >
            <ListItem style={{ background: location.pathname === '/market-history' ? 'lightgray' : 'unset' }} button key={'Market History'}>
              <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary={'Market History'} />
            </ListItem>
          </RouterLink>

          {/* <RouterLink
            to='/report'
            style={{
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'black',
            }}
          >
            <ListItem button key={'Comparison Report'}>
              <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                <FactCheckOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Comparison Report'} />
            </ListItem>
          </RouterLink> */}

          <RouterLink
            to='/support'
            style={{
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
            }}
          >
            <ListItem style={{ background: location.pathname === '/support' ? 'lightgray' : 'unset' }} button key={'Support'}>
              <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                <SupportOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Support'} />
            </ListItem>
          </RouterLink>
        </List>

        <div
          style={{
            bottom: 0,
            width: '90%',
            textAlign: 'center',
            backgroundColor: theme.palette.mode === 'dark' ? '' : 'white',
            position: 'absolute',
            alignSelf: 'center',
            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
            paddingTop: '30px',
            paddingBottom: '30px',
            marginBottom: '20px',
            borderRadius: '12px',
          }}
        >

          <div>STR Insights Property Finder</div>
          <Button
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            size='large'
            style={{
              width: '170px',
              background:
                'linear-gradient(305.02deg, #FF9A02 4.13%, #FF866C 110.93%)',
            }}
            onClick={() => {
              setShowPropertyFinder(true)
            }}
          >
            Learn More
          </Button>
        </div>
        {isDemo && (
          <div
            style={{
              bottom: 0,
              width: '90%',
              textAlign: 'center',
              backgroundColor: 'white',
              position: 'absolute',
              alignSelf: 'center',
              boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
              paddingTop: '30px',
              paddingBottom: '30px',
              marginBottom: '20px',
              borderRadius: '12px',
            }}
          >
            <div
              style={{
                height: '110px',
                width: '110px',
                borderRadius: '50%',
                backgroundColor: '#F8F9FD',
                textAlign: 'center',
                padding: '15px',
                margin: 'auto',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <Lock />
              </div>
            </div>

            <div>Get the Most Out of STR Insights</div>
            <Button
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              size='large'
              style={{
                width: '170px',
                background:
                  'linear-gradient(305.02deg, #FF9A02 4.13%, #FF866C 110.93%)',
              }}
              onClick={() => {
                sendToGTM()
                navigate('/login')
              }}
            >
              Get Started
            </Button>
          </div>
        )}
      </Drawer>
    </Box>
  )
}

export const Lock = () => {
  return (
    <svg
      width='58'
      height='66'
      viewBox='0 0 58 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M51.7857 32.9999H19.6786V19.7096C19.6786 14.605 23.7826 10.3639 28.9094 10.3124C34.0879 10.2608 38.3214 14.4503 38.3214 19.5936V21.6561C38.3214 23.3706 39.7067 24.7499 41.4286 24.7499H45.5714C47.2933 24.7499 48.6786 23.3706 48.6786 21.6561V19.5936C48.6786 8.76551 39.8103 -0.0387891 28.9353 -0.00011723C18.0603 0.0385546 9.32143 8.95887 9.32143 19.787V32.9999H6.21429C2.78348 32.9999 0 35.7714 0 39.1874V59.8124C0 63.2284 2.78348 65.9999 6.21429 65.9999H51.7857C55.2165 65.9999 58 63.2284 58 59.8124V39.1874C58 35.7714 55.2165 32.9999 51.7857 32.9999ZM34.1786 52.5936C34.1786 55.4425 31.8612 57.7499 29 57.7499C26.1388 57.7499 23.8214 55.4425 23.8214 52.5936V46.4061C23.8214 43.5573 26.1388 41.2499 29 41.2499C31.8612 41.2499 34.1786 43.5573 34.1786 46.4061V52.5936Z'
        fill='url(#paint0_linear_1216_6329)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1216_6329'
          x1='81.5283'
          y1='65.9999'
          x2='-14.0917'
          y2='4.93493'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0DC0F3' />
          <stop offset='1' stopColor='#4DDBB7' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
}

const learnMoreModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  height: 'auto',
  'overflow-y': 'scroll',
}