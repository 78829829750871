import React, { useState } from 'react'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  TextField,
  Button
} from '@mui/material'
import { strFetch } from '../../utils/api'
import BottomSnackbar from 'components/snackbar'
import useConferenceMode from 'hooks/useConferenceTrialMode'
import { useNavigate } from 'react-router-dom'

export default function AmenitiesRegisterRoute() {
  const sendDataToGTM = useGTMDispatch()
  useConferenceMode();
  let navigate = useNavigate()

  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [marketOfChoice, setMarketOfChoice] = useState('')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    strFetch(
      '/login/signup/amenities',
      {
        method: 'POST',
        body: data,
      },
      false
    ).then(body => {
      window.location.href = body.session.url
    })
  }
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url("/city_background.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
    >
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <BottomSnackbar></BottomSnackbar>
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
          }}
        >
          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          ></Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: 'black',
                fontSize: '40px',
                fontWeight: '700',
                marginRight: '4px',
              }}
            >
              Get
            </span>
            <span
              style={{
                fontSize: '40px',
                fontWeight: '700',
                color: 'rgb(51,208,209)',
              }}
            >
              Started
            </span>
          </div>

          <Typography
            component='p'
            style={{ color: '8C96AE', fontSize: '15px' }}
          >
            Ready to evaluate amenities?
          </Typography>
          <Box
            style={{
              border: 'solid 1px #aaa',
              padding: '16px',
              borderRadius: '8px',
              background: 'white',
            }}
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="dense"
              required
              fullWidth
              id='firstName'
              label='First name'
              name='firstName'
              autoComplete='First name'
              onChange={(event) => {
                setFirstName(event.target.value)
              }}
              autoFocus
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id='lastName'
              label='Last name'
              name='lastName'
              autoComplete='Last name'
              onChange={(event) => {
                setLastName(event.target.value)
              }}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id='username'
              label='Email Address'
              name='username'
              autoComplete='email'
              type='email'
              onChange={(event) => {
                let isValid = event.target.value.match(
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
                if (isValid) {
                  // Hide the error messaging from the UI
                  setEmail(event.target.value)
                }
              }}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id='marketOfChoice'
              label='Market of Choice'
              name='marketOfChoice'
              placeholder='ex. Chicago, Illinois'
              onChange={(event) => {
                setMarketOfChoice(event.target.value)
              }}
              autoFocus
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disabled={
                !(firstName && lastName && email && marketOfChoice)
              }
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  )
}