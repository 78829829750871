import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import Switch from '@mui/material/Switch'
import InfoIcon from '@mui/icons-material/Info'
import Stack from '@mui/material/Stack'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Divider,
  SwipeableDrawer,
  TextField,
} from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import useDemoMode from 'hooks/useDemoMode'
import useBreakpoint from 'hooks/useBreakpoint'
import useGlobal from 'hooks/useGlobal'
import { BedroomExtended, MarketExtended, RevenueLimited, RoiLimited } from 'routes/responses'
import { CircularProgress } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { strFetch } from 'utils/api'
import TablePagination from '@mui/material/TablePagination';
import Pagination from '@mui/material/Pagination';
import { track } from 'mixpanel'
import { Breakpoint } from 'utils/breakpoints'
import WarningIcon from '@mui/icons-material/Warning';
import TimelineIcon from '@mui/icons-material/Timeline';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

function descendingComparator<T>(a: MarketExtended, b: MarketExtended, orderBy: keyof MarketExtended, revenuePercentile: string) {

  if (orderBy === 'revenue') {
    let perc = 'fiftieth' as keyof RevenueLimited
    if (revenuePercentile.includes('50')) {
      perc = 'fiftieth' as keyof RevenueLimited
    }
    if (revenuePercentile.includes('75')) {
      perc = 'seventyFifth' as keyof RevenueLimited
    }
    if (revenuePercentile.includes('90')) {
      perc = 'ninetieth' as keyof RevenueLimited
    }
    if (b[orderBy][perc] < a[orderBy][perc]) {
      return -1
    }
    if (b[orderBy][perc] > a[orderBy][perc]) {
      return 1
    }
  }

  if (orderBy === 'roi') {
    let perc = 'fiftieth' as keyof RoiLimited
    if (revenuePercentile.includes('50')) {
      perc = 'fiftieth' as keyof RoiLimited
    }
    if (revenuePercentile.includes('75')) {
      perc = 'seventyFifth' as keyof RoiLimited
    }
    if (revenuePercentile.includes('90')) {
      perc = 'ninetieth' as keyof RoiLimited
    }
    if (b[orderBy][perc] < a[orderBy][perc]) {
      return -1
    }
    if (b[orderBy][perc] > a[orderBy][perc]) {
      return 1
    }
  }

  if (orderBy.toString() === 'valuation') {
    orderBy = 'valuation' as keyof MarketExtended
  }
  if (orderBy.toString() === 'state') {
    orderBy = 'state' as keyof MarketExtended
  }
  if (orderBy.toString() === 'city') {
    orderBy = 'city' as keyof MarketExtended
  }
  if (orderBy.toString() === 'market size') {
    orderBy = 'marketSize' as keyof MarketExtended
  }
  if (orderBy.toString() === 'active listing count') {
    orderBy = 'activeListingCount' as keyof MarketExtended
  }
  if (orderBy.toString() === 'accomodates') {
    orderBy = 'accomodates' as keyof MarketExtended
  }
  if (orderBy.toString() === 'occupancy') {
    orderBy = 'occupancy' as keyof MarketExtended
  }
  if (orderBy.toString() === 'adr') {
    orderBy = 'adr' as keyof MarketExtended
  }


  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 100, 200, 0.04)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof MarketExtended>(
  order: Order,
  orderBy: Key,
  revenuePercentile: string
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a as MarketExtended, b as MarketExtended, orderBy, revenuePercentile)
    : (a, b) => -descendingComparator(a as MarketExtended, b as MarketExtended, orderBy, revenuePercentile)
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: HeadCell[]
  setOpen: Function
  setInfoType: Function
}

function EnhancedTableHead(props: EnhancedTableProps) {
  let navigate = useNavigate()
  const { state: global, dispatch } = useGlobal()

  const { order, orderBy, onRequestSort, headCells, setOpen, setInfoType } =
    props
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {global.enableSavedMarkets &&

          <TableCell padding='checkbox'>
            <Button
              color='primary'
              onClick={() => {
                navigate('/saved-markets')
              }}
            >
              Save to Dashboard
            </Button>
          </TableCell>
        }
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div style={{ display: 'inline-flex' }}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                style={{ textTransform: 'capitalize' }}
              >
                <div style={{ fontSize: '12px' }}>
                  {headCell.label.toUpperCase()}
                </div>
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>

              <IconButton
                onClick={() => {
                  setInfoType(headCell.label.toLowerCase())
                  setOpen(true)
                }}
              >
                <InfoIcon fontSize={'small'} />
              </IconButton>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
  useBedrooms: boolean
  revenuePercentile: any
  setOpen: Function
  setInfoType: Function
  rowsPerPage: number
  mapMode: boolean
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { state: global, dispatch } = useGlobal()
  const { numSelected, useBedrooms, revenuePercentile, setOpen, setInfoType, rowsPerPage, mapMode } =
    props
  function toggleCityROIGrouping(global: any, useBedrooms: boolean) {
    if (!useBedrooms) {
      dispatch.setFilters('bedroomCounts', [])
    }
    dispatch.setUseBedrooms(useBedrooms)
  }

  const regulations = ['Little to none', 'Moderate', 'Heavy', 'Restricted']

  return (
    <div>
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
        p={2}
        alignItems={{
          xs: 'flex-start',
          sm: 'flex-start',
          md: 'center',
          lg: 'center',
        }}
        spacing={2}
      >
        {!mapMode &&
          <Stack direction='column'>
            <FormLabel>
              View city ROI by:
              <IconButton
                onClick={() => {
                  setInfoType('bedroom count toggle')
                  setOpen(true)
                }}
              >
                <InfoIcon fontSize={'small'} />
              </IconButton>
            </FormLabel>

            <ButtonGroup
              aria-label='outlined primary button group'
              size='small'
              sx={{ mt: 1 }}
            >
              <Button
                onClick={() => {
                  toggleCityROIGrouping(global, false)
                  dispatch.search({ useBedrooms: false, skip: 0, take: rowsPerPage })
                }}
                variant={global.useBedrooms ? 'outlined' : 'contained'}
              >
                Average
              </Button>
              <Button
                onClick={() => {
                  toggleCityROIGrouping(global, true)
                  dispatch.search({ useBedrooms: true, skip: 0, take: rowsPerPage })
                }}
                variant={global.useBedrooms ? 'contained' : 'outlined'}
              >
                Bedroom Count
              </Button>
            </ButtonGroup>
          </Stack>
        }

        <FormControl>
          <FormLabel>
            Revenue Percentile:
            <IconButton
              onClick={() => {
                setInfoType('revenue percentile')
                setOpen(true)
              }}
            >
              <InfoIcon fontSize={'small'} />
            </IconButton>
          </FormLabel>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='perc75'
            name='radio-buttons-group'
            row
            onChange={(event: any) => {
              dispatch.setRevenuePercentile(event.target.value)
            }}
          >
            <FormControlLabel
              value='perc90'
              control={<Radio size='small' />}
              label='90'
            />
            <FormControlLabel
              value='perc75'
              control={<Radio size='small' />}
              label='75'
            />
            <FormControlLabel
              value='perc50'
              control={<Radio size='small' />}
              label='50'
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      {/* {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Discover New Markets Based On Your Criteria
        </Typography>
      )} */}
      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </div>
  )
}

interface MarketTableProps {
  rows: any
  useBedrooms: boolean
  headerCells: any
  revenuePercentile: any
  mapMode: boolean
}

export default function MarketTable(props: MarketTableProps) {
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<string>('roi')
  const [selected, setSelected] = useState<readonly string[]>([])
  let isDemo = useDemoMode()
  const breakpoint = useBreakpoint()
  const [open, setIsOpen] = useState<boolean>(false)
  const [infoType, setInfoType] = useState<string>('')
  const { state: global, dispatch } = useGlobal()
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })
  const loading = global.tableLoading
  const totalCount = global.totalCount

  const rows = props.rows
  const headerCells = props.headerCells
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function addSavedMarket(row: MarketExtended | BedroomExtended) {
    strFetch(`/saved-markets`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        city: row.city,
        state: row.state
      }),
    }).then(() => dispatch.addSavedMarketRows(row))
  }

  function deleteSavedMarket(row: MarketExtended | BedroomExtended) {
    strFetch(`/saved-markets`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        city: row.city,
        state: row.state
      }),
    }).then(() => dispatch.removeSavedMarketRows(row))
  }

  function getInfoDescription(label: string): string {
    const hasBedroomFilter = headerCells.filter(
      (a: { label: string }) => a.label === 'bedrooms'
    )[0]

    if (label === 'revenue') {
      return 'The yearly revenue at the selected percentile for all properties that are listed at least 200 days a year. Yearly revenue for properties is calculated by aggregating the daily booking rate across all booked dates. Revenue does not include cleaning fees, taxes, or site fees.'
    }
    if (label === 'roi' && !hasBedroomFilter) {
      return 'ROI for a market is determined by dividing the average revenue in that market by the average valuation in that market.'
    }
    if (label === 'roi' && hasBedroomFilter) {
      return 'ROI for a bedroom count in a market is determined by dividing the average revenue by the average valuation for that bedroom in the given market.'
    }
    if (label === 'valuation' && !hasBedroomFilter) {
      return 'The average home valuation of properties that we have identified as short term rentals in this market.'
    }
    if (label === 'valuation' && hasBedroomFilter) {
      return 'The average home valuation of properties with the given bedroom count that we have identified as short term rentals in this market. '
    }
    if (label === 'active listing count' && !hasBedroomFilter) {
      return 'The total number of active Airbnb and VRBO listings in this market.'
    }
    if (label === 'active listing count' && hasBedroomFilter) {
      return 'The total number of active Airbnb and VRBO listings with the given bedroom count in this market.'
    }
    if (label === 'bedroom count toggle') {
      return 'Select "Market" to view market-wide estimates. Select "Bedroom Count" to view estimates at the bedroom-count level.'
    }
    if (label === 'revenue percentile') {
      return 'The revenue percentile that is used to determine revenue estimates. For example, if the revenue percentile is set to 75, revenue estimates represent listings that are performing better than 75% of all other listings in the given market.'
    }
    if (label === 'market size') {
      return 'The size of the entire market or all short term rentals is based on small, medium and large. Small markets are less than 100. Medium markets are > 100 but < 500. Large markets are > 500 total rentals'
    }
    if (label === 'city') {
      return 'The city in which the property grouping is located'
    }
    if (label === 'state') {
      return 'The state in which the property grouping is located'
    }
    if (label === 'sqft') {
      return 'The average sqft for a group'
    }
    if (label === 'occupancy') {
      return 'The average yearly occupancy for a given property grouping'
    }
    if (label === 'accomodates number') {
      return 'How many people on average can this bedroom count grouping accommodate'
    }
    if (label === 'adr') {
      return 'Average Daily Rate'
    }

    return 'N/A'
  }

  function getROI(row: any) {
    if (props.revenuePercentile === 'perc50') {
      return `${Math.trunc(row.roi.fiftieth * 100)}%`
    }
    if (props.revenuePercentile === 'perc75') {
      return `${Math.trunc(row.roi.seventyFifth * 100)}%`
    }
    if (props.revenuePercentile === 'perc90') {
      return `${Math.trunc(row.roi.ninetieth * 100)}%`
    }
  }

  function getRevenue(row: MarketExtended) {
    if (props.revenuePercentile === 'perc50') {
      return `${formatter.format(row.revenue.fiftieth)}`
    }
    if (props.revenuePercentile === 'perc75') {
      return `${formatter.format(row.revenue.seventyFifth)}`
    }
    if (props.revenuePercentile === 'perc90') {
      return `${formatter.format(row.revenue.ninetieth)}`
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

    track('Pagination Detection', {
      user: {
        name: localStorage.getItem('userName'),
        email: localStorage.getItem('email'),
      },
      rowsPerPage: rowsPerPage,
      page: newPage,
      filters: global.filters
    })
    dispatch.search({ skip: (newPage - 1) * rowsPerPage, take: rowsPerPage })
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    track('Pagination Detection', {
      user: {
        name: localStorage.getItem('userName'),
        email: localStorage.getItem('email'),
      },
      rowsPerPage: rowsPerPage,
      page: 0,
      filters: global.filters
    })
    dispatch.search({ skip: 0, take: parseInt(event.target.value, 10) })

  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  function getWidth(breakpoint: Breakpoint | undefined) {
    if (breakpoint === 'md') {
      return '110em'
    }
    return '100%'
  }

  return (
    <Paper
      sx={{ width: '100%', borderRadius: '20px', mb: 2, mt: -4 }}
      elevation={!props.mapMode ? 24 : 0}
    >
      <SwipeableDrawer
        anchor={'right'}
        open={open}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        <Box sx={{ width: 300 }} role='presentation'>
          <div style={{ textAlign: 'center' }}>
            <h2>{infoType.toUpperCase()}</h2>
          </div>
          <Divider></Divider>

          <div style={{ padding: 20 }}>
            {getInfoDescription(infoType.trim())}
          </div>
        </Box>
      </SwipeableDrawer>

      <EnhancedTableToolbar
        numSelected={selected.length}
        useBedrooms={props.useBedrooms}
        revenuePercentile={props.revenuePercentile}
        setInfoType={setInfoType}
        setOpen={setIsOpen}
        rowsPerPage={rowsPerPage}
        mapMode={props.mapMode}
      />
      <TableContainer
        sx={{
          maxHeight: ['xs', 'sm'].includes(breakpoint!) ? 440 : 'auto',
          boxShadow: !props.mapMode ? ['xs', 'sm'].includes(breakpoint!)
            ? 'inset -6px 0px 4px 0px rgba(0,0,0,0.22)'
            : 'none' : 'unset',
          borderRadius: '20px',
        }}
      >
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby='tableTitle'
          size={'small'}
          stickyHeader
        >
          <EnhancedTableHead
            setInfoType={setInfoType}
            setOpen={setIsOpen}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={(
              event: React.MouseEvent<unknown>,
              property: string
            ) => {
              const isAsc = orderBy === property && order === 'asc'

              dispatch.setFilterOrderBy(property)
              dispatch.setFilterOrder(isAsc ? 'desc' : 'asc')

              handleRequestSort(event, property)
              setPage(1)
              track('Pagination Detection', {
                user: {
                  name: localStorage.getItem('userName'),
                  email: localStorage.getItem('email'),
                },
                rowsPerPage: rowsPerPage,
                page: 0,
                filters: global.filters
              })
              dispatch.search({ skip: 0, take: rowsPerPage, order: isAsc ? 'desc' : 'asc', orderBy: property })

            }}
            rowCount={rows.length}
            headCells={headerCells}
          />
          <TableBody>
            {/* {location.pathname == '/demo' && (
                  <StyledTableRow hover role='checkbox'>
                    <TableCell style={{ background: 'white' }} colSpan={8} height={150}>
                      <div style={{ textAlign: 'center', fontSize: '20px' }}>Log In or Sign Up to Find Emerging Markets! </div>
                    </TableCell>
                  </StyledTableRow>
                )} */}
            {rows.length > 0 &&
              rows
                .sort(getComparator(order, orderBy as keyof MarketExtended, props.revenuePercentile))
                .map((row: MarketExtended | BedroomExtended, index: any) => {

                  return (
                    <StyledTableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={`row-${index}`}
                    >
                      {global.enableSavedMarkets &&

                        <TableCell
                          padding='checkbox'
                          key={`${index}-checkbox`}
                        >
                          {global.savedMarketRows.includes(row) || row.savedMarket ? (
                            <CheckBoxIcon
                              color='primary'
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                row.savedMarket = false
                                deleteSavedMarket(row)
                                e.stopPropagation()
                              }}
                            />
                          ) : (
                            <CheckBoxOutlineBlankIcon
                              color='primary'
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                addSavedMarket(row)
                                e.stopPropagation()
                              }}
                            />
                          )}
                        </TableCell>
                      }
                      {/* {Object.keys(row).map((cell) => {
                        return (
                          <TableCell align='right' key={`${index}-${cell}`}>
                            {getCellFormat(row, cell, dispatch, isDemo, props.revenuePercentile)}
                          </TableCell>
                        )
                      })} */}
                      <TableCell align='right' key={`${index}-state`}>
                        <span>{row.state}</span>
                      </TableCell>
                      {!isDemo &&
                        <TableCell align='right' key={`${index}-city`}>
                          <div style={{ display: 'inline-flex' }}>
                            <span style={{ paddingRight: '8px' }}>{row.city}</span>

                            <Link
                              to={`/city?city=${row.city}&state=${row.state}`}
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                                color: 'black',
                                alignItems: 'center',
                              }}
                              onClick={() => {
                                dispatch.setStateAndCityFilters(row['city'], row['state'])
                              }}
                            >
                              <span>
                              <Tooltip title="Market Profile">
                                <LocationSearchingIcon fontSize='small' />
                              </Tooltip>
                              </span>
                            </Link>
                            <Link
                              to={`/market-history?city=${row.city}&state=${row.state}`}
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                                color: 'black',
                                alignItems: 'center',
                                marginLeft: 12
                              }}
                              onClick={() => {
                                dispatch.setStateAndCityFilters(row['city'], row['state'])
                              }}
                            >
                              <span>
                              <Tooltip title="Market History">
                                <TimelineIcon fontSize='small' />
                              </Tooltip>
                              </span>
                            </Link>
                          </div>


                        </TableCell>
                      }
                      {isDemo &&
                        <TableCell align='right' key={`${index}-city`}>
                          <span style={blur}>{row.city}</span>
                          <span>
                            <Tooltip
                              title={'Sign Up to See Emerging Markets!'}
                              placement='top-start'
                            >
                              <LocationSearchingIcon fontSize='small' />
                            </Tooltip>
                          </span>
                        </TableCell>

                      }
                      <TableCell align='right' key={`${index}-marketSize`}>
                        {row.marketSize === 'small' && props.mapMode &&
                          <Tooltip title="Please note that the current metrics may be limited by a listing count, which could impact the reliability of the results. Therefore, caution should be exercised when drawing conclusions from the available data. Consider filtering the market size to view markets with a greater listing count.">
                            <IconButton>
                              <WarningIcon style={{ fontSize: '20px', color: '#FFD580' }} />
                            </IconButton>
                          </Tooltip>
                        }
                        <span>{row.marketSize ? row.marketSize.toUpperCase() : row.marketSize ?? 'N/A'}</span>

                      </TableCell>
                      {props.useBedrooms &&
                        <TableCell align='right' key={`${index}-bedrooms`}>
                          <span>{(row as BedroomExtended).bedrooms}</span>
                        </TableCell>
                      }


                      <TableCell align='right' key={`${index}-roi`}>
                        <span>{getROI(row)}</span>
                      </TableCell>
                      <TableCell align='right' key={`${index}-revenue`}>
                        <span>{getRevenue(row)}</span>
                      </TableCell>
                      <TableCell align='right' key={`${index}-valuation`}>
                        <span>{formatter.format(row.valuation)}</span>
                      </TableCell>
                      <TableCell align='right' key={`${index}-activeListinCount`}>
                        <span>{row.activeListingCount}</span>
                      </TableCell>
                      {props.headerCells.filter((r: any) => r.label === 'ACCOMODATES').length > 0 &&
                        <TableCell align='right' key={`${index}-accomodates`}>
                          <span>{Math.round((row).accomodates)}</span>
                        </TableCell>
                      }
                      {props.headerCells.filter((r: any) => r.label === 'OCCUPANCY').length > 0 &&
                        <TableCell align='right' key={`${index}-occupancy`}>
                          <span>{Math.trunc(row.occupancy * 100)}%</span>
                        </TableCell>
                      }
                      {props.headerCells.filter((r: any) => r.label === 'ADR').length > 0 &&
                        <TableCell align='right' key={`${index}-adr`}>
                          <span>{formatter.format(row.adr)}</span>
                        </TableCell>
                      }
                      {/* <TableCell
                        component='th'
                        id={labelId}
                        scope='row'
                        padding='none'
                        key={`${row.name}-name-${index}`}
                      >
                        {row.name}
                      </TableCell> */}
                    </StyledTableRow>
                  )
                })}
            {rows.length === 0 && !loading && (
              <StyledTableRow hover role='checkbox'>
                <TableCell colSpan={8}>
                  <div style={{ textAlign: 'center' }}>No Results</div>
                </TableCell>
              </StyledTableRow>
            )}
            {rows.length === 0 && loading && (
              <StyledTableRow hover role='checkbox'>
                <TableCell colSpan={8}>
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={60} />

                  </div>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{ paddingRight: !['xs', 'sm'].includes(breakpoint!) ? '0px' : 30 }}
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(subProps: any) => {
          const {
            count,
            rowsPerPage,
            backIconButtonProps,
            nextIconButtonProps,
            showLastButton,
            getItemAriaLabel,
            showFirstButton,
            onPageChange,
            ...restSubProps
          } = subProps;
          return (
            <>
              {!['xs', 'sm'].includes(breakpoint!) &&
                <Pagination style={{ width: getWidth(breakpoint), marginRight: 10, marginLeft: 30 }} onChange={handleChangePage} count={Math.ceil(totalCount / rowsPerPage)} page={page} variant="outlined" size="small" shape="rounded" />
              }
            </>
          );
        }}
      />

      {['xs', 'sm'].includes(breakpoint!) &&
        <Pagination style={{ marginLeft: '15em' }} onChange={handleChangePage} count={Math.ceil(totalCount / rowsPerPage)} page={page} variant="outlined" size="small" shape="rounded" />
      }
    </Paper>
  )
}

const blur = {
  width: '100%',
  heigth: '100%',
  background: 'rgba(255, 255, 255, 0.2)', // Make sure this color has an opacity of less than 1
  filter: 'blur(8px)', // This be the blur
  paddingRight: '8px',
}
