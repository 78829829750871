import BlockRoundedIcon from '@mui/icons-material/BlockRounded'

const Restricted = () => {
  return (
    <div
      style={{
        backgroundColor: '#fcdcda',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
      }}
    >
      <BlockRoundedIcon fontSize='large' sx={{ color: '#eb3223' }} />
    </div>
  )
}

export default Restricted
