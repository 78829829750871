import {
  Accordion, AccordionDetails, AccordionSummary, InputLabel,
  MenuItem,
  Select, Autocomplete, Avatar, Skeleton, Box, Button, Card, FormControl, Checkbox, Chip, CircularProgress, Divider, Grid, IconButton, Modal, Stack, SwipeableDrawer, TextField, Typography, SelectChangeEvent
} from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import { Market } from "routes/responses"
import { strFetch, strFetchBlob } from "utils/api"
import InfoIcon from '@mui/icons-material/Info'
import useDemoMode from "hooks/useDemoMode"
import QuestionMark from './regulation-icons/Question-Mark'
import Little from './regulation-icons/Little'
import Heavy from './regulation-icons/Heavy'
import Moderate from './regulation-icons/Moderate'
import Restricted from './regulation-icons/Restricted'
import useGlobal from "hooks/useGlobal"
import { track } from '../../mixpanel'
import bockholdt from '../../images/Bockholdt.jpg'
import mortgageShop from '../../images/MortgageShop.png'

import useBreakpoint from "hooks/useBreakpoint"
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import GoogleMapReact from "google-map-react"
import Marker from "./Marker"
import CityProfileTable from "./map-table"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useSupercluster from "use-supercluster"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import { theme } from "styles/themes"
import { Sidebar } from "primereact/sidebar";
import { TabPanel, TabView } from "primereact/tabview";
import RevenueDetails from "./RevenueDetails"
import PropertyInfo from "./PropertyInfo"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface MapProps {
  cityName: string
  state: string
  cityProfileFilters: any
  revenuePercentile: number
}
interface RealtorInfoWrapper {
  realtorInfo: any
  realtorPhoto: any
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

// 0 is not available as we don't need to show 
// Studios that are out in the market
const bedroomCounts = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
]

interface LocationForExport {
  ListingID: string;
  ListingLink: string;
  Bedrooms: number;
  Bathrooms: number;
  Reviews: number;
  Revenue: string;
  ADR: string;
  Occupancy: string;
  ListingAge: string;
  ZipCode: string;
  Valuation: string | null | number;
  Rating: any;
}

const cityImageBaseUrl = 'https://cdn.strinsights.com/city-profile-photos/';
const realtorImageBaseUrl = 'https://cdn.strinsights.com/realtor-photos/'
const ClusterMarker = ({ children }: any) => children;

export default function CityProfileRoute(props: MapProps) {
  let isDemo = useDemoMode()
  const navigate = useNavigate()

  const { state: global, dispatch } = useGlobal()
  const breakpoint = useBreakpoint()

  const [shouldShowImage, setShouldShowImage] = useState(true)
  const [smallDataCardsLoading, setSmallDataCardsLoading] = useState(true)
  const [market, setMarket] = useState<Market>();
  const [zipCodeMarkets, setZipCodeMarkets] = useState<Market[]>();
  const [overallMarket, setOverallMarket] = useState<Market>();

  const [infoType, setInfoType] = useState<string>('')
  const [open, setIsOpen] = useState<boolean>(false)
  const [realtorDataLoading, setRealtorDataLoading] = useState(true)
  const [locationsLoading, setLocationsLoading] = useState(true)

  const [propertyForProfile, setPropertyForProfile] = useState<any>();
  const [propertyProfileVisible, setPropertyProfileVisible] = useState<boolean>(false);
  const [revenueForProfile, setRevenueForProfile] = useState<any>();

  const [bedroomCountForProperties, setBedroomCountForProperties] = useState<string[]>([])
  const [realtorInfo, setRealtorInfo] = useState<RealtorInfoWrapper>({
    realtorInfo: null,
    realtorPhoto: null,
  })
  const [locations, setLocations] = useState<any[]>([])
  const [center, setCenter] = useState({ lat: 40.75855, lng: -73.98956 })
  const [polygons, setPolygons] = useState<any[]>([])

  const [shouldShowReadMore, setShouldShowReadMore] = useState(false)
  const [showRealtorModal, setShowRealtorModal] = useState(false)
  const [showRealtorModalForRegulation, setShowRealtorModalForRegulation] = useState(false)
  const [showContactInfo, setShowContactInfo] = useState(false)
  const [showContactInfoForLender, setShowContactInfoForLender] = useState(false)
  const [chosenLender, setChosenLender] = useState('')

  const [shouldShowContactInfo, setShouldShowContactInfo] = useState(false)
  const [shouldShowLenderContactInfo, setShouldShowLenderContactInfo] = useState(false)

  const [emailDemo, setEmailDemoMode] = useState('')
  const [name, setNameDemoMode] = useState('')
  const [isSent, setIsSent] = useState(false)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [apiError, setApiError] = useState(false)
  const [zipCode, setZipCode] = useState('')
  const [boundaryMap, setBoundaryMap] = useState<Map<string, any>>()
  const [zipCodeList, setZipCodeList] = useState<string[]>([])
  const [superclusterToUse, setSuperCluster] = useState<any>();
  const [clustersToUse, setClusters] = useState<any[]>([]);
  const [bounds, setBounds] = useState<number[]>([]);
  const [zoom, setZoom] = useState(10);
  const [propertiesToExport, setPropertiesToExport] = useState<any[]>([]);
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://strinsights.steadilypartner.com/partner/v3.js";
    script.async = true;

    document.body.appendChild(script);

  }, [])

  async function fetchRevenueByListingId(listingId: string) {
    const data = await strFetch(`/revenue-info/${listingId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    setRevenueForProfile(data)
  }

  async function fetchExtraPropertyInfoByListingId(listingId: string) {
    const data = await strFetch(`/property-info/${listingId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    setPropertyForProfile(data)
  }
  const mapRef = useRef<any>();

  const handleApiLoaded = (map: any, maps: { Polygon: new (arg0: { paths: { lat: number; lng: number }[]; strokeColor: string; strokeOpacity: number; strokeWeight: number; fillColor: string; fillOpacity: number; clickable: boolean, indexID: number }) => any }) => {
    setZipCodeList(polygons.map(a => a.zipCode).sort())
    const boundaryMapTemp = new Map();

    polygons.forEach(polygon => {
      if (polygon?.polygon.includes('next-polygon')) {
        let unsplitPolygons = polygon?.polygon.split(',next-polygon,');

        for (let [index, sectionedPolygon] of unsplitPolygons.entries()) {
          const polygonCoords = sectionedPolygon.split(',').map((a: string) => {
            return { lng: Number.parseFloat(a.trim().split(' ')[0]), lat: Number.parseFloat(a.trim().split(' ')[1]) }
          })
          var boundary = new maps.Polygon({
            paths: polygonCoords,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "transparent",
            fillOpacity: 0.35,
            clickable: true,
            indexID: polygon.zipCode
          });

          if (polygonCoords) {
            console.log(polygon.center.split(';')[index].split(',')[1])
            const markerLabel = new google.maps.Marker({
              position: { lat: Number.parseFloat(polygon.center.split(';')[index].split(',')[1]), lng: Number.parseFloat(polygon.center.split(';')[index].split(',')[0]) },
              title: polygon.zipCode,
              label: {
                fontWeight: '800',
                text: polygon.zipCode,
              },
              icon: ' ',
            });
            markerLabel.setMap(map);

          }

          boundary.setMap(map);
          if (boundaryMapTemp.get(polygon.zipCode)) {
            boundaryMapTemp.get(polygon.zipCode).push(boundary)
          } else {
            boundaryMapTemp.set(polygon.zipCode, [boundary])
          }

          google.maps.event.addListener(boundary, 'click', function (event: any) {

            boundaryMapTemp?.forEach((a, b) => {
              if (b === polygon.zipCode) {
                a.forEach((x: { setOptions: (arg0: { strokeColor: string; strokeOpacity: number; strokeWeight: number; fillColor: string; fillOpacity: number }) => void }) => {
                  x.setOptions({
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35,
                  });
                })
              }

            })

            boundaryMapTemp?.forEach((a, b) => {

              if (b !== polygon.zipCode) {
                a.forEach((x: { setOptions: (arg0: { strokeColor: string; strokeOpacity: number; strokeWeight: number; fillColor: string; fillOpacity: number }) => void }) => {
                  x.setOptions({
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "transparent",
                    fillOpacity: 0.35,
                  })
                })
              }
            })
            setZipCode(polygon.zipCode)
            setMarket(zipCodeMarkets?.filter(a => a.marketDetails.zipCode === polygon.zipCode)[0])
          });

        }

      } else {
        const polygonCoords = polygon?.polygon.split(',').map((a: string) => {
          return { lng: Number.parseFloat(a.trim().split(' ')[0]), lat: Number.parseFloat(a.trim().split(' ')[1]) }
        })
        var boundary = new maps.Polygon({
          paths: polygonCoords,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "transparent",
          fillOpacity: 0.35,
          clickable: true,
          indexID: polygon.zipCode
        });

        if (polygonCoords) {
          const markerLabel = new google.maps.Marker({
            position: { lat: Number.parseFloat(polygon.center.split(',')[1]), lng: Number.parseFloat(polygon.center.split(',')[0]) },
            title: polygon.zipCode,
            label: {
              fontWeight: '800',
              text: polygon.zipCode,
              color: theme.palette.mode === 'dark' ? 'white' : 'black'
            },
            icon: ' ',
          });
          markerLabel.setMap(map);

        }

        boundary.setMap(map);
        boundaryMapTemp.set(polygon.zipCode, [boundary])

        google.maps.event.addListener(boundary, 'click', function (event: any) {

          boundaryMapTemp?.forEach((a, b) => {
            if (b === polygon.zipCode) {
              a.forEach((x: { setOptions: (arg0: { strokeColor: string; strokeOpacity: number; strokeWeight: number; fillColor: string; fillOpacity: number }) => void }) => {
                x.setOptions({
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                });
              })
            }

          })

          boundaryMapTemp?.forEach((a, b) => {

            if (b !== polygon.zipCode) {
              a.forEach((x: { setOptions: (arg0: { strokeColor: string; strokeOpacity: number; strokeWeight: number; fillColor: string; fillOpacity: number }) => void }) => {
                x.setOptions({
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "transparent",
                  fillOpacity: 0.35,
                })
              })
            }
          })
          setZipCode(polygon.zipCode)
          setMarket(zipCodeMarkets?.filter(a => a.marketDetails.zipCode === polygon.zipCode)[0])
        });
      }
    })

    setBoundaryMap(boundaryMapTemp)

  }

  useEffect(() => {
    if (zipCode === '') {


      boundaryMap?.forEach((a, b) => {

        a.forEach((x: { setOptions: (arg0: { strokeColor: string; strokeOpacity: number; strokeWeight: number; fillColor: string; fillOpacity: number }) => void }) => {
          x.setOptions({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "transparent",
            fillOpacity: 0.35,
          })
        })



      })

      setMarket(overallMarket)

    } else {
      boundaryMap?.get(zipCode).forEach((x: { setOptions: (arg0: { strokeColor: string; strokeOpacity: number; strokeWeight: number; fillColor: string; fillOpacity: number }) => void }) => {
        x.setOptions({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
      })


      boundaryMap?.forEach((a, b) => {

        if (b !== zipCode) {
          a.forEach((x: { setOptions: (arg0: { strokeColor: string; strokeOpacity: number; strokeWeight: number; fillColor: string; fillOpacity: number }) => void }) => {
            x.setOptions({
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "transparent",
              fillOpacity: 0.35,
            })
          })
        }
      })
      setMarket(zipCodeMarkets?.filter(a => a.marketDetails.zipCode === zipCode)[0])
    }

  }, [zipCode])

  useEffect(() => {
    setSmallDataCardsLoading(true)

    strFetch(`/market/${props.state}/${props.cityName}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }).then((data) => {
      if (data.cause) {
        setApiError(true);
        setSmallDataCardsLoading(false)
      } else {
        setZipCode('')
        setApiError(false);
        setMarket(data.overallMarkets);
        setOverallMarket(data.overallMarkets)
        setZipCodeMarkets(data.zipCodeMarkets)
        setShouldShowImage(true)
        setSmallDataCardsLoading(false)
      }
    })

  }, [props.cityName, props.state])

  useEffect(() => {
    async function getData() {
      // When the city and state changes (See the dependency props on this effect) We
      // will clear the bedroom counts so that all markets start with the default instead
      // utilizing the value that persisted across other markets.
      dispatch.setCityProfileFilters('bedroomCounts', [])
      setBedroomCountForProperties([])

      setRealtorDataLoading(true)
      setLocationsLoading(true)

      strFetch(`/realtors/${props.state}/${props.cityName}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }).then((realtorResult) => {
        setRealtorInfo(realtorResult)
        setRealtorDataLoading(false)
      })

      strFetch(`/locations/${props.state}/${props.cityName}`, {
        headers: { 'Content-Type': 'application/json' },
      }).then((result) => {
        if (!result || result.mapListings.length === 0) {
          setLocations([])
          setLocationsLoading(false)
          return
        }
        setLocations(result.mapListings)
        setCenter({ lat: result.mapListings[0].latitude, lng: result.mapListings[0].longitude })
        setPolygons(result.polygons)
        setLocationsLoading(false)

      })
    }
    getData()
    track('viewed market profile', {
      city: props.cityName,
      state: props.state,
    })
    // Both here as there are duplicate City names.
  }, [props.cityName, props.state])

  const hideImg = () => {
    setShouldShowImage(false)
  }

  function isDemoCityAndState() {
    return props.cityName === 'Denver' && props.state === 'Colorado'
  }

  function formatMoney(amount: number) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })

    return formatter.format(amount)
  }

  function getByPercentileNumber(isROI: boolean) {
    if (isDemo && !isDemoCityAndState()) {
      return null;
    }

    if (props.revenuePercentile === 50) {
      return isROI ? market?.marketDetails.roi.fiftieth : market?.marketDetails.revenue.fiftieth;
    }
    if (props.revenuePercentile === 60) {
      return isROI ? market?.marketDetails.roi.sixtieth : market?.marketDetails.revenue.sixtieth;
    }
    if (props.revenuePercentile === 70) {
      return isROI ? market?.marketDetails.roi.seventieth : market?.marketDetails.revenue.seventieth;
    }
    if (props.revenuePercentile === 75) {
      return isROI ? market?.marketDetails.roi.seventyFifth : market?.marketDetails.revenue.seventyFifth;
    }
    if (props.revenuePercentile === 80) {
      return isROI ? market?.marketDetails.roi.eightieth : market?.marketDetails.revenue.eightieth;
    }
    if (props.revenuePercentile === 90) {
      return isROI ? market?.marketDetails.roi.ninetieth : market?.marketDetails.revenue.ninetieth;
    }
    if (props.revenuePercentile === 95) {
      return isROI ? market?.marketDetails.roi.ninetyFifth : market?.marketDetails.revenue.ninetyFifth;
    }
    return null;
  }

  function calculateAvgROI() {
    if (
      !market?.marketDetails ||
      !getByPercentileNumber(true)
    ) {
      return 'No Data Available'
    }

    return `${Math.trunc(getByPercentileNumber(true) as number * 100
    )}%`
  }

  function getAverageOccupancy() {
    if (market?.marketDetails && market?.marketDetails.occupancy) {
      return `
  ${Math.trunc(market?.marketDetails.occupancy * 100)} %`
    } else {
      return 'No Data Available'
    }
  }

  function getADR() {
    if (market?.marketDetails && market?.marketDetails.adr) {
      return formatMoney(market?.marketDetails.adr)
    } else {
      return 'No Data Available'
    }
  }

  const regulationInfo: {
    icon: () => JSX.Element
    label: string
    color: string
  } = useMemo(() => {
    switch (market?.regulationAmount) {
      case 'Little to none':
        return {
          label: 'Little',
          icon: Little,
          color: '#2fb344',
        }
      case 'Moderate':
        return {
          label: 'Moderate',
          icon: Moderate,
          color: '#5686E1',
        }
      case 'Heavy':
        return {
          label: 'Heavy',
          icon: Heavy,
          color: '#f59f00',
        }
      case 'Restricted':
        return {
          label: 'Restricted',
          icon: Restricted,
          color: '#eb3223',
        }
      default:
        return {
          label: 'Unknown',
          icon: QuestionMark,
          color: '#616876',
        }
    }
  }, [market?.regulationAmount])

  function getInfoDescription(label: string): string {
    if (label === 'revenue') {
      return 'The yearly revenue at the selected percentile for all properties that are listed at least 200 days a year. Yearly revenue for properties is calculated by aggregating the daily booking rate across all booked dates. Revenue does not include cleaning fees, taxes, or site fees.'
    }
    if (label === 'roi' || label === 'gross roi') {
      return 'ROI for a bedroom count in a market is determined by dividing the average revenue by the average valuation for that bedroom in the given market.'
    }
    if (label === 'valuation') {
      return 'The average home valuation of properties with the given bedroom count that we have identified as short term rentals in this market. '
    }
    if (label === 'active listing count') {
      return 'The total number of active Airbnb and VRBO listings with the given bedroom count in this market.'
    }
    if (label === 'bedroom count toggle') {
      return 'Select "Market" to view market-wide estimates. Select "Bedroom Count" to view estimates at the bedroom-count level.'
    }
    if (label === 'occupancy') {
      return 'The average yearly occupancy for a given property grouping'
    }
    if (label === 'adr') {
      return 'Average Daily Rate'
    }
    if (label === 'bedrooms') {
      return 'The bedroom count for a given property group'
    }
    if (label === 'regulation') {
      return getDescription()
    }
    if (label === 'realtor') {
      return 'This is our recommended STR realtor that knows the regulation of this market as well as owns active short term rental properties.'
    }
    if (label === 'lender') {
      return 'This is our recommended lender who specializes in loan products for short term rentals.'
    }

    return 'N/A'
  }

  function getDescription() {
    if (market?.regulationAmount === 'Little to none') {
      return 'Short-term rentals have little to no restrictions in this market. There are no confirmed neighborhood, city or county restrictions on short term rentals. Please verify independently.'
    }
    if (market?.regulationAmount === 'Moderate') {
      return 'Short-term rentals are moderately regulated in this market. Some neighborhoods, cities or counties may limit or restrict the number of rentals.'
    }
    if (market?.regulationAmount === 'Heavy') {
      return 'Short-term rentals are heavily regulated in this market and may only be allowed in certain zones, areas, or property types for absentee owners'
    }
    if (market?.regulationAmount === 'Restricted') {
      return 'This market does not allow short term rentals in residential zone areas for absentee owners. You cannot own or operate a vacation rental in this entire market.'
    }
    return 'We do not have enough data or information to feel confident in categorizing the regulation for this market. Please contact a professional before purchasing.'
  }

  const bio = useMemo(() => {
    function evaluateBio() {
      let description = realtorInfo.realtorInfo?.description.split(' ')
      setShouldShowReadMore(false)

      if (description?.length > 50) {
        let descriptionShortened = description.slice(0, 50).join(' ')
        setShouldShowReadMore(true)

        return descriptionShortened
      }
      return realtorInfo.realtorInfo?.description
    }
    return evaluateBio()
  }, [realtorInfo.realtorInfo?.description])

  function trackContactInfoClick(shouldTrack: boolean) {
    if (shouldTrack) {
      track('Contact Info Popup Opened', {
        realtor: realtorInfo.realtorInfo,
        user: {
          name: localStorage.getItem('userName'),
          email: localStorage.getItem('email'),
        },
      })
    } else {
      if (shouldShowContactInfo) {
        // For searches that happens after the initial collection
        // of info without a browser refresh we will use that info
        // to track.
        showContactInfoForRealtorAndTrack()
      }
    }
  }

  function trackLenderContactInfoClick(shouldTrack: boolean) {
    if (shouldTrack) {
      track('Lender Contact Info Popup Opened', {
        user: {
          name: localStorage.getItem('userName'),
          email: localStorage.getItem('email'),
        },
      })
    } else {
      if (shouldShowLenderContactInfo) {
        // For searches that happens after the initial collection
        // of info without a browser refresh we will use that info
        // to track.
        showContactInfoForLenderAndTrack()
      }
    }
    strFetch(`/lender/email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(() => { })
  }

  function showContactInfoForLenderAndTrack() {
    setShouldShowLenderContactInfo(true)
    track('Lender Contact Info Popup Opened', {
      realtor: realtorInfo.realtorInfo,
      user: {
        name: name,
        email: emailDemo,
      },
    })
  }
  function showContactInfoForRealtorAndTrack() {
    setShouldShowContactInfo(true)
    track('Contact Info Popup Opened', {
      realtor: realtorInfo.realtorInfo,
      user: {
        name: name,
        email: emailDemo,
      },
    })
  }
  const handleSubmit = async () => {
    const data = {
      email,
      message,
      city: props.cityName,
      state: props.state,
    }

    const result = await strFetch(
      '/realtor/email',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
        },
      },
      false
    )
  }

  function trackEmailDelivery() {
    track('Email Sent To Realtor', {
      realtor: realtorInfo.realtorInfo,
      user: {
        name: localStorage.getItem('userName'),
        email: localStorage.getItem('email'),
      },
    })
  }
  var filteredLocations = locations.filter((location: any) => {
    if (props.cityProfileFilters.bedroomCounts) {
      if (
        props.cityProfileFilters.bedroomCounts.includes('6+') &&
        location.bedrooms > 5
      ) {
        return true
      }
      return props.cityProfileFilters.bedroomCounts.includes(
        location.bedrooms.toString()
      )
    }
    return true
  })

  const grouped = _.groupBy(filteredLocations, location => location.bedrooms);

  let property: keyof typeof grouped;

  filteredLocations = [];

  for (property in grouped) {
    let locs = grouped[property].slice(
      0,
      Math.round(((100 - Number(props.revenuePercentile)) * grouped[property].length) / 100)
    )
    filteredLocations.push(...locs)
  }

  const data = useMemo(() => {
    let response = [];

    response = filteredLocations.map((elem: any) => {
      return {
        type: "Feature",
        properties: {
          airbnb_id: elem.airbnb_id,
          vrbo_id: elem.vrbo_id,
          revenue: elem.revenue,
          occupancy: elem.occupancy,
          adr: elem.adr,
          bathrooms: elem.bathrooms,
          bedrooms: elem.bedrooms,
          longitude: elem.longitude,
          latitude: elem.latitude,
          reviews: elem.review_count,
          start_date: elem.start_date,
          zip_code: elem.zip_code,
          rating: elem.rating,
          propertyInfo: elem.propertyInfo
        },
        geometry: {
          type: "Point",
          coordinates: [parseFloat(elem.longitude), parseFloat(elem.latitude)]
        }
      };
    });

    return response;
  }, [filteredLocations]);

  const { clusters, supercluster } = useSupercluster({
    points: data,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  useEffect(() => {
    setClusters(clusters)
    setSuperCluster(supercluster)
  }, [clusters, supercluster])

  useEffect(() => {
    if (superclusterToUse && clustersToUse.length > 0) {
      let propertiesForExport: any[] = [];
      for (let cluster of clustersToUse) {
        // Dont go crazy on this threshold for performance reasons
        if (cluster.properties.cluster_id) {
          const leaves = superclusterToUse.getLeaves(cluster.properties.cluster_id, 10000, 0);
          for (let leaf of leaves) {
            propertiesForExport.push(leaf.properties)
          }
        }
        if (cluster.properties.revenue) {
          propertiesForExport.push(cluster.properties)
        }
      }
      setPropertiesToExport(propertiesForExport)

    } else {
      setPropertiesToExport([])

    }
  }, [clustersToUse, superclusterToUse]);

  const lenderLearnMore = (lender: any) => {
    track('Lender Site Clicked', {
      user: {
        name: localStorage.getItem('userName'),
        email: localStorage.getItem('email'),
      },
      lender: lender
    })
    strFetch(`/lender/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: lender,
        email: lender === 'Brian Bockholdt' ? 'brian.m.bockholdt@huntington.com' : 'brenna@mortgageshop.co'
      })
    }).then(() => {
      if (lender === 'Brian Bockholdt') {
        window.open('https://www.huntington.com/mortgage/bockholdt-brian', '_blank');
      } else {
        window.open('https://www.mortgage.shop', '_blank');
      }
    })
  }

  const realtorLearnMore = () => {
    track('Realtor Potential User', {
      user: {
        name: localStorage.getItem('userName'),
        email: localStorage.getItem('email'),
      },
    })
    window.open('https://www.strinsights.com/post/realtor-partnership-program-now-accepting-applications-to-become-a-strinsights-preferred-real-estate-agent', '_blank');
  }

  const download = async () => {
    let blob
    let columns
    let exportData: LocationForExport[] = [];
    propertiesToExport.forEach(a => {
      var diff = Math.abs(new Date().getTime() - new Date(a.start_date).getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      let zipMarket = zipCodeMarkets?.find(x => x.marketDetails.zipCode === a.zip_code);
      let bedroomMarket = zipMarket?.bedroomDetails?.find(r => r.bedroomCount === a.bedrooms);

      exportData.push({
        ListingID: a.airbnb_id ? a.airbnb_id : a.vrbo_id,
        ListingLink: a.airbnb_id
          ? 'https://airbnb.com/rooms/' + a.airbnb_id
          : a.vrbo_id
            ? 'https://vrbo.com/' + a.vrbo_id
            : '',
        ZipCode: a.zip_code ? a.zip_code : 'N/A',
        Bedrooms: a.bedrooms,
        Bathrooms: a.bathrooms,
        Reviews: a.reviews,
        Revenue: formatMoney(a.revenue),
        Rating: a.rating,
        ADR: formatMoney(a.adr),
        Occupancy: `${Math.trunc(a.occupancy * 100)}%`,
        ListingAge: diffDays >= 365 ? 'Year +' : Math.ceil(diffDays / 30) + ' Month(s)',
        Valuation: bedroomMarket ? bedroomMarket.valuation ? formatMoney(bedroomMarket.valuation) : 'N/A' : 'N/A'
      })
    })

    columns = Object.keys(exportData[0])

    blob = await strFetchBlob(`/download`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        columns: columns,
        data: exportData,
        listingIds: exportData.map(c => c.ListingID)
      }),
    })

    var a = document.createElement('a')
    document.body.appendChild(a)

    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = 'STRInsightsExport.csv'
    a.click()
    a.remove()
  }

  var dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  const percentageFormatter = (value: any) => {
    return Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
    }).format(value)
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      {/* <Sidebar visible={propertyProfileVisible} position="left" onHide={() => setPropertyProfileVisible(false)} className='p-sidebar-lg'> */}
      <SwipeableDrawer
        anchor={'left'}
        open={propertyProfileVisible}
        onClose={() => setPropertyProfileVisible(false)}
        onOpen={() => setPropertyProfileVisible(true)}
      >
        <Box sx={{ width: ['xs', 'sm'].includes(breakpoint!) ? '80vw' : '50vw', padding: '12px' }} role='presentation'>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Revenue Details" {...a11yProps(0)} />
            {propertyForProfile && propertyForProfile.listing_id &&
              <Tab label="Property Info" {...a11yProps(1)} />

            }
          </Tabs>
          <div id={`simple-tabpanel-${0}`} style={{ marginBottom: '20px' }}>
            {
              tabValue === 0 &&
              <RevenueDetails
                propertyForProfile={propertyForProfile}
                revenueForProfile={revenueForProfile}
                percentageFormatter={percentageFormatter}
                formatMoney={formatMoney}
              />
            }

          </div>
          {propertyForProfile && propertyForProfile.listing_id &&
            <div id={`simple-tabpanel-${1}`} style={{ marginBottom: '20px' }}>
              {tabValue === 1 &&
                <PropertyInfo
                  propertyForProfile={propertyForProfile}
                />
              }

            </div>
          }

        </Box>




      </SwipeableDrawer>

      {/* </Sidebar> */}
      <Grid container spacing={2}>
        <SwipeableDrawer
          anchor={'right'}
          open={open}
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
        >
          <Box sx={{ width: 300 }} role='presentation'>
            <div style={{ textAlign: 'center' }}>
              <h2>{infoType.toUpperCase()}</h2>
            </div>
            <Divider></Divider>

            <div style={{ padding: 20 }}>
              {getInfoDescription(infoType.trim().toLowerCase())}
            </div>
          </Box>
        </SwipeableDrawer>
        <Modal
          disableAutoFocus={true}
          open={showRealtorModal}
          onClose={() => setShowRealtorModal(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Card
            sx={realtorModalStyle}
            style={{
              width: ['xs', 'sm'].includes(breakpoint!) ? '90%' : 'auto',
            }}
          >
            <Stack direction='row' alignItems='center'>
              {!realtorInfo.realtorPhoto && (
                <Avatar
                  style={{ height: 45, width: 45, marginLeft: '5px' }}
                ></Avatar>
              )}
              {realtorInfo.realtorPhoto && (
                <Avatar
                  src={realtorImageBaseUrl + realtorInfo.realtorPhoto}
                  style={{ height: 45, width: 45, marginLeft: '5px' }}
                ></Avatar>
              )}

              <Stack
                direction='column'
                alignItems='flex-start'
                marginLeft={'12px'}
              >
                <div>
                  <b>{realtorInfo.realtorInfo?.name}</b>
                </div>
                <div>{realtorInfo.realtorInfo?.business}</div>
              </Stack>
            </Stack>
            <Stack
              direction='row'
              alignItems='flex-start'
              marginLeft={'12px'}
              marginRight={'12px'}
            >
              <Stack
                direction='column'
                alignItems='flex-start'
                marginTop={'12px'}
              >
                <div>{realtorInfo.realtorInfo?.description}</div>
              </Stack>
            </Stack>
          </Card>
        </Modal>
        <Modal
          disableAutoFocus={true}
          open={showContactInfoForLender}
          onClose={() => setShowContactInfoForLender(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Card
            sx={realtorContactStyle}
            style={{
              width: ['xs', 'sm'].includes(breakpoint!) ? '90%' : 'auto',
            }}
          >
            <Stack direction='row' alignItems='center'>
              <Avatar
                src={chosenLender === 'Brian Bockholdt' ? bockholdt : mortgageShop}
                style={{ height: 45, width: 45, marginLeft: '5px' }}
              ></Avatar>
              <Stack
                direction='column'
                alignItems='flex-start'
                marginLeft={'12px'}
              >
                <div>
                  <b>{chosenLender}</b>
                </div>
              </Stack>
            </Stack>
            <Stack
              direction='row'
              alignItems='flex-start'
              marginLeft={'12px'}
              marginRight={'12px'}
            >
              <Stack
                direction='column'
                alignItems='flex-start'
                marginTop={'20px'}
              >
                {!shouldShowLenderContactInfo && isDemo && (
                  <>
                    <p>
                      To see Contact information please provide the following.
                    </p>

                    <TextField
                      margin='normal'
                      required
                      size='small'
                      sx={{ width: '100%' }}
                      id='username'
                      label='Email Address'
                      name='username'
                      autoComplete='email'
                      disabled={realtorDataLoading}
                      onChange={(event) => {
                        let isValid = event.target.value.match(
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )
                        if (isValid) {
                          setEmailDemoMode(event.target.value)
                        }
                      }}
                    />

                    <TextField
                      margin='normal'
                      required
                      size='small'
                      sx={{ width: '100%', marginBottom: '16px' }}
                      id='username'
                      label='Name'
                      name='username'
                      onChange={(event) => {
                        let isValid = event.target.value.length > 2
                        if (isValid) {
                          setNameDemoMode(event.target.value)
                        }
                      }}
                    />
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      disabled={!(emailDemo && name)}
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => {
                        showContactInfoForLenderAndTrack()
                      }}
                    >
                      Reveal
                    </Button>
                  </>
                )}

                {(!isDemo || (isDemo && shouldShowLenderContactInfo)) && (
                  <>
                    <div style={{ display: 'inline-flex' }}>
                      <div>Phone: </div>

                      <a href={chosenLender === 'Brian Bockholdt' ? 'tel:630.210.4205' : 'tel:800.963.0954'}>
                        <div style={{ marginLeft: '12px' }}>
                          {chosenLender === 'Brian Bockholdt' ? '630-210-4205' : '800-963-0954'}
                        </div>
                      </a>


                    </div>
                    <div style={{ display: 'inline-flex', marginTop: 12 }}>
                      <div>Email: </div>

                      <a href={`mailto:${chosenLender === 'Brian Bockholdt' ? 'brian.m.bockholdt@huntington.com' : 'brenna@mortgageshop.co'}`}>
                        <div
                          style={{
                            marginLeft: '12px',
                            lineBreak: 'anywhere',
                          }}
                        >
                          {chosenLender === 'Brian Bockholdt' ? 'brian.m.bockholdt@huntington.com' : 'brenna@mortgageshop.co'}
                        </div>
                      </a>

                    </div>
                  </>
                )}
              </Stack>
            </Stack>
          </Card>
        </Modal>
        <Modal
          disableAutoFocus={true}
          open={showContactInfo}
          onClose={() => setShowContactInfo(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Card
            sx={realtorContactStyle}
            style={{
              width: ['xs', 'sm'].includes(breakpoint!) ? '90%' : 'auto',
            }}
          >
            <Stack direction='row' alignItems='center'>
              {!realtorInfo.realtorPhoto && (
                <Avatar
                  style={{ height: 45, width: 45, marginLeft: '5px' }}
                ></Avatar>
              )}
              {realtorInfo.realtorPhoto && (
                <Avatar
                  src={realtorImageBaseUrl + realtorInfo.realtorPhoto}
                  style={{ height: 45, width: 45, marginLeft: '5px' }}
                ></Avatar>
              )}

              <Stack
                direction='column'
                alignItems='flex-start'
                marginLeft={'12px'}
              >
                <div>
                  <b>{realtorInfo.realtorInfo?.name}</b>
                </div>
                <div>{realtorInfo.realtorInfo?.business}</div>
              </Stack>
            </Stack>
            <Stack
              direction='row'
              alignItems='flex-start'
              marginLeft={'12px'}
              marginRight={'12px'}
            >
              <Stack
                direction='column'
                alignItems='flex-start'
                marginTop={'20px'}
              >
                {!shouldShowContactInfo && isDemo && (
                  <>
                    <p>
                      To see Contact information please provide the following.
                    </p>

                    <TextField
                      margin='normal'
                      required
                      size='small'
                      sx={{ width: '100%' }}
                      id='username'
                      label='Email Address'
                      name='username'
                      autoComplete='email'
                      disabled={realtorDataLoading}
                      onChange={(event) => {
                        let isValid = event.target.value.match(
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )
                        if (isValid) {
                          setEmailDemoMode(event.target.value)
                        }
                      }}
                    />

                    <TextField
                      margin='normal'
                      required
                      size='small'
                      sx={{ width: '100%', marginBottom: '16px' }}
                      id='username'
                      label='Name'
                      name='username'
                      onChange={(event) => {
                        let isValid = event.target.value.length > 2
                        if (isValid) {
                          setNameDemoMode(event.target.value)
                        }
                      }}
                    />
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      disabled={!(emailDemo && name)}
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => {
                        showContactInfoForRealtorAndTrack()
                      }}
                    >
                      Reveal
                    </Button>
                  </>
                )}

                {(!isDemo || (isDemo && shouldShowContactInfo)) && (
                  <>
                    {realtorInfo.realtorInfo?.phone && (
                      <div style={{ display: 'inline-flex' }}>
                        <div>Phone: </div>
                        {realtorInfo.realtorInfo.phone
                          .split(';')
                          .map((phone: {} | null | undefined) => {
                            return (
                              <a href={'tel:' + phone}>
                                <div style={{ marginLeft: '12px' }}>
                                  {phone}
                                </div>
                              </a>
                            )
                          })}
                      </div>
                    )}
                    {realtorInfo.realtorInfo?.email && (
                      <div style={{ display: 'inline-flex', marginTop: 12 }}>
                        <div>Email: </div>
                        {realtorInfo.realtorInfo.email
                          .split(';')
                          .map((email: {} | null | undefined) => {
                            return (
                              <a href={'mailto:' + email}>
                                <div
                                  style={{
                                    marginLeft: '12px',
                                    lineBreak: 'anywhere',
                                  }}
                                >
                                  {email}
                                </div>
                              </a>
                            )
                          })}
                      </div>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
          </Card>
        </Modal>
        <Modal
          disableAutoFocus={true}
          open={showRealtorModalForRegulation}
          onClose={() => setShowRealtorModalForRegulation(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Card
            sx={realtorModalStyle}
            style={{
              width: ['xs', 'sm'].includes(breakpoint!) ? '90%' : 'auto',
            }}
          >
            <Stack direction='row' alignItems='center'>
              {!realtorInfo.realtorPhoto && (
                <Avatar
                  style={{ height: 45, width: 45, marginLeft: '5px' }}
                ></Avatar>
              )}
              {realtorInfo.realtorPhoto && (
                <Avatar
                  src={realtorImageBaseUrl + realtorInfo.realtorPhoto}
                  style={{ height: 45, width: 45, marginLeft: '5px' }}
                ></Avatar>
              )}

              <Stack
                direction='column'
                alignItems='flex-start'
                marginLeft={'12px'}
              >
                <div>
                  <b>{realtorInfo.realtorInfo?.name}</b>
                </div>
                <div>{realtorInfo.realtorInfo?.business}</div>
              </Stack>
            </Stack>
            <Stack
              direction='row'
              alignItems='flex-start'
              marginLeft={'12px'}
              marginRight={'12px'}
            >
              <Stack
                direction='column'
                alignItems='flex-start'
                marginTop={'12px'}
              >
                <div>{realtorInfo.realtorInfo?.regulation_zoning_details}</div>
              </Stack>
            </Stack>
          </Card>
        </Modal>
        {!apiError &&
          <>
            <Grid item md={5.5} xs={12}>
              <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'start', gap: 20, marginBottom: 12 }}>
                <div style={{ fontSize: 24, float: 'right' }}>
                  <Button variant='outlined' onClick={() => {
                    navigate(`/market-history?city=${props.cityName}&state=${props.state}`)
                  }}>
                    <div>
                      View Market History
                    </div>

                  </Button>
                </div>
                {
                  zipCodeList && zipCodeList.length > 0 &&
                  <div style={{ fontSize: 24, float: 'right' }}>
                    <Button variant='outlined' onClick={() => {
                      setZipCode('')
                      setMarket(overallMarket);
                    }}>
                      Clear Zip Code
                    </Button>
                  </div>
                }

              </div>
              <div style={{ fontSize: 24 }}>
                {props.cityName + ', ' + props.state + (zipCode ? ' - ' + zipCode : '')}
              </div>

              <div>
                {smallDataCardsLoading &&
                  <Skeleton variant="rectangular" width={'100%'} height={175} />
                }
                {!smallDataCardsLoading &&
                  shouldShowImage && (
                    <div>
                      <img
                        src={cityImageBaseUrl + market?.marketDetails?.image}
                        alt='market profile main'
                        style={{ width: '100%', height: '175px' }}
                        onError={(e) => hideImg()}
                      />
                    </div>
                  )
                }

              </div>
              <div>
                {smallDataCardsLoading &&
                  <Skeleton variant="rectangular" style={{ marginTop: 10 }} width={'100%'} height={100} />
                }
                {!smallDataCardsLoading && market?.marketDetails?.description && (
                  <div
                    style={{
                      height: '50%',
                      paddingTop: '10px',
                      paddingBottom: '16px',
                      paddingLeft: '16px',
                    }}
                  >
                    <div style={cityDescriptionText}>
                      {market?.marketDetails?.description}
                    </div>
                  </div>
                )
                }

              </div>

              <Grid mt={0.5} container spacing={1}>
                <Grid item md={4} xs={12}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" width={210} height={100} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardSmall}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Active Listings</div>
                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('active listing count')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <div style={cardValueLight}>
                        {!smallDataCardsLoading && (
                          <div>
                            {market?.marketDetails && market?.marketDetails.activeListingCount
                              ? market?.marketDetails.activeListingCount
                              : 'No Data Available'}
                          </div>
                        )}
                      </div>
                    </Card>
                  }
                </Grid>
                <Grid item md={4} xs={12}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" width={210} height={100} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardSmall}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Avg. Valuation</div>
                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('valuation')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <div style={cardValueLight}>
                        {!smallDataCardsLoading && (
                          <div
                            style={isDemo && !isDemoCityAndState() ? blur : undefined}
                          >
                            {market?.marketDetails && market?.marketDetails.valuation
                              ? formatMoney(market?.marketDetails.valuation)
                              : 'No Data Available'}
                          </div>
                        )}
                      </div>
                    </Card>
                  }
                </Grid>
                <Grid item md={4} xs={12}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" width={210} height={100} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardSmall}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Avg. Revenue</div>
                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('revenue')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <div style={cardValueLight}>
                        {smallDataCardsLoading && (
                          <CircularProgress style={{ marginLeft: '40%' }} size={30} />
                        )}
                        {!smallDataCardsLoading && (
                          <div
                            style={isDemo && !isDemoCityAndState() ? blur : undefined}
                          >
                            {market?.marketDetails &&
                              getByPercentileNumber(false)
                              ? formatMoney(
                                getByPercentileNumber(false) as number
                              )
                              : 'No Data Available'}
                          </div>
                        )}
                      </div>
                    </Card>
                  }

                </Grid>
              </Grid>

              <Grid mt={0.5} container spacing={1}>
                <Grid item md={4} xs={12}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" width={210} height={100} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardSmall}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Avg. ROI</div>
                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('roi')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <div style={cardValueLight}>
                        {smallDataCardsLoading && (
                          <CircularProgress style={{ marginLeft: '40%' }} size={30} />
                        )}
                        {!smallDataCardsLoading && (
                          <div
                            style={isDemo && !isDemoCityAndState() ? blur : undefined}
                          >
                            {calculateAvgROI()}
                          </div>
                        )}
                      </div>
                    </Card>
                  }

                </Grid>
                <Grid item md={4} xs={12}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" width={210} height={100} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardSmall}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Avg. Occupancy</div>
                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('occupancy')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <div style={cardValueLight}>
                        {smallDataCardsLoading && (
                          <CircularProgress style={{ marginLeft: '40%' }} size={30} />
                        )}
                        {!smallDataCardsLoading && (
                          <div
                            style={isDemo && !isDemoCityAndState() ? blur : undefined}
                          >
                            {getAverageOccupancy()}
                          </div>
                        )}
                      </div>
                    </Card>
                  }

                </Grid>
                <Grid item md={4} xs={12}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" width={210} height={100} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardSmall}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Avg. Daily Rate</div>
                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('adr')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <div style={cardValueLight}>
                        {smallDataCardsLoading && (
                          <CircularProgress style={{ marginLeft: '40%' }} size={30} />
                        )}
                        {!smallDataCardsLoading && <div>{getADR()}</div>}
                      </div>
                    </Card>
                  }

                </Grid>
              </Grid>

              <Grid mt={0.5} container spacing={1}>
                <Grid item md={12} xs={12} style={{ display: !['xs', 'sm'].includes(breakpoint!) ? 'inline-flex' : '', justifyContent: 'space-between', gap: 20 }}>
                  {realtorDataLoading &&
                    <Skeleton variant="rectangular" style={dataCardMediumExtended} width={250} height={300} />
                  }
                  {!realtorDataLoading &&
                    <Card style={dataCardMediumExtended}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Preferred Realtor</div>

                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('realtor')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>
                      {!realtorInfo.realtorInfo && !realtorDataLoading && (
                        <div style={{ padding: 20 }}>
                          <div color='primary'>
                            Interested in representing this market?
                          </div>
                          <Button
                            variant='contained'
                            size='small'
                            onClick={realtorLearnMore}
                            startIcon={<OpenInNewIcon />}
                            style={{ marginTop: '20px' }}
                          >
                            Learn More
                          </Button>
                        </div>
                      )}

                      {realtorDataLoading && (
                        <CircularProgress
                          style={{ marginTop: '20px', marginLeft: '40%' }}
                          size={30}
                        />
                      )}
                      <Stack direction='row' alignItems='center'>
                        {realtorInfo.realtorPhoto && (
                          <Avatar
                            src={realtorImageBaseUrl + realtorInfo.realtorPhoto}
                            style={{ height: 45, width: 45, marginLeft: '5px' }}
                          ></Avatar>
                        )}

                        <Stack
                          direction='column'
                          alignItems='flex-start'
                          marginLeft={'12px'}
                        >
                          <div>
                            <b>{realtorInfo.realtorInfo?.name}</b>
                          </div>
                          <div>{realtorInfo.realtorInfo?.business}</div>
                        </Stack>
                      </Stack>

                      <Stack
                        direction='row'
                        alignItems='flex-start'
                        marginLeft={'12px'}
                        marginRight={'12px'}
                      >
                        <Stack
                          direction='column'
                          alignItems='flex-start'
                          marginTop={'12px'}
                        >
                          {realtorInfo.realtorInfo?.desired_shown_metric_type ===
                            'Properties Sold' && (
                              <div
                                style={{
                                  display: 'inline-flex',
                                  marginBottom: '12px',
                                }}
                              >
                                <strong>Properties Sold: </strong>
                                <div style={{ marginLeft: '10px' }}>
                                  {realtorInfo.realtorInfo?.properties_sold}
                                </div>
                              </div>
                            )}
                          {realtorInfo.realtorInfo?.desired_shown_metric_type ===
                            'Years in Industry' && (
                              <div
                                style={{
                                  display: 'inline-flex',
                                  marginBottom: '12px',
                                }}
                              >
                                <strong>Years in Industry: </strong>

                                <div style={{ marginLeft: '10px' }}>
                                  {realtorInfo.realtorInfo?.industry_years}
                                </div>
                              </div>
                            )}
                          <div>{bio}</div>
                          {shouldShowReadMore && realtorInfo.realtorInfo && (
                            <div
                              onClick={() => setShowRealtorModal(true)}
                              style={{ color: '#0598EB', cursor: 'pointer' }}
                            >
                              Read More...
                            </div>
                          )}
                          {realtorInfo.realtorInfo?.regulation_zoning_details && (
                            <div
                              onClick={() => setShowRealtorModalForRegulation(true)}
                              style={{
                                color: '#0598EB',
                                marginTop: '12px',
                                cursor: 'pointer',
                              }}
                            >
                              Additional Regulation and Zoning Info
                            </div>
                          )}
                          {realtorInfo.realtorInfo && (
                            <div
                              onClick={() => {
                                setShowContactInfo(true)
                                trackContactInfoClick(!isDemo)
                              }}
                              style={{
                                color: '#0598EB',
                                marginTop: '12px',
                                cursor: 'pointer',
                              }}
                            >
                              Contact Info
                            </div>
                          )}
                          {/* {!isSent && realtorInfo.realtorInfo && (
                          <div style={{ height: '100%' }}>
                            <div style={{ color: 'black', marginTop: '12px' }}>
                              Contact Realtor
                            </div>

                            <TextField
                              margin='normal'
                              required
                              size='small'
                              sx={{ width: '100%', marginTop: '16px' }}
                              // fullWidth
                              id='username'
                              label='Email Address'
                              name='username'
                              autoComplete='email'
                              disabled={realtorDataLoading}
                              onChange={(event) => {
                                let isValid = event.target.value.match(
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                )
                                if (isValid) {
                                  setEmail(event.target.value)
                                }
                              }}
                            />

                            <TextField
                              id='outlined-multiline-static'
                              label='Message'
                              multiline
                              rows={4}
                              size='small'
                              disabled={realtorDataLoading}
                              sx={{ width: '100%', marginTop: '16px' }}
                              onChange={(event) => {
                                setMessage(event.target.value)
                              }}
                            />
                            <Button
                              type='submit'
                              fullWidth
                              variant='contained'
                              disabled={!(email && message)}
                              sx={{ mt: 3, mb: 2 }}
                              onClick={() => {
                                handleSubmit()
                                setIsSent(true)
                                trackEmailDelivery()
                              }}
                            >
                              Send
                            </Button>
                          </div>
                        )}

                        {isSent && (
                          <div
                            style={{
                              height: '100px',
                              width: '100%',
                              display: 'grid',
                              justifyContent: 'center',
                              marginTop: '50px',
                            }}
                          >
                            Message Sent!
                          </div>
                        )} */}
                        </Stack>
                      </Stack>
                    </Card>
                  }

                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" style={dataCardMediumExtended} width={250} height={300} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardMediumExtended}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Regulation Amount</div>
                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('regulation')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <div style={{ ...cardValueLight, textAlign: 'center' }}>
                        <Grid py={2}>
                          <regulationInfo.icon />
                          <div
                            style={{
                              textAlign: 'center',
                              fontSize: '18px',
                              color: regulationInfo.color,
                              marginTop: 16,
                              textTransform: 'uppercase',
                            }}
                          >
                            {regulationInfo.label}
                          </div>
                          {market?.regulationSource && market?.regulationSource.startsWith('http') &&
                            <>
                              <hr></hr>
                              <div onClick={() => window.open(market?.regulationSource)} style={{ color: regulationInfo.color, cursor: 'pointer' }}>See Source</div>
                            </>
                          }
                          {market?.regulationSource && !market?.regulationSource.startsWith('http') &&
                            <>
                              <hr></hr>
                              <div style={{ fontSize: '16px' }}>{market?.regulationSource}</div>
                            </>
                          }
                        </Grid>
                      </div>
                    </Card>
                  }

                </Grid>

                <Grid item md={6} xs={12} style={{ display: !['xs', 'sm'].includes(breakpoint!) ? 'inline-flex' : '', justifyContent: 'space-between', gap: 20 }}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" style={dataCardMediumExtended} width={250} height={300} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardMediumExtended}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Preferred Lender</div>

                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('lender')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <Stack direction='row' alignItems='center'>
                        <Avatar
                          src={bockholdt}
                          style={{ height: 45, width: 45, marginLeft: '5px' }}
                        ></Avatar>

                        <Stack
                          direction='column'
                          alignItems='flex-start'
                          marginLeft={'12px'}
                        >
                          <div>
                            <b>Brian Bockholdt</b>
                          </div>
                          <div>The Bockholdt Lending Team</div>
                        </Stack>
                      </Stack>

                      <Stack
                        direction='row'
                        alignItems='flex-start'
                        marginLeft={'12px'}
                        marginRight={'12px'}
                      >
                        <Stack
                          direction='column'
                          alignItems='flex-start'
                          marginTop={'12px'}
                        >
                          <div
                            style={{
                              display: 'inline-flex',
                              marginBottom: '12px',
                            }}
                          >
                            <div>
                              Top 1% originator nationally
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setChosenLender('Brian Bockholdt')
                              setShowContactInfoForLender(true)
                              trackLenderContactInfoClick(!isDemo)
                            }}
                            style={{
                              color: '#0598EB',
                              marginTop: '12px',
                              cursor: 'pointer',
                            }}
                          >
                            Contact Info
                          </div>
                          <Button
                            variant='contained'
                            size='small'
                            onClick={() => lenderLearnMore('Brian Bockholdt')}
                            startIcon={<OpenInNewIcon />}
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                          >
                            Learn More
                          </Button>
                        </Stack>
                      </Stack>
                    </Card>
                  }

                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" style={dataCardMediumExtended} width={250} height={300} />
                  }
                </Grid>
                <Grid item md={6} xs={12} style={{ display: !['xs', 'sm'].includes(breakpoint!) ? 'inline-flex' : '', justifyContent: 'space-between', gap: 20 }}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" style={dataCardMediumExtended} width={250} height={300} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardMediumExtended}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Preferred Lender</div>

                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('lender')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <Stack direction='row' alignItems='center'>
                        <Avatar
                          src={mortgageShop}
                          style={{ height: 45, width: 45, marginLeft: '5px' }}
                        ></Avatar>

                        <Stack
                          direction='column'
                          alignItems='flex-start'
                          marginLeft={'12px'}
                        >
                          <div>
                            <b>Brenna Carles</b>
                          </div>
                          <div>The Mortgage Shop</div>
                        </Stack>
                      </Stack>

                      <Stack
                        direction='row'
                        alignItems='flex-start'
                        marginLeft={'12px'}
                        marginRight={'12px'}
                      >
                        <Stack
                          direction='column'
                          alignItems='flex-start'
                          marginTop={'12px'}
                        >
                          <div
                            style={{
                              display: 'inline-flex',
                              marginBottom: '12px',
                            }}
                          >
                            <div>
                              The Mortgage Shop is the top brokerage offering white glove service for short-term rentals, long-term rentals, and vacation home loans, with an added focus on helping clients build generational wealth through The Mortgage Shop Academy.
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setChosenLender('Brenna Carles')
                              setShowContactInfoForLender(true)
                              trackLenderContactInfoClick(!isDemo)
                            }}
                            style={{
                              color: '#0598EB',
                              marginTop: '12px',
                              cursor: 'pointer',
                            }}
                          >
                            Contact Info
                          </div>
                          <Button
                            variant='contained'
                            size='small'
                            onClick={() => lenderLearnMore('Brenna Carles')}
                            startIcon={<OpenInNewIcon />}
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                          >
                            Learn More
                          </Button>
                        </Stack>
                      </Stack>
                    </Card>
                  }

                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" style={dataCardMediumExtended} width={250} height={300} />
                  }
                </Grid>
                <Grid item md={12} xs={12} style={{ display: !['xs', 'sm'].includes(breakpoint!) ? 'inline-flex' : '', justifyContent: 'space-between', gap: 20 }}>
                  {smallDataCardsLoading &&
                    <Skeleton variant="rectangular" style={dataCardMediumExtended} width={250} height={300} />
                  }
                  {!smallDataCardsLoading &&
                    <Card style={dataCardMediumExtended}>
                      <div style={cardTitleContainer}>
                        <div style={cardTitle}>Preferred Insurer</div>

                        <div style={{ height: '100%' }}>
                          <IconButton
                            onClick={() => {
                              setInfoType('Insurer')
                              setIsOpen(true)
                            }}
                          >
                            <InfoIcon fontSize={'small'} />
                          </IconButton>
                        </div>
                      </div>

                      <Stack direction='row' alignItems='center'>


                        <Stack
                          direction='column'
                          alignItems='flex-start'
                          marginLeft={'12px'}
                        >
                          <div>
                            <b>STR Preferred Insurer</b>
                          </div>
                          <div>Get an instant quote with Steadily</div>
                        </Stack>
                      </Stack>

                      <Stack
                        direction='row'
                        alignItems='flex-start'
                        marginLeft={'12px'}
                        marginRight={'12px'}
                      >
                        <Stack
                          direction='column'
                          alignItems='flex-start'
                          marginTop={'12px'}
                        >
                          <div
                            style={{
                              display: 'inline-flex',
                              marginBottom: '12px',
                            }}
                          >
                            <div style={{ width: 250 }}>
                              Coverage for fires, windstorms, water leaks, vandalism, and more for your rental.
                            </div>
                          </div>

                          <div className="steadily-quote-button"
                            data-product="landlord-insurance"
                            data-show-modal-on-load="0"
                            data-button-text="Get a Quote"
                            data-button-class="btn-primary"
                            onClick={() =>
                              track('Insurer Clicked', {
                                user: {
                                  name: localStorage.getItem('userName'),
                                  email: localStorage.getItem('email'),
                                },
                              })
                            }
                          ></div>
                        </Stack>
                      </Stack>
                    </Card>
                  }

                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6.5} xs={12}>
              <Stack
                spacing={1}
                direction='column'
                zIndex={1}
                position={'absolute'}
                sx={{ padding: '12px' }}
              >
                <Autocomplete
                  disabled={isDemo}
                  multiple
                  id='bedroom-count'
                  value={bedroomCountForProperties}
                  options={bedroomCounts}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  sx={{ textTransform: 'capitalize', minWidth: 175 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Bedroom Counts'
                      sx={{ backgroundColor: theme.palette.mode === 'dark' ? 'gray' : 'white' }}
                    />
                  )}
                  onChange={(event: any, value: string[]) => {
                    setBedroomCountForProperties(value)
                    dispatch.setCityProfileFilters('bedroomCounts', value)
                  }}
                  size='small'
                />
                {
                  zipCodeList && zipCodeList.length > 0 &&
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Zip Code</InputLabel>

                    <Select
                      sx={{ backgroundColor: theme.palette.mode === 'dark' ? 'gray' : 'white' }}
                      name='zipCodes'
                      labelId="demo-simple-select-label"
                      id="zipCodes"
                      value={zipCode}
                      label="Zip Codes"
                      onChange={(event: SelectChangeEvent) => {
                        setZipCode(event.target.value as string)
                      }}
                    >
                      {zipCodeList.map(val => {
                        return <MenuItem value={val}>{val}</MenuItem>
                      })}

                    </Select>
                  </FormControl>
                }


                <Button
                  variant='contained'
                  size='small'
                  onClick={download}
                  startIcon={<FileDownloadRoundedIcon />}
                  disabled={
                    (propertiesToExport.length === 0 ||
                      isDemo)
                  }
                >
                  Export Properties
                  {/* <Chip label="NEW" style={{background:
                'linear-gradient(306.01deg, #EC9F05 -23.49%, #FF4E00 110.92%)', color: 'white', marginLeft: 10}} />   */}
                </Button>
              </Stack>
              {locationsLoading &&
                <Skeleton variant="rectangular" style={dataCardMediumExtended} width={'100%'} height={'60vh'} />
              }
              {!locationsLoading &&
                <div
                  style={{
                    width: '100%',
                    height: '60vh',
                  }}
                >
                  <GoogleMapReact
                    key={locations.length + 'map'}
                    bootstrapURLKeys={{
                      key: 'AIzaSyDPWL6h3SpdOLOJxCm4iRK9I75BL4NIT_Y',
                      language: 'en',
                      region: 'US',
                    }}
                    center={center}
                    defaultZoom={11}
                    options={{
                      zoomControlOptions: {
                        position: 7,
                      },
                      styles: theme.palette.mode === 'dark' ? [{
                        "featureType": "all",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                          "saturation": 36
                        }, {
                          "color": "#000000"
                        }, {
                          "lightness": 40
                        }]
                      }, {
                        "featureType": "all",
                        "elementType": "labels.text.stroke",
                        "stylers": [{
                          "visibility": "on"
                        }, {
                          "color": "#000000"
                        }, {
                          "lightness": 16
                        }]
                      }, {
                        "featureType": "all",
                        "elementType": "labels.icon",
                        "stylers": [{
                          "visibility": "off"
                        }]
                      }, {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 20
                        }]
                      }, {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 17
                        }, {
                          "weight": 1.2
                        }]
                      }, {
                        "featureType": "administrative",
                        "elementType": "labels",
                        "stylers": [{
                          "visibility": "off"
                        }]
                      }, {
                        "featureType": "administrative.country",
                        "elementType": "all",
                        "stylers": [{
                          "visibility": "simplified"
                        }]
                      }, {
                        "featureType": "administrative.country",
                        "elementType": "geometry",
                        "stylers": [{
                          "visibility": "simplified"
                        }]
                      }, {
                        "featureType": "administrative.country",
                        "elementType": "labels.text",
                        "stylers": [{
                          "visibility": "simplified"
                        }]
                      }, {
                        "featureType": "administrative.province",
                        "elementType": "all",
                        "stylers": [{
                          "visibility": "off"
                        }]
                      }, {
                        "featureType": "administrative.locality",
                        "elementType": "all",
                        "stylers": [{
                          "visibility": "simplified"
                        }, {
                          "saturation": "-100"
                        }, {
                          "lightness": "30"
                        }]
                      }, {
                        "featureType": "administrative.neighborhood",
                        "elementType": "all",
                        "stylers": [{
                          "visibility": "off"
                        }]
                      }, {
                        "featureType": "administrative.land_parcel",
                        "elementType": "all",
                        "stylers": [{
                          "visibility": "off"
                        }]
                      }, {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [{
                          "visibility": "simplified"
                        }, {
                          "gamma": "0.00"
                        }, {
                          "lightness": "74"
                        }]
                      }, {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 20
                        }]
                      }, {
                        "featureType": "landscape.man_made",
                        "elementType": "all",
                        "stylers": [{
                          "lightness": "3"
                        }]
                      }, {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [{
                          "visibility": "off"
                        }]
                      }, {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 21
                        }]
                      }, {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [{
                          "visibility": "simplified"
                        }]
                      }, {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 17
                        }]
                      }, {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 29
                        }, {
                          "weight": 0.2
                        }]
                      }, {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 18
                        }]
                      }, {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 16
                        }]
                      }, {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 19
                        }]
                      }, {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [{
                          "color": "#000000"
                        }, {
                          "lightness": 17
                        }]
                      }] : []
                    }}
                    onGoogleApiLoaded={({ map, maps }) => {
                      mapRef.current = map;
                      // Add back for boundaries
                      handleApiLoaded(map, maps)
                    }}
                    onChange={({ zoom, bounds }) => {
                      setZoom(zoom);
                      setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                      ]);
                    }}
                  >
                    {clustersToUse &&
                      clustersToUse.map((cluster) => {
                        const [longitude, latitude] = cluster.geometry.coordinates;
                        const {
                          cluster: isCluster,
                          point_count: pointCount
                        } = cluster.properties;
                        if (isCluster) {
                          let size = (pointCount * 20) / data.length;
                          if (pointCount && pointCount !== 0) {
                            return (
                              <ClusterMarker
                                lat={latitude}
                                lng={longitude}
                                key={`cluster-${cluster.id}`}
                                className="cluster-marker"
                              >
                                <div
                                  className="cluster-marker"
                                  style={{ width: size + "px", height: size + "px" }}
                                  onClick={() => {
                                    const expansionZoom = Math.min(
                                      superclusterToUse.getClusterExpansionZoom(cluster.id),
                                      20
                                    );
                                    mapRef.current.setZoom(expansionZoom);
                                    mapRef.current.panTo({ lat: latitude, lng: longitude });


                                  }}
                                >
                                  {pointCount}
                                </div>
                              </ClusterMarker>
                            );
                          }

                        }
                        else {
                          return (
                            <Marker
                            setTabValue = {setTabValue}
                              setPropertyForProfile={setPropertyForProfile}
                              setPropertyProfileVisible={setPropertyProfileVisible}
                              fetchRevenueByListingId={fetchRevenueByListingId}
                              fetchExtraPropertyInfoByListingId={fetchExtraPropertyInfoByListingId}
                              data={cluster.properties}
                              lat={cluster.properties.latitude}
                              lng={cluster.properties.longitude}
                              text={''}
                              airbnbLink={
                                cluster.properties.airbnb_id
                                  ? 'https://airbnb.com/rooms/' + cluster.properties.airbnb_id
                                  : ''
                              }
                              airbnbLinkText={
                                cluster.properties.airbnb_id
                                  ? 'AirBnb'
                                  : ''
                              }
                              vrboLink={
                                cluster.properties.vrbo_id
                                  ? 'https://vrbo.com/' + cluster.properties.vrbo_id
                                  : ''
                              }
                              vrboLinkText={
                                cluster.properties.vrbo_id
                                  ? 'Vrbo'
                                  : ''
                              }
                              tooltip={`
                    ${cluster.properties.bedrooms} Bedrooms 
                    ${cluster.properties.bathrooms} Bathrooms
                 `}
                              revenue={`
                     ${cluster.properties.revenue === 'no data'
                                  ? 'no data'
                                  : dollarFormatter.format(cluster.properties.revenue)
                                }
                    `}
                            />
                          );
                        }
                      })}
                  </GoogleMapReact>
                </div>
              }


              <Grid item md={12} xs={12} height={'25%'} position={'relative'}>
                {smallDataCardsLoading &&
                  <Skeleton variant="rectangular" style={{ marginTop: 20 }} width={'100%'} height={400} />
                }
                {!smallDataCardsLoading &&
                  <CityProfileTable
                    searchCity={props.cityName}
                    searchState={props.state}
                    bedroomDetails={market?.bedroomDetails}
                    setOpen={setIsOpen}
                    setInfoType={setInfoType}
                    revenuePercentile={props.revenuePercentile}
                    bedroomCounts={bedroomCountForProperties}
                  ></CityProfileTable>
                }

              </Grid>
            </Grid>
          </>


        }

        {apiError &&
          <div>
            No Data For This Market. Please check back later.
          </div>
        }

      </Grid>
    </>
  )
}

const cityDescriptionText = {
  fontSize: '15px',
  lineHeight: '20px',
}
const cityDescriptionTitle = {
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '25px',
  paddingLeft: '16px',
  paddingTop: '10px',
  paddingRight: '16px',
}

const dataCardSmall = {
  height: '100%',
  padding: '6px 10px',
}

const cardTitleContainer = {
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const cardTitle = {
  fontSize: 12,
  'text-transform': 'uppercase',
}

const cardValueLight = {
  fontSize: 24,
  fontWeight: 500,
  paddingBottom: '6px',
}

const blur = {
  width: '100%',
  heigth: '100%',
  background: 'rgba(255, 255, 255, 0.2)', // Make sure this color has an opacity of less than 1
  filter: 'blur(8px)', // This be the blur
}
const dataCardMediumExtended = {
  marginBottom: '12px',
  padding: '6px 10px',
  width: '100%'
}
const dataCardMedium = {
  backgroundColor: 'white',
  marginBottom: '12px',
  padding: '6px 10px',
}
const realtorModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
  height: 'auto',
  'overflow-y': 'scroll',
}

const realtorContactStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  height: 'auto',
  'overflow-y': 'scroll',
}