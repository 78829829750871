import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning';
import { CircularProgress, Paper, Tooltip } from '@mui/material'
import useDemoMode from 'hooks/useDemoMode'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { BedroomDetail } from 'routes/responses'
import { theme } from 'styles/themes'



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F5F9FD',
  },
}))

type Order = 'asc' | 'desc'

// function getComparator<Key extends keyof any>(
//   order: Order,
//   orderBy: Key
// ): (
//   a: { [key in Key]: number | string },
//   b: { [key in Key]: number | string }
// ) => number {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy)
// }

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

interface EnhancedTableProps {
  order: Order
  orderBy: string
  rowCount: number
  headCells: HeadCell[]
  setOpen?: Function
  setInfoType?: Function
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells, setOpen, setInfoType } =
    props

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }
  return (
    <TableHead>
      <TableRow style={{ 
        backgroundColor: theme.palette.mode === 'dark' ? '' : 'white',
         height: '50px'
         }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'none'}
            // sortDirection={orderBy === headCell.id ? order : false}
            sx={{ textTransform: 'capitalize' }}
          >
            <div style={{ display: 'inline-flex' }}>
            <div style={{ fontSize: '12px', padding: 10 }}>{headCell.label}</div>

              {setInfoType && setOpen &&
                <IconButton
                  onClick={() => {
                    setInfoType(headCell.label)
                    setOpen(true)
                  }}
                >
                  <InfoIcon fontSize={'small'} />
                </IconButton>
              }

              {/* <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              > */}
              {/* {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null} */}
              {/* </TableSortLabel> */}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface CityProfileTableProps {
  searchState: string
  searchCity: string
  // searchFunction: Function
  // columns: any
  // rowsFunction: Function
  revenuePercentile: number
  bedroomDetails: BedroomDetail[] | undefined
  setOpen?: Function
  setInfoType?: Function,
  bedroomCounts?: string[]
}

export default function CityProfileTable(props: CityProfileTableProps) {
  const [isLoading, setIsLoading] = useState(false)
  let isDemo = useDemoMode()

  const sendDataToGTM = useGTMDispatch()

  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('bedroomCount')

  const headerCells = ['BEDROOMS', 'REVENUE', 'VALUATION', 'GROSS ROI', 'OCCUPANCY']
    .map(column => {
      return {
        disablePadding: true,
        id: column,
        label: column,
        numeric: true,
      }
    })

  function sendToGTM() {
    sendDataToGTM({
      event: 'DemoGetStarted',
    })
  }

  function sendGTMAndRedirect() {
    sendToGTM()
    return '/login'
  }

  function isDemoCityAndState() {
    return props.searchCity === 'Denver' && props.searchState === 'Colorado'
  }

  function getByPercentileNumber(isROI: boolean, bedroomDetail: BedroomDetail) {
    if (props.revenuePercentile === 50) {
      return isROI ? bedroomDetail.roi.fiftieth : bedroomDetail.revenue.fiftieth;
    }
    if (props.revenuePercentile === 60) {
      return isROI ? bedroomDetail.roi.sixtieth : bedroomDetail.revenue.sixtieth;
    }
    if (props.revenuePercentile === 70) {
      return isROI ? bedroomDetail.roi.seventieth : bedroomDetail.revenue.seventieth;
    }
    if (props.revenuePercentile === 75) {
      return isROI ? bedroomDetail.roi.seventyFifth : bedroomDetail.revenue.seventyFifth;
    }
    if (props.revenuePercentile === 80) {
      return isROI ? bedroomDetail.roi.eightieth : bedroomDetail.revenue.eightieth;
    }
    if (props.revenuePercentile === 90) {
      return isROI ? bedroomDetail.roi.ninetieth : bedroomDetail.revenue.ninetieth;
    }
    if (props.revenuePercentile === 95) {
      return isROI ? bedroomDetail.roi.ninetyFifth : bedroomDetail.revenue.ninetyFifth;
    }
    return null;
  }
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function baseColor() {
    return theme.palette.mode === 'dark' ? 'gray' : '#F6F9FD'
  }
  function highlightColor() {
    return theme.palette.mode === 'dark' ? 'gray' : '#F6F9FD'
  }
  function nonHighlightColor() {
    return theme.palette.mode === 'dark' ? 'black' : 'rgba(217, 217, 217, 0.5)'
  }
  return (
    <Paper>
            <TableContainer>
        <Table
          style={{
            marginTop: 6,
          }}
          aria-labelledby='tableTitle'
          size={'small'}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            rowCount={props.bedroomDetails ? props.bedroomDetails.length : 0}
            headCells={headerCells}
            setOpen={props.setOpen}
            setInfoType={props.setInfoType}
            onRequestSort={(
              event: React.MouseEvent<unknown>,
              property: string
            ) => {
              const isAsc = orderBy === property && order === 'asc'
              handleRequestSort(event, property)
            }}
          />

          <TableBody>
            {isDemo && !isDemoCityAndState() && (
              <StyledTableRow hover role='checkbox'>
                <TableCell
                  style={{ background: theme.palette.mode === 'dark' ? '' : 'white' }}
                  colSpan={8}
                  height={150}
                >
                  <div style={{ textAlign: 'center', fontSize: '20px' }}>
                    <a href={sendGTMAndRedirect()}>
                      Get started to activate this table's insights
                    </a>
                  </div>
                </TableCell>
              </StyledTableRow>
            )}

            {isLoading && !isDemo && (
              <StyledTableRow hover role='checkbox'>
                <TableCell colSpan={8} height={250}>
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                </TableCell>
              </StyledTableRow>
            )}
            {props.bedroomDetails?.length === 0 && !isLoading && !isDemo && (
              <StyledTableRow hover role='checkbox'>
                <TableCell colSpan={8}>
                  <div style={{ textAlign: 'center' }}>No Results</div>
                </TableCell>
              </StyledTableRow>
            )}
            {props.bedroomDetails && props.bedroomDetails.length > 0 && (!isDemo || isDemoCityAndState()) && (
              <>
                {props.bedroomDetails
                  .slice()
                  .filter(c => {
                    if (c.bedroomCount === 0 || c.bedroomCount > 5) {
                      if (!c.valuation) {
                        return false;
                      }
                    }
                    return true;
                  })
                  .map((row: BedroomDetail, index: any) => {
                    const labelId = `enhanced-table-checkbox-${index}`
                    var formatter = new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0,
                    })
                    const roi = getByPercentileNumber(true, row);

                    return (
                      <StyledTableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        style={{ 
                          height: '40px', 
                          background: props.bedroomCounts?.length === 0 ? baseColor() : props.bedroomCounts?.filter(a => a === row.bedroomCount.toString())[0] ? highlightColor() : nonHighlightColor()
                        }}
                      >
                        <TableCell
                          align='right'
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          key={`${Math.random()}-name-${index}`}
                        >
                          {row.bedroomCount}
                        </TableCell>
                        <TableCell
                          align='right'
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          key={`${Math.random()}-name-${index}`}
                        >
                          {formatter.format(getByPercentileNumber(false, row) as number)}
                        </TableCell>
                        <TableCell
                          align='right'
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          key={`${Math.random()}-name-${index}`}
                        >
                          {row.valuation ? formatter.format(row.valuation) : 'N/A'}
                          {!row.valuation &&
                            <Tooltip title="Not enough data to provide an estimate - check back later!">
                              <IconButton>
                                <WarningIcon style={{ fontSize: '20px', color: '#FFD580' }} />
                              </IconButton>
                            </Tooltip>
                          }
                        </TableCell>
                        <TableCell
                          align='right'
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          key={`${Math.random()}-name-${index}`}
                        >
                          {roi ? Math.trunc(roi * 100) + '%' : 'N/A'}
                          {!roi &&
                            <Tooltip title="Not enough data to provide an estimate - check back later!">
                              <IconButton>
                                <WarningIcon style={{ fontSize: '20px', color: '#FFD580' }} />
                              </IconButton>
                            </Tooltip>
                          }
                        </TableCell>
                        <TableCell
                          align='right'
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='normal'
                          key={`${Math.random()}-name-${index}`}
                        >
                          {row.occupancy ? Math.trunc(row.occupancy * 100) + '%' : 'N/A'}

                        </TableCell>
                      </StyledTableRow>
                    )
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
}
