export default function terms() {
  return (
    <span>
      These terms and conditions (the "Terms and Conditions") govern the use of
      www.strinsights.com (the "Site"). This Site is owned and operated by Short
      Term Rental Insights Inc.. This Site is a subscription data product. By
      using this Site, you indicate that you have read and understand these
      Terms and Conditions and agree to abide by them at all times. Intellectual
      Property All content published and made available on our Site is the
      property of Short Term Rental Insights Inc. and the Site's creators. This
      includes, but is not limited to images, text, logos, documents,
      downloadable files and anything that contributes to the composition of our
      Site. Acceptable Use As a user of our Site, you agree to use our Site
      legally, not to use our Site for illegal purposes, and not to:  Violate
      the intellectual property rights of the Site owners or any third party to
      the Site;  Hack into the account of another user of the Site;  Scrape or
      mine data from the site; or  Share user account credentials with others.
      If we believe you are using our Site illegally or in a manner that
      violates these Terms and Conditions, we reserve the right to limit,
      suspend or terminate your access to our Site. We also reserve the right to
      take any legal steps necessary to prevent you from accessing our Site.
      Accounts When you create an account on our Site, you agree to the
      following: 1. You are solely responsible for your account and the security
      and privacy of your account, including passwords or sensitive information
      attached to that account; and 2. All personal information you provide to
      us through your account is up to date, accurate, and truthful and that you
      will update your personal information if it changes. We reserve the right
      to suspend or terminate your account if you are using our Site illegally
      or if you violate these Terms and Conditions. Links to Other Websites Our
      Site contains links to third party websites or services that we do not own
      or control. We are not responsible for the content, policies, or practices
      of any third party website or service linked to on our Site. It is your
      responsibility to read the terms and conditions and privacy policies of
      these third party websites before using these sites. Limitation of
      Liability Short Term Rental Insights Inc. and our directors, officers,
      agents, employees, subsidiaries, and affiliates will not be liable for any
      actions, claims, losses, damages, liabilities and expenses including legal
      fees from your use of the Site. Indemnity Except where prohibited by law,
      by using this Site you indemnify and hold harmless Short Term Rental
      Insights Inc. and our directors, officers, agents, employees,
      subsidiaries, and affiliates from any actions, claims, losses, damages,
      liabilities and expenses including legal fees arising out of your use of
      our Site or your violation of these Terms and Conditions. Applicable Law
      These Terms and Conditions are governed by the laws of the State of New
      York. Severability If at any time any of the provisions set forth in these
      Terms and Conditions are found to be inconsistent or invalid under
      applicable laws, those provisions will be deemed void and will be removed
      from these Terms and Conditions. All other provisions will not be affected
      by the removal and the rest of these Terms and Conditions will still be
      considered valid. Changes These Terms and Conditions may be amended from
      time to time in order to maintain compliance with the law and to reflect
      any changes to the way we operate our Site and the way we expect users to
      behave on our Site. We will notify users by email of changes to these
      Terms and Conditions or post a notice on our Site. Refunds Short Term
      Rental Insights Inc. reserves the right to decide to issue or not issue a
      refund for a subscription plan purchased by a user. In most instances, a
      refund will not be issued unless a user has purchased a different
      subscription plan. The user may choose to cancel their current
      subscription plan at any time without being charged for future months or
      years, however, Short Term Rental Insights Inc. will not pro-rate or
      discount the current subscription plan if the user decides to cancel
      before the designated renewal date of the plan. ©2002-2022 LawDepot.com®
      Contact Details Please contact us if you have any questions or concerns.
      Our contact details are as follows: alexander.gillie@strinsights.com
      kenneth.bedwell@strinsights.com ______________________________________ You
      can also contact us through the feedback form available on our Site.
    </span>
  )
}
