import { DashboardFilters } from './dashboard-filters'
import MarketTable from './market-table'
import ConfigureColumns from './configure-columns'
import useGlobal from 'hooks/useGlobal'
import { useEffect } from 'react'
import { strFetch } from 'utils/api'

interface DashboardProps {
  columns: any
  filters: any
  filterOrder: any
  filterOrderBy: any
  useBedrooms: boolean
  revenuePercentile: any
}

export default function Dashboard(props: DashboardProps) {
  const { state: global, dispatch } = useGlobal()
  useEffect(() => {
    dispatch.setRevenuePercentile('perc75')
  }, [])
  
  return (
    <div>
      <ConfigureColumns></ConfigureColumns>
      <DashboardFilters
        filterdStates={props.filters.state}
        filters={props.filters}
        useBedrooms={props.useBedrooms}
      ></DashboardFilters>
      <MarketTable
        rows={global.tableRows}
        headerCells={global.headerCells}
        useBedrooms={props.useBedrooms}
        revenuePercentile={props.revenuePercentile}
        mapMode={false}
      ></MarketTable>
    </div>
  )
}
