
export const viewOptions = [
    'Beachfront',
    'Waterfront',
    'Ocean view',
    'Mountain view',
    'Lake view',
    'Sea view',
    'Beach view',
    'River view',
    'City skyline view',
    'Harbour view',
    'Marina view',
    'Vineyard view',
    'Resort view',
    'Pool view',
    'Desert view',
    'Valley view',
    'Bay view',
    'Park view',
    'Garden view',
    'Golf course view',
    'Courtyard view',
    'Canal view',
]


export const amenityOptions = [
    'Backyard',
    'Private Pool',
    'Hot tub',
    'Ski-in Ski-out',
    'Resort access',
    'Beach Access',
    'Lake access',
    'Sauna',
    'Fire pit',
    'Shared Pool',
    'Sauna',
    'Patio or Balcony',
    'Outdoor Kitchen',
    'Outdoor dining area',
    'Indoor Fireplace',
    'Air conditioning',
    'EV charger',
    'Free Parking',
]


export const itemOptions = [
    'Outdoor Furniture',
    'Pool Table',
    'Fast Wifi',
    'Beach Essentials',
    'Cable or Satellite',
    'TV > 55inches',
    'Dryer',
    'Washer',
    'Grill',
    'Game Console',
    'Espresso Machine',
    'Free Streaming',
    'Family Friendly',
    'Ping Pong Table',
    'Dedicated workspace',
    'Gym',
    'Kayak',
    'Bikes',
    'Outdoor Shower',
]

const propertyTypeOptions = [
    'Shared room',
    'Private Room',
    'Condominium',
    'Townhome',
    'Other',
    'Unique',
    'Private room',
    'Residential home',
]