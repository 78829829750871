import { Slider } from '@mui/material'
import useDemoMode from 'hooks/useDemoMode'
import useGlobal from 'hooks/useGlobal'

export default function RevenuePercentileSlider() {
  let isDemo = useDemoMode()
  const { state: global, dispatch } = useGlobal()

  return (
    <div style={{ width: '250px' }}>
      <Slider
        disabled={isDemo}
        defaultValue={global.marketProfileRevenuePercentile}
        valueLabelDisplay='off'
        // Needs to be null to allow steps based on the
        // below marks
        step={null}
        marks={[
          {
            value: 50,
            label: '50',
          },
          {
            value: 60,
            label: '60',
          },
          {
            value: 70,
            label: '70',
          },
          {
            value: 75,
            label: '75',
          },
          {
            value: 80,
            label: '80',
          },
          {
            value: 90,
            label: '90',
          },
          {
            value: 95,
            label: '95',
          },
        ]}
        min={50}
        max={95}
        onChange={(event: any) => {
          dispatch.setMarketProfileRevenuePercentile(event.target.value)
        }}
        size='small'
      />
    </div>
  )
}
