import { useEffect, useState } from 'react'
import _ from 'lodash'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../../styles/themes'
import { styled } from '@mui/system'
import { CheckmarkFilled } from '../../styles/icons'
import PageHeader from '../../components/shared/page-title'
import AppBar from '../../components/AppBar'
import { strFetch, getCookie } from '../../utils/api'
import { Box, Button, Modal, Typography } from '@mui/material'
import BottomSnackbar from '../../components/snackbar'
import useConferenceMode from 'hooks/useConferenceTrialMode'
import useGlobal from 'hooks/useGlobal'
import StarIcon from '@mui/icons-material/Star';

const SubscriptionClosedModal = styled('div')({
  border: '2px solid red',
  'border-radius': '20px',
  height: '30px',
  padding: '15px',
  display: 'flex',
  'align-items': 'center',
  'font-size': '20px',
})

const PricingPageWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  'flex-direction': 'column',
  'align-items': 'center',
})

const PricingPlansContainer = styled('div')({
  width: '100%',
  display: 'flex',
  'flex-direction': 'row',
  '@media screen and (max-width: 600px)': {
    display: 'flex',
    'flex-direction': 'column !important',
  },
  justifyContent: 'center',
})

const PricingPlanWrapper = styled('div')({
  width: '280px',
  'border-radius': '20px',
  'box-shadow': '0px 4px 50px 0px #00000014',
  background: theme.palette.mode === 'dark' ? '' : '#FFFFFF',
  padding: '20px',
  margin: '20px',
})

type PricingPlanLabelProps = {
  featured: boolean
}

const PricingPlanTopLabel = styled('div')<PricingPlanLabelProps>((props) => ({
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'center',
  'align-items': 'center',
  padding: '10px 20px',
  gap: '10px',
  width: '92px',
  height: '21px',
  left: '534px',
  top: '408px',
  color: theme.palette.mode === 'dark' ? '' : '#FFFFFF',

  /* Gradiant */

  background: props.featured
    ? 'linear-gradient(226.01deg, #FF6700 -23.49%, #EFAE81 500.92%)'
    : 'linear-gradient(306.01deg, #0DC0F3 -23.49%, #4DDBB7 110.92%)',
  'border-radius': '10px',
}))

const PricingPlanPriceWrapper = styled('div')({
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'flex-start',
  'align-items': 'center',
  margin: '15px 0px',
  width: '200px',
})

const PricingPlanPrice = styled('div')({
  display: 'flex',
  'font-size': '44px',
  'line-height': '130%',
  /* or 57px */
  color: theme.palette.mode === 'dark' ? '' : '#273047',
})

const PricingPlanPeriod = styled('div')({
  display: 'flex',
  'align-items': 'center',
  'margin-left': '10px',
})

const PricingPlanDescription = styled('div')({
  'font-weight': '400',
  'font-size': '16px',
  'line-height': '170%',
  /* or 27px */
  color: '#8C96AE',
  margin: '10px 0px',
})

const PricingPlanFeatures = styled('div')({
  padding: '10px 00px',
})

const PricingPlanChoosePlan = styled('div')<PricingPlanLabelProps>((props) => ({
  background: props.featured
    ? 'linear-gradient(226.01deg, #FF6700 -23.49%, #EFAE81 500.92%)'
    : 'linear-gradient(306.01deg, #0DC0F3 -23.49%, #4DDBB7 110.92%)',
  'border-radius': '16px',
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'align-items': 'center',
  padding: '18px 28px',
  gap: '11px',
  color: theme.palette.mode === 'dark' ? '' : '#FFFFFF',
  'font-weight': '500',
  'font-size': '20px',
  'line-height': '130%',
  cursor: 'pointer',
}))

const PricingPlanChoosePlanDisabled = styled('div')({
  background: '#696969',
  'border-radius': '16px',
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'align-items': 'center',
  padding: '18px 28px',
  gap: '11px',
  color: theme.palette.mode === 'dark' ? '' : '#FFFFFF',
  'font-weight': '500',
  'font-size': '20px',
  'line-height': '130%',
  cursor: 'not-allowed',
})

const PricingPlanSingleFeature = styled('div')({
  'margin-bottom': '10px',
  display: 'flex',
  'flex-direction': 'row',
})

const PricingPlanFeatureDescription = styled('div')({
  'margin-left': '10px',
})

const PricingPageHeader = styled('div')({
  marginBottom: '50px',
  marginTop: '20px',
  textAlign: 'center',
})

const PlanContainer = styled('div')({
  width: '100%',
  borderRadius: '8px',
  boxShadow: '0 0 8px rgb(0 0 0 / 20%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.mode === 'dark' ? '' : 'white',
})

const pricingPlanDetails = [
  {
    name: 'Yearly',
    period: 'year',
    price: '1599.99',
    subscriptionTerm: 'yearly',
    monthlyCost: '133.33',
    percentageSaved: '32%'
  },
  {
    name: 'Quarterly',
    period: 'quarter',
    price: '449.99',
    subscriptionTerm: 'quarterly',
    featured: true,
    monthlyCost: '149.99',
    percentageSaved: '23%'
  },
  {
    name: 'Monthly',
    period: 'month',
    price: '194.99',
    subscriptionTerm: 'monthly',
    monthlyCost: '194.99'
  },
  {
    name: 'Trial',
    period: 'trial',
    price: '1',
    subscriptionTerm: 'trial',
    featured: true,
  },
]

async function getCheckoutSession(subscriptionTerm: string) {
  return strFetch(
    `/create-checkout-session/${subscriptionTerm}/${localStorage.getItem('papCookie') ?? getCookie('papCookie') ?? 'default'
    }`
  )
}

async function createFutureSession(subscriptionTerm: string) {
  return strFetch(`/update-future-plan/${subscriptionTerm}`, {
    method: 'PUT',
  })
}

const SUBSCRIPTION_CLOSED = false

export default function PricingWrapper() {
  const {
    dispatch: { setSnackbar },
  } = useGlobal()
  return <Pricing setSnackbar={setSnackbar} />
}

interface PricingProps {
  setSnackbar: Function
}

function Pricing(props: PricingProps) {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showConferenceOffering, setShowConferenceOffering] = useState(false)

  const [term, setTerm] = useState('')
  let isConferenceMode = useConferenceMode()

  useEffect(() => {
    ; (async function getData() {
      const result = await strFetch(
        '/user/info',
        {
          headers: { 'Content-Type': 'application/json' },
        },
        false
      )
      if (result.stripeData.subscribed) {
        setHasActiveSubscription(true)
      } else {
        setHasActiveSubscription(false)
      }
    })()
  }, [])

  useEffect(() => {
    strFetch(
      '/feature-flags',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
      false
    ).then((flags) => {
      setShowConferenceOffering(
        (flags as any[]).filter(
          (a: { feature_type: string }) => a.feature_type === 'conference_mode'
        )[0].enabled
      )
    })
  }, [])

  const handleChoosePlanClick = async (plan: typeof pricingPlanDetails[0]) => {
    if (hasActiveSubscription) {
      setShowConfirmationModal(true)
      setTerm(plan.subscriptionTerm)
    } else {
      const checkoutSession = await getCheckoutSession(plan.subscriptionTerm)
      if (!checkoutSession) return
      // TODO: Add better error handling
      localStorage.setItem('str_samt', plan.price)
      window.location.href = checkoutSession.session.url
    }
  }

  function processFutureSubsciption() {
    setShowConfirmationModal(false)
    createFutureSession(term).then(() => {
      props.setSnackbar({
        isOpen: true,
        autoHideDuration: 30000,
        severity: 'success',
        message: 'Subscription Updated',
      })
    })
  }

  const renderPricingPlanFeatures = () => {
    return (
      <PricingPlanFeatures>
        <PricingPlanSingleFeature>
          <CheckmarkFilled />
          <PricingPlanFeatureDescription>
            Discover the top markets across the entire United States that best
            fit your criteria
          </PricingPlanFeatureDescription>
        </PricingPlanSingleFeature>
        <PricingPlanSingleFeature>
          <CheckmarkFilled />
          <PricingPlanFeatureDescription>
            Identify the gross ROI for each market by destination type
          </PricingPlanFeatureDescription>
        </PricingPlanSingleFeature>
        <PricingPlanSingleFeature>
          <CheckmarkFilled />
          <PricingPlanFeatureDescription>
            Compare vacation rental housing prices in every market
          </PricingPlanFeatureDescription>
        </PricingPlanSingleFeature>
        <PricingPlanSingleFeature>
          <CheckmarkFilled />
          <PricingPlanFeatureDescription>
            Analyze revenue of active properties and access their listing links
          </PricingPlanFeatureDescription>
        </PricingPlanSingleFeature>
      </PricingPlanFeatures>
    )
  }

  const renderPricingPlan = (planDetails: typeof pricingPlanDetails[0]) => {
    // Checks if conference mode is on. This is based on the URL nav for
    // conference offering. If that page was not navigated to then do not
    // show the offering
    if (planDetails.price === '1' && !isConferenceMode) {
      return
    }
    // If the above passes then we want to check further to make
    // sure the user does not have an active subscription. If they
    // do then we don't want them to see this option
    if (planDetails.price === '1' && hasActiveSubscription) {
      // If the user has an active subscription we can clear the
      // conferenceMode value that is used for the react hook for
      // conferenceMode. This ensures that the user can't create
      // the trial for many users on the same browser session.
      return
    }
    // If they do not have an active subscription and have conference
    // mode turned on through the URL Query Params then we want to
    // finally check if the global conference offering is turned off.
    // Only admins have the ability to toggle this option.
    if (planDetails.price === '1' && !showConferenceOffering) {
      return
    }
    return (
      <PricingPlanWrapper style={{ border: planDetails.name === 'Yearly' ? '1px solid #FF6700' : '0px solid' }}>
        <div style={{ position: 'absolute', width: '220px' }}>
          {planDetails.price !== '1' && planDetails.name === 'Yearly' &&
            <div style={{ display: 'inline-flex', width: '220px' }}>
              <div style={{ background: 'lightgray', padding: 4, borderRadius: 16 }}>
                <div style={{ fontSize: 12 }}>SAVE {planDetails.percentageSaved}</div>
              </div>
              <StarIcon style={{ color: '#FF6700', marginLeft: 'auto' }} />
            </div>
          }
          {planDetails.price !== '1' && planDetails.name === 'Quarterly' &&
            <div style={{ display: 'inline-flex', width: '220px' }}>
              <div style={{ background: 'lightgray', padding: 4, borderRadius: 16 }}>
                <div style={{ fontSize: 12 }}>SAVE {planDetails.percentageSaved}</div>
              </div>
            </div>
          }
        </div>

        <PricingPlanTopLabel style={{ marginTop: 39 }} featured={!!planDetails.featured}>
          {_.get(planDetails, 'name', '')}
        </PricingPlanTopLabel>
        <PricingPlanPriceWrapper>
          {planDetails.price === '1' && (
            <>
              <PricingPlanPrice>Free</PricingPlanPrice>
              <PricingPlanPeriod>/ 90 days</PricingPlanPeriod>
            </>
          )}
          {planDetails.price !== '1' && (
            <>
              <PricingPlanPrice>
                {_.get(planDetails, 'monthlyCost', '')}
              </PricingPlanPrice>
              <PricingPlanPeriod>
                / per Month
              </PricingPlanPeriod>
            </>
          )}

        </PricingPlanPriceWrapper>
        {/* <PricingPlanChoosePlan>Choose Plan</PricingPlanChoosePlan> */}
        {SUBSCRIPTION_CLOSED ? (
          <PricingPlanChoosePlanDisabled>
            Not Currently Available
          </PricingPlanChoosePlanDisabled>
        ) : (
          <PricingPlanChoosePlan
            featured={!!planDetails.featured}
            onClick={() => handleChoosePlanClick(planDetails)}
          >
            Choose Plan
          </PricingPlanChoosePlan>
        )}
        {planDetails.price === '1' && (
          <div style={{ marginTop: '12px' }}>
            449.99 / per month after 90 days
          </div>
        )}
        <div style={{ marginTop: 12 }}>
          {planDetails.price !== '1' && (planDetails.name === 'Yearly' || planDetails.name === 'Quarterly') && (
            <>
              One time payment of {planDetails.price}
            </>
          )}
        </div>
      </PricingPlanWrapper>
    )
  }

  return (
    <PlanContainer>
      <BottomSnackbar></BottomSnackbar>
      <Modal
        disableAutoFocus={true}
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Subscription Change
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Once complete the changes will take effect at the end of your
            current subscription cycle. Are you sure you'd like to proceed?
          </Typography>
          <Button
            style={{ marginTop: 20, marginRight: 20 }}
            onClick={() => {
              setShowConfirmationModal(false)
            }}
          >
            No Thanks
          </Button>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => {
              processFutureSubsciption()
            }}
          >
            Proceed
          </Button>
        </Box>
      </Modal>

      <ThemeProvider theme={theme}>
        <PricingPageWrapper>
          <PricingPageHeader>
            <PageHeader
              firstTitle={'Pricing'}
              secondTitle={'Plans'}
              text={
                "Find out which solution is right your short-term rental business's needs"
              }
              theme={theme.palette.mode}
            />
          </PricingPageHeader>
          <div style={{ marginTop: 20 }}>{renderPricingPlanFeatures()}</div>

          {/* {SUBSCRIPTION_CLOSED && <SubscriptionClosedModal>
                        Access will be available on Friday, July 8th.
                        </SubscriptionClosedModal>} */}
          <PricingPlansContainer id='main'>
            {_.map(pricingPlanDetails, (planDetails) =>
              renderPricingPlan(planDetails)
            )}
          </PricingPlansContainer>
        </PricingPageWrapper>
      </ThemeProvider>
    </PlanContainer>
  )
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  p: 4,
}
