import React, { useState, useRef } from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useGlobal from "hooks/useGlobal";

interface MarkerProps {
    lat: number
    lng: number

    data: {
        city: string
        state: string
    }
}

export default function MapModeMarker(props: MarkerProps) {
    const [openedPopover, setOpenedPopover] = useState(false)
    const popoverAnchor = useRef(null)
    const { state: global, dispatch } = useGlobal()
    const navigate = useNavigate()

    const popoverEnter = () => {
        setOpenedPopover(true)
    }

    const popoverLeave = () => {
        setOpenedPopover(false)
    }

    function toCity() {
        dispatch.setStateAndCityFilters(props.data.city, props.data.state)
        navigate(`/city?city=${props.data.city}&state=${props.data.state}`)
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    })

    return (
        <div>
            <Popover
                id='mouse-over-popover'
                sx={{
                    pointerEvents: 'none',
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transitionDuration={100}
                PaperProps={{
                    onMouseEnter: popoverEnter,
                    onMouseLeave: popoverLeave,
                    sx: { pointerEvents: 'auto' },
                }}
                disableRestoreFocus
            >
                <div style={{ width: 'auto', padding: '.25em .5em' }}>

                    <div style={{ width: '212px', padding: '.25em .5em' }}>
                        <Typography style={{ width: '100%', textAlign: 'left' }}>
                            {props.data.city}, {props.data.state}
                        </Typography>
                        <Typography style={{ width: '100%', textAlign: 'left' }}>
                            <Button
                                className='linear-gradient-styling'
                                variant='outlined'
                                onClick={toCity}
                                size='medium'
                                sx={{ flexGrow: 1, flexBasis: 0, minWidth: 100, marginTop: '12px' }}
                            >
                                See Profile
                            </Button>
                        </Typography>

                    </div>
                </div>
            </Popover>
            <div className="well-marker" style={{ height: 20, width: 20 }}
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
                ref={popoverAnchor}
                aria-owns={'mouse-over-popover'}
                aria-haspopup='true'
                onClick={() => {
                }}>

            </div>
        </div>
    )
}


const blur = {
    width: '100%',
    heigth: '100%',
    background: 'rgba(255, 255, 255, 0.2)',// Make sure this color has an opacity of less than 1
    filter: 'blur(8px)'// This be the blur
}