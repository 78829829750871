import { useEffect, useState } from 'react'
import {useLocation} from "react-router-dom";
import { strFetch } from 'utils/api'

const useDemoMode = () => {
  const search = useLocation().search
  const [isDemo, setIsDemo] = useState(() => {
    if (sessionStorage.getItem('demo') === 'true') {
      if (localStorage.getItem('userName')) {
        strFetch('/logout')
        localStorage.removeItem('userName')
        localStorage.removeItem('email')
      }
      return true
    }

    const demo = new URLSearchParams(search).get('demo') !== null

    sessionStorage.setItem('demo', demo ? 'true' : 'false')
    return demo
  })
  return isDemo
}

export default useDemoMode
