import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'

const Little = () => {
  return (
    <div
      style={{
        backgroundColor: '#d7f4dc',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
      }}
    >
      <LockOpenOutlinedIcon fontSize='large' sx={{ color: '#2fb344' }} />
    </div>
  )
}

export default Little
