import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded'

const QuestionMark = () => {
  return (
    <div
      style={{
        backgroundColor: '#e9eaed',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
      }}
    >
      <QuestionMarkRoundedIcon fontSize='large' sx={{ color: '#616876' }} />
    </div>
  )
}

export default QuestionMark
