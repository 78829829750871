import { createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#4ddbb7',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff9a6c',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Outfit',
  },
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: grey[900],
      paper: grey[900],
    },
    text: {
    ...({
          primary: '#fff',
          secondary: grey[500],
        }),
    },
  },

});

export const theme = 'dark' === localStorage.getItem('theme') ? darkTheme : lightTheme