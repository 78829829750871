import { render } from 'react-dom'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import * as _ from 'lodash'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter, Routes, Route, useSearchParams } from 'react-router-dom'
import Login from './routes/login/login'
import RegisterRoute from './routes/register'
import ForgotPasswordRoute from './routes/forgotPassword'
import Main from './components/App'
import { theme } from './styles/themes'
import { GlobalProvider } from 'providers/GlobalProvider'
import Authenticator from 'routes/authenticator'
import AmenitiesRegisterRoute from 'routes/amenities-register'
import AmenitiesSuccess from 'routes/amenities-register/register-success-page'

const rootElement = document.getElementById('root')

const gtmParams = { id: 'GTM-T6ZJ946' }

render(
  <GTMProvider state={gtmParams}>
    <GlobalProvider>
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path='/*' element={<Main />}></Route>
            <Route path='login' element={<Login />}></Route>
            <Route path='register' element={<RegisterRoute />}></Route>
            <Route path='amenities-register' element={<AmenitiesRegisterRoute />}></Route>
            <Route path='amenities-register-success' element={<AmenitiesSuccess />}></Route>
            <Route
              path='forgot-password'
              element={<ForgotPasswordRoute />}
            ></Route>
            <Route path='authenticated' element={<Authenticator/>}></Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </GlobalProvider>
  </GTMProvider>,
  rootElement
)
