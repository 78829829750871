import { useState, useRef } from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from "@mui/material";

interface MarkerProps {
  text: string
  tooltip: string
  lat: number
  lng: number
  airbnbLink: string
  vrboLink: string
  airbnbLinkText: string
  vrboLinkText: string
  revenue: string
  data: {
    bedrooms: string
    bathrooms: string
    adr: number
    airbnb_id: string | null
    vrbo_id: string | null
    latitude: number
    longitude: number
    occupancy: number
    revenue: string
    rating: string
  },
  setPropertyForProfile: Function,
  setPropertyProfileVisible: Function,
  fetchRevenueByListingId: Function,
  fetchExtraPropertyInfoByListingId: Function,
  setTabValue: Function 
}

export default function Marker(props: MarkerProps) {
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null)

  const popoverEnter = () => {
    setOpenedPopover(true)
  }

  const popoverLeave = () => {
    setOpenedPopover(false)
  }

  function setupForProfile(propertyLink: any) {
    props.setTabValue(0)
    props.setPropertyProfileVisible(true);
    props.fetchRevenueByListingId(props.data.airbnb_id)
    props.fetchExtraPropertyInfoByListingId(props.data.airbnb_id)
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })

  return (
    <div>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={100}
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
          sx: { pointerEvents: 'auto' },
        }}
        disableRestoreFocus
      >
        <div style={{ width: '212px', padding: '.25em .5em' }}>
          <Typography style={{ width: '100%' }}>
            {props.data.bedrooms} bed,&nbsp;{props.data.bathrooms} bath
          </Typography>
          <hr style={{ backgroundColor: '#ddd', height: 1, border: 0 }} />
          {props.revenue && (
            <dl style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
              <dt style={{ width: '33%' }}>Revenue:</dt>
              <dd>
                <span
                  style={props.revenue.trim() === 'no data' ? blur : undefined}
                >
                  {props.revenue}
                </span>
              </dd>
            </dl>
          )}
          {props.data.occupancy && (
            <dl style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
              <dt style={{ width: '33%' }}>Occupancy:</dt>
              <dd>{Math.round(props.data.occupancy * 100)}%</dd>
            </dl>
          )}
          {props.data.adr && (
            <dl style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
              <dt style={{ width: '33%' }}>ADR:</dt>
              <dd>{formatter.format(props.data.adr)}</dd>
            </dl>
          )}
          {props.data.rating && (
            <dl style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
              <dt style={{ width: '33%' }}>Rating:</dt>
              <dd>{props.data.rating}</dd>
            </dl>
          )}
          <hr style={{ backgroundColor: '#ddd', height: 1, border: 0 }} />
          {props.airbnbLink && (
            <a
              href={props.airbnbLink}
              target='_blank'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {props.airbnbLinkText}
              <LogoutIcon sx={{ fontSize: 14, ml: 1 }}></LogoutIcon>
            </a>
          )}
          {props.vrboLink && (
            <a
              href={props.vrboLink}
              target='_blank'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {props.vrboLinkText}
              <LogoutIcon sx={{ fontSize: 14, ml: 1 }}></LogoutIcon>
            </a>
          )}
          <div style={{ marginTop: '12px', textAlign: 'center', marginBottom: '12px' }}>
            <Button onClick={() => setupForProfile(props.airbnbLink)}>
              See Profile
            </Button>
          </div>

        </div>
      </Popover>
      <Typography
        ref={popoverAnchor}
        aria-owns={'mouse-over-popover'}
        aria-haspopup='true'
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        style={{
          height: '14px',
          width: '14px',
          background: '#15458D',
          borderRadius: '50% 50% 50% 50%',
          border: 'solid 1px #E2E2E2',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <span>{props.text}</span>
      </Typography>
    </div>
  )
}


const blur = {
  width: '100%',
  heigth: '100%',
  background: 'rgba(255, 255, 255, 0.2)',// Make sure this color has an opacity of less than 1
  filter: 'blur(8px)'// This be the blur
}