import { Grid } from "@mui/material";
import useBreakpoint from "hooks/useBreakpoint";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from "recharts";

export default function RevenueDetails(props: any) {
    const breakpoint = useBreakpoint()

    const { propertyForProfile, revenueForProfile, percentageFormatter, formatMoney } = props;

    return (
        <>
            {(propertyForProfile && revenueForProfile) &&
                <>
                    {/* <div className="mb-2 text-lg font-semibold">
                        {propertyForProfile.title}
                    </div> */}
                    {/* <hr></hr> */}
                    <div style={{ width: '100%', marginTop: '20px', fontSize: '18px', textAlign: 'center' }}>
                        Trailing Yearly Attributes
                    </div>
                    <div style={{ display: ['xs', 'sm'].includes(breakpoint!) ? 'grid' : 'flex', gap: '1.25rem', marginBottom: '1.25rem', justifyContent: 'center' }}>
                        <div style={{ border: 'solid 1px grey', width: '240px', padding: '1.25rem' }}>
                            <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold' }} >
                                Revenue
                            </div>
                            <div style={{ marginTop: '12px', textAlign: 'center' }}>
                                {formatMoney(propertyForProfile.revenue)}
                            </div>
                        </div>
                        <div style={{ border: 'solid 1px #475569', width: '240px', padding: '1.25rem' }}>
                            <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold' }} >
                                Occupancy
                            </div>
                            <div style={{ marginTop: '12px', textAlign: 'center' }}>
                                {Math.trunc(propertyForProfile.occupancy * 100)}%
                            </div>
                        </div>
                        <div style={{ border: 'solid 1px #475569', width: '240px', padding: '1.25rem' }}>
                            <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold' }} >
                                Average Daily Rate
                            </div>
                            <div style={{ marginTop: '12px', textAlign: 'center' }}>
                                {formatMoney(propertyForProfile.adr)}
                            </div>
                        </div>
                    </div>

                    <Grid item md={6} xs={12} style={{ padding: '20px' }}>
                        <div style={{ width: '100%', marginTop: '12px', marginBottom: '12px', fontSize: '24px', textAlign: 'center' }}>
                            Historical Revenue Attributes
                        </div>
                        <div style={{ width: '100%', fontWeight: 'bold', marginTop: '12px', marginBottom: '12px', fontSize: '24px', textAlign: 'center' }}>Revenue Performance</div>
                        <ResponsiveContainer width="100%" aspect={5}>
                            <LineChart height={200} data={revenueForProfile} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                <YAxis tickFormatter={formatMoney} />
                                <Tooltip labelStyle={{ color: 'black' }} formatter={formatMoney} />
                                <Line dataKey={'revenue'} stroke="green" />
                            </LineChart>
                        </ResponsiveContainer>
                        <div style={{ width: '100%', fontWeight: 'bold', marginTop: '12px', marginBottom: '12px', fontSize: '24px', textAlign: 'center' }}>Occupancy Performance</div>
                        <ResponsiveContainer width="100%" aspect={5}>
                            <LineChart height={200} data={revenueForProfile} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                <YAxis tickFormatter={percentageFormatter} />
                                <Tooltip labelStyle={{ color: 'black' }} formatter={percentageFormatter} />
                                <Line dataKey={'occupancy'} stroke="blue" />
                            </LineChart>
                        </ResponsiveContainer>
                        <div style={{ width: '100%', fontWeight: 'bold', marginTop: '12px', marginBottom: '12px', fontSize: '24px', textAlign: 'center' }}>ADR Performance</div>
                        <ResponsiveContainer width="100%" aspect={5}>
                            <LineChart data={revenueForProfile} margin={{ top: 0, right: 20, bottom: 0, left: 10 }}>
                                <CartesianGrid />
                                <XAxis dataKey="name" interval={'preserveStartEnd'} />
                                <YAxis domain={[0, 'dataMax + 500']} tickFormatter={formatMoney} />
                                <Tooltip labelStyle={{ color: 'black' }} formatter={formatMoney} />
                                <Line dataKey={'adr'} stroke="red" />
                            </LineChart>
                        </ResponsiveContainer>
                    </Grid>

                </>
            }
        </>
    )
}