import History from './History'
import _ from 'lodash'
import useDemoMode from 'hooks/useDemoMode'
import useGlobal from 'hooks/useGlobal'
import { useLocation } from 'react-router-dom'

export default function HistoryIndex() {
  let isDemo = useDemoMode()
  const search = useLocation().search
  const city = new URLSearchParams(search).get('city')
  const state = new URLSearchParams(search).get('state')

  const { state: global } = useGlobal()

  return (
    <div>
      <History
        cityName={city ? city : _.get(
          global.cityProfileFilters,
          'cities[0]',
          isDemo ? 'Denver' : 'New York'
        )}
        state={state ? state :_.get(
          global.cityProfileFilters,
          'state[0]',
          isDemo ? 'Colorado' : 'New York'
        )}
        cityProfileFilters={_.get(global, 'cityProfileFilters', {
          cities: [isDemo ? 'Denver' : 'New York'],
          state: [isDemo ? 'Colorado' : 'New York'],
        })}
        revenuePercentile={global.marketProfileRevenuePercentile as any}
      />
    </div>
  )
}
