import {
    Container,
    CssBaseline,
    Box,
    Button
} from '@mui/material'
import BottomSnackbar from 'components/snackbar'
import { useNavigate } from 'react-router-dom'

export default function AmenitiesSuccess() {
    let navigate = useNavigate()

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: 'url("/city_background.svg")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
            }}
        >
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <BottomSnackbar></BottomSnackbar>
                <Box
                    sx={{
                        marginTop: '50px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'scroll',
                    }}
                >

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                            style={{
                                color: 'rgb(51,208,209)',
                                fontSize: '40px',
                                fontWeight: '700',
                                marginRight: '4px',
                            }}
                        >
                            Successful
                        </span>
                        <span
                            style={{ fontSize: '40px', fontWeight: '700', color: 'black' }}
                        >
                            Payment
                        </span>
                    </div>

                    <Box
                        style={{
                            border: 'solid 1px #aaa',
                            padding: '16px',
                            borderRadius: '8px',
                            background: 'white',
                        }}
                        component='form'
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <p style={{ fontSize: '24px' }}>
                            Thank you for registering! Please check your email for updates as a member from our team will connect with you soon!
                        </p>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => navigate('/login')}
                        >
                            Back To Login
                        </Button>
                    </Box>

                </Box>
            </Container>
        </div>
    )
}