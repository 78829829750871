import React, { createContext, useReducer } from 'react'
import _ from 'lodash'
import { strFetch } from 'utils/api'

type TFilterOrder = 'desc' | 'asc'
type TFilterOrderBy = 'gross_roi'
type TRevenuePercentile = 'perc50' | 'perc75' | 'perc90'
type TSnackbar = {
  isOpen: boolean
  autoHideDuration: number
  severity: 'success' | 'error' | 'warning' | 'info'
  message: string
}
export type TGlobalState = {
  filters: any
  columns: any[]
  tableRows: any[]
  tableRowsForExport: any[]
  tableLoading: boolean
  totalCount: number
  headerCells: any[]
  selectedColumns: string[]
  filterOrder: TFilterOrder
  filterOrderBy: TFilterOrderBy
  cityProfileFilters: any
  useBedrooms: boolean
  revenuePercentile: TRevenuePercentile
  marketProfileRevenuePercentile: number
  savedMarketRows: any[]
  cityProfileReportRows: any
  sideNavOpen: boolean
  snackbar: TSnackbar
  configureColumnsOpen: boolean,
  enableSavedMarkets: boolean
}
const initialState: TGlobalState = {
  filters: {marketSize: ["medium", "large"]},
  columns: [],
  tableRows: [],
  tableRowsForExport: [],
  headerCells: [],
  selectedColumns: [
    'state',
    'city_name',
    'gross_roi',
    'market_size',
    'revenue',
    'valuation',
    'active_listing_count',
  ],
  filterOrder: 'desc',
  cityProfileFilters: {},
  filterOrderBy: 'gross_roi',
  useBedrooms: false,
  revenuePercentile: 'perc75',
  marketProfileRevenuePercentile: 75,
  savedMarketRows: [],
  cityProfileReportRows: [],
  sideNavOpen: false,
  snackbar: {
    isOpen: false,
    autoHideDuration: 0,
    severity: 'success',
    message: '',
  },
  tableLoading: false,
  configureColumnsOpen: false,
  totalCount: 0,
  enableSavedMarkets: false
}

export type TGlobalDispatch = {
  setFilters: any
  clearFilters: any
  setTableRows: any
  setTableRowsForExport: any
  setHeaderCells: any
  setSelectedColumns: any
  setSideNavOpen: any
  setConfigureColumnsOpen: any
  setConfigureColumnsClosed: any
  setFilterOrder: any
  setCityProfileFilters: any
  setFilterOrderBy: any
  setStateAndCityFilters: any
  setUseBedrooms: any
  setRevenuePercentile: any
  setMarketProfileRevenuePercentile: any
  search: any
  setComparisonReportRows: any
  setCityProfileReportRows: any
  addSavedMarketRows: any
  removeSavedMarketRows: any
  setSnackbar: any
  setEnableSavedMarkets: any
}

interface ISearchProps {}

export const GlobalContext = createContext({})

const DISPATCH = {
  GLOBAL: {
    SET_STATE: 'setState',
  },
}

function reducer(
  state: TGlobalState,
  action: { type: string; data: any }
): TGlobalState {
  switch (action.type) {
    case DISPATCH.GLOBAL.SET_STATE:
      return {
        ...state,
        ...action.data,
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export function GlobalProvider({ children }: any) {
  const [state, dispatch] = useReducer(reducer, initialState)

  function setFilters(filterName: string, value: any) {
    const filters: any = {
      ...state.filters,
      [filterName]: value,
    }
    if (Array.isArray(value) && value.length === 0) {
      delete filters[filterName]
    }
    dispatch({
      type: DISPATCH.GLOBAL.SET_STATE,
      data: {
        filters,
      },
    })
  }

  function clearFilters() {
    dispatch({
      type: DISPATCH.GLOBAL.SET_STATE,
      data: {
        filters: {},
      },
    })
  }

  function actionSetState(data: any) {
    dispatch({
      type: DISPATCH.GLOBAL.SET_STATE,
      data,
    })
  }

  function setTableRows(tableRows: any) {
    actionSetState({ tableRows })
  }

  function setTableRowsForExport(tableRowsForExport: any) {
    actionSetState({ tableRowsForExport })
  }

  function setTableLoading(loading: boolean) {
    actionSetState({ tableLoading: loading })
  }

  function setTotalCount(totalCount: number) {
    actionSetState({ totalCount: totalCount })
  }


  function setHeaderCells(headerCells: any) {
    actionSetState({ headerCells })
  }

  function setSelectedColumns(selectedColumns: any) {
    actionSetState({ selectedColumns })
  }

  function setSideNavOpen(sideNavOpen: any) {
    actionSetState({ sideNavOpen })
  }

  function setConfigureColumnsOpen() {
    actionSetState({ configureColumnsOpen: true })
  }

  function setConfigureColumnsClosed() {
    actionSetState({ configureColumnsOpen: false })
  }

  function setFilterOrder(filterOrder: TFilterOrder) {
    actionSetState({ filterOrder })
  }

  function setCityProfileFilters(filterName: string, value: any) {
    const cityProfileFilters: any = {
      ...state.cityProfileFilters,
      [filterName]: value,
    }
    if (Array.isArray(value) && value.length === 0) {
      delete cityProfileFilters[filterName]
    }
    actionSetState({ cityProfileFilters })
  }

  function setFilterOrderBy(filterOrderBy: TFilterOrderBy) {
    const isAsc =
      filterOrderBy === state.filterOrderBy && state.filterOrder === 'asc'
    actionSetState({ filterOrderBy, filterOrder: isAsc ? 'desc' : 'asc' })
  }

  function setStateAndCityFilters(city: string, stateName: string) {
    actionSetState({
      cityProfileFilters: {
        ...state.cityProfileFilters,
        cities: [city],
        state: [stateName],
      },
    })
  }

  function setUseBedrooms(useBedrooms: any) {
    const selectedColumns = state.selectedColumns
    let filterOrderBy = state.filterOrderBy
    if (useBedrooms) {
      selectedColumns.push('bedrooms')
    } else {
      const index = selectedColumns.indexOf('bedrooms')
      if (index > -1) {
        selectedColumns.splice(index, 1)
      }
      filterOrderBy = 'gross_roi'
    }
    actionSetState({ useBedrooms, selectedColumns, filterOrderBy })
  }

  function setRevenuePercentile(revenuePercentile: TRevenuePercentile) {
    actionSetState({ revenuePercentile })
  }

  function setEnableSavedMarkets(enableSavedMarkets: boolean) {
    actionSetState({ enableSavedMarkets })
  }

  function setMarketProfileRevenuePercentile(
    marketProfileRevenuePercentile: number
  ) {
    actionSetState({ marketProfileRevenuePercentile })
  }

  async function search(props: ISearchProps) {
    setTableRows([])
    setTableLoading(true)

    const filters = _.get(props, 'filters', state.filters)
    if (filters.maxPrice) {
      filters.maxPrice = Number.parseInt(filters.maxPrice)
    }
    if (filters.minPrice) {
      filters.minPrice = Number.parseInt(filters.minPrice)
    }
    const useBedrooms = _.get(props, 'useBedrooms', state.useBedrooms)
    const revenuePercentile = _.get(
      props,
      'revenuePercentile',
      state.revenuePercentile
    )

    if (!useBedrooms) {
      delete filters['bedroomCounts']
    }
    
    const result = await strFetch(`/markets`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filters,
        order: _.get(props, 'order', state.filterOrder),
        orderBy: _.get(props, 'orderBy', state.filterOrderBy),
        useBedrooms,
        revenuePercentile,
        ids: _.get(props, 'ids', filters.ids ? filters.ids : []),
        skip:  _.get(props, 'skip', 0),
        take:  _.get(props, 'take', 25)

      }),
    })
    if (result === undefined || result.payload.length === 0) {
      setTotalCount(0)
      setTableLoading(false)
      setTableRows([])
      return
    }
    setTotalCount(result.recordCount)
    setTableLoading(false)

    let columns = [
      'state',
      'city',
      'market size',
      'roi',
      'revenue',
      'valuation',
      'active listing count',
    ]

    if (useBedrooms) {
      columns = [
        'state',
        'city',
        'market size',
        'bedrooms',
        'roi',
        'revenue',
        'valuation',
        'active listing count',
      ]
    } else {
      columns = columns.filter(a => a !== 'bedrooms');
    }

    if (state.selectedColumns.includes('accomodates_number')){
      columns.push('accomodates');
    } else {
      columns = columns.filter(a => a !== 'accomodates');
    }

    if (state.selectedColumns.includes('occupancy')){
      columns.push('occupancy');
    } else {
      columns = columns.filter(a => a !== 'occupancy');
    }

    if (state.selectedColumns.includes('adr')){
      columns.push('adr');
    } else {
      columns = columns.filter(a => a !== 'adr');
    }
    // const newRows = result.payload.map((row: any) => {
    //   const newRow = {}
    //   defaultOrder.forEach((o) => {
    //     if (row[o]) return ((newRow as any)[o] = row[o])
    //   })
    //   return newRow
    // })
    // const keys = Object.keys(newRows[0])
    setHeaderCells(
      columns.map((cell) => {
        return {
          id: cell,
          numeric: true,
          disablePadding: false,
          label: cell.toUpperCase(),
        }
      })
    )

    console.log(result.payload.map((c: any) => {
      return {
        accomodates: c.accomodates,
        activeListingCount: c.activeListingCount,
        adr: c.adr,
        city: c.city,
        marketSize: c.marketSize,
        occupancy: c.occupancy,
        regulationLevel: c.regulationLevel,
        revenue_50th_percentile: c.revenue.fiftieth,
        revenue_75th_percentile: c.revenue.seventyFifth,
        revenue_90th_percentile: c.revenue.ninetieth,
        roi_50th_percentile: c.roi.fiftieth,
        roi_75th_percentile: c.roi.seventyFifth,
        roi_90th_percentile: c.roi.ninetieth,
        state: c.state,
        valuation: c.valuation
      }
    }))

    setTableRowsForExport(result.payload.map((c: any) => {
      return {
        accomodates: c.accomodates,
        activeListingCount: c.activeListingCount,
        adr: c.adr,
        city: c.city,
        marketSize: c.marketSize,
        occupancy: c.occupancy,
        regulationLevel: c.regulationLevel,
        revenue_50th_percentile: c.revenue.fiftieth,
        revenue_75th_percentile: c.revenue.seventyFifth,
        revenue_90th_percentile: c.revenue.ninetieth,
        roi_50th_percentile: c.roi.fiftieth,
        roi_75th_percentile: c.roi.seventyFifth,
        roi_90th_percentile: c.roi.ninetieth,
        state: c.state,
        valuation: c.valuation
      }
    }))
    
    setTableRows(result.payload)
  }

  function setComparisonReportRows(comparisonReportRows: any[]) {
    actionSetState({ comparisonReportRows })
  }

  function setCityProfileReportRows(cityProfileReportRows: any[]) {
    actionSetState({ cityProfileReportRows })
  }

  function addSavedMarketRows(savedMarketRow: any) {
    let savedMarketRows = state.savedMarketRows as any[]
    savedMarketRows.push(savedMarketRow)
    actionSetState({ savedMarketRows })
  }

  function removeSavedMarketRows(savedMarketRow: any) {
    let savedMarketRows = state.savedMarketRows.filter((row) => {
      return row !== savedMarketRow
    })
    actionSetState({ savedMarketRows })
  }

  function setSnackbar(snackbar: TSnackbar) {
    actionSetState({ snackbar })
  }

  const value: {
    dispatch: TGlobalDispatch
    state: TGlobalState
  } = {
    dispatch: {
      setFilters,
      clearFilters,
      setTableRows,
      setHeaderCells,
      setSelectedColumns,
      setSideNavOpen,
      setConfigureColumnsOpen,
      setConfigureColumnsClosed,
      setFilterOrder,
      setCityProfileFilters,
      setFilterOrderBy,
      setStateAndCityFilters,
      setUseBedrooms,
      setRevenuePercentile,
      setMarketProfileRevenuePercentile,
      search,
      setComparisonReportRows,
      setCityProfileReportRows,
      addSavedMarketRows,
      removeSavedMarketRows,
      setSnackbar,
      setEnableSavedMarkets,
      setTableRowsForExport
    },
    state,
  }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}
