import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Container, CssBaseline, Box, Typography, TextField, Button, Grid } from '@mui/material'
import { strFetch } from '../../utils/api';
import BottomSnackbar from 'components/snackbar'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

export default function ForgotPasswordRoute() {
  let navigate = useNavigate();

  const [email, setEmail] = useState('')
  const [isFirstStep, setIsFirstStep] = useState(true)
  const [isSecondStep, setIsSecondStep] = useState(false)
  const [secondStepErrorMessage, setSecondStepErrorMessage] = useState('')
  const [thirdStepErrorMessage, setThirdStepErrorMessage] = useState('')

  const [isThirdStep, setIsThirdStep] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [code, setCode] = useState('')


  async function changePassword(event: any) {
    event.preventDefault()

    if (password !== confirmPassword) {
      setThirdStepErrorMessage('Passwords Do Not Match');
      return;
    }

    strFetch('/login/change-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: localStorage.getItem('forgotPasswordEmail'),
        password: password
      })
    }, false).then((result) => {    
      if (result.success) {
        navigate('/login')
      }
    })
  }

  async function confirmCode(event: any) {
    event.preventDefault()

    strFetch('/login/confirm-code/' + localStorage.getItem('forgotPasswordEmail') + '/' + code, {
      method: 'POST',
    }, false).then(result => {
      if (result.success) {
        setIsThirdStep(true);
        setIsSecondStep(false);
        return;
      } else if (result.code === 'CodeDoesNotExist') {
        setSecondStepErrorMessage('No Code For This Account')
      } else if (result.code === 'CodeIsIncorrect') {
        setSecondStepErrorMessage('Incorrect Code')
      } else if (result.code === 'CodeHasExpired') {
        setSecondStepErrorMessage('This Code Has Expired')
      } else if (result.code === 'CountExceeded') {
        setSecondStepErrorMessage('You Have Exceeded The Try Count!')
      } 
      
    })
  }

  async function sendInitialResetEmail(event: any) {
    event.preventDefault()
    strFetch('/login/forgot-password/' + email, {
      method: 'POST',
    }, false).then((result) => {
    
      if (result.success) {
        localStorage.setItem('forgotPasswordEmail', email)
        setIsFirstStep(false)
        setIsSecondStep(true)
  
      }
    })


  }

  return <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundImage: 'url("/city_background.svg")', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom'}}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <BottomSnackbar></BottomSnackbar>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
        >
        </Typography>
        <div style={{display: 'flex', alignItems: 'center'}}><span style={{color: 'black', fontSize: '40px', fontWeight: '700', marginRight: '4px'}}>Forgot</span><span style={{fontSize: '40px', fontWeight: '700', color: 'rgb(51,208,209)'}}>Password</span></div>
          <Typography component="p" style={{color: '8C96AE', fontSize: '15px'}}>
            Forgot your password? Follow these steps to reset!
          </Typography>
          {isFirstStep && 
          <Box style={{border: 'solid 1px #aaa', padding: '16px', borderRadius: '8px', background: 'white'}}
          component="form" onSubmit={sendInitialResetEmail} noValidate sx={{ mt: 1 }}>
            <TextField
            margin="normal"
            required
            fullWidth
            label="Email Address"
            onChange={(event) => {
              let isValid = event.target.value.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
              if (isValid) {
                // Hide the error messaging from the UI
                setEmail(event.target.value)
              }
            }}
            autoFocus
          />
            <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!(email)}
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          </Box> }

          {isSecondStep && 
          <Box style={{border: 'solid 1px #aaa', padding: '16px', borderRadius: '8px', background: 'white'}}
          component="form" onSubmit={confirmCode} noValidate sx={{ mt: 1 }}>
            <TextField
            margin="normal"
            required
            fullWidth
            label="Code"
            onChange={(event) => {
              let isValid = event.target.value.length > 0
              if (isValid) {
                // Hide the error messaging from the UI
                setCode(event.target.value)
              }
            }}
            autoFocus
          />
          {secondStepErrorMessage.length > 0 && 
          <div style={{color: 'red', fontSize: 12}}>
            {secondStepErrorMessage}
        </div>
          }
            <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!(code)}
            sx={{ mt: 3, mb: 2 }}
          >
            Verify Code
          </Button>
          </Box> 
          }

          {isThirdStep && 
          <Box style={{border: 'solid 1px #aaa', padding: '16px', borderRadius: '8px', background: 'white'}}
          component="form" onSubmit={changePassword} noValidate sx={{ mt: 1 }}>
            <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            onChange={(event) => {
              setThirdStepErrorMessage('')
              let isValid = event.target.value.length > 0
              if (isValid) {
                // Hide the error messaging from the UI
                setPassword(event.target.value)
              }
            }}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            onChange={(event) => {
              setThirdStepErrorMessage('')
              let isValid = event.target.value.length > 0
              if (isValid) {
                // Hide the error messaging from the UI
                setConfirmPassword(event.target.value)
              }
            }}
          />
          {thirdStepErrorMessage.length > 0 && 
          <div style={{color: 'red', fontSize: 12}}>
            {thirdStepErrorMessage}
          </div>
          }
          
            <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!(password)}
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          </Box> 
          }
          <Grid container>
            <Grid item xs>
              <Button onClick={() => navigate(-1)}>
                <KeyboardBackspaceRoundedIcon /> Back
              </Button>
            </Grid>
          </Grid>
      </Box>
    </Container>
  </div>
}