import { DashboardFilters } from '../dashboard/dashboard-filters'
import MarketTable from '../dashboard/market-table'
import ConfigureColumns from '../dashboard/configure-columns'
import useGlobal from 'hooks/useGlobal'
import { useEffect, useMemo, useRef, useState } from 'react'
import { strFetch } from 'utils/api'
import { DashboardFiltersMapMode } from './dashboard-filters-map-mode'
import GoogleMapReact from 'google-map-react'
import { Accordion, AccordionDetails, AccordionSummary, Popover, Stack, Typography } from '@mui/material'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import useSupercluster from 'use-supercluster'
import "./styles.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MapModeMarker from './map-mode-marker'
import _ from 'lodash'
import { track } from 'mixpanel'
import { theme } from 'styles/themes'

interface DashboardProps {
  columns: any
  filters: any
  filterOrder: any
  filterOrderBy: any
  useBedrooms: boolean
  revenuePercentile: any
}
const Marker = ({ children }: any) => children;

export default function DashboardMapView(props: DashboardProps) {
  const { state: global, dispatch } = useGlobal()
  const [bounds, setBounds] = useState<number[]>([]);
  const [zoom, setZoom] = useState(10);
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null)
  const [superclusterToUse, setSuperCluster] = useState<any>();
  const [clustersToUse, setClusters] = useState<any[]>([]);

  const popoverEnter = () => {
    setOpenedPopover(true)
  }

  const popoverLeave = () => {
    setOpenedPopover(false)
  }

  // setup map

  const mapRef = useRef<any>();

  const loadData = () => {
    var data = require('../resources/market-points.json'); // forward slashes will depend on the file location

    return data;
  };

  const data = useMemo(() => {
    let res = loadData();
    let response = [];
    const destinationTypes =  _.get(global, 'filters.destinationType', []);
    const marketSizes = global.filters.marketSize || [];
    const regulationLevels = global.filters.regulationLevels || [];
    const minPrice = global.filters.minPrice;
    const maxPrice = global.filters.maxPrice;

    response = res.map((elem: any) => {

      const beach = destinationTypes.filter((a: string) => a === 'beach')[0]
      const mountain = destinationTypes.filter((a: string) => a === 'mountain')[0]
      const ski = destinationTypes.filter((a: string) => a === 'ski')[0]
      const college = destinationTypes.filter((a: string) => a === 'college')[0]
      const river = destinationTypes.filter((a: string) => a === 'river')[0]
      const lake = destinationTypes.filter((a: string) => a === 'lake')[0]
      const nationalPark = destinationTypes.filter((a: string) => a === 'national park')[0]
      const statePark = destinationTypes.filter((a: string) => a === 'state park')[0]
      const themePark = destinationTypes.filter((a: string) => a === 'theme park')[0]
      const historical = destinationTypes.filter((a: string) => a === 'historical')[0]
      const city = destinationTypes.filter((a: string) => a === 'city')[0]

      if (marketSizes.length > 0 && !marketSizes.includes(elem.market_size)) {
        return {}
      }
      if (beach) {
        if (!elem.beach_ind) {
          return {}
        }
      } 
      if (mountain) {
        if (!elem.mountain_ind) {
          return {}
        }
      } 
      if (ski) {
        if (!elem.ski_ind) {
          return {}
        }
      } 
      if (college) {
        if (!elem.college_ind) {
          return {}
        }
      } 
      if (river) {
        if (!elem.river_ind) {
          return {}
        }
      } 
      if (lake) {
        if (!elem.lake_ind) {
          return {}
        }
      } 
      if (nationalPark) {
        if (!elem.national_park_ind) {
          return {}
        }
      } 
      if (statePark) {
        if (!elem.state_park_ind) {
          return {}
        }
      } 
      if (themePark) {
        if (!elem.theme_park_ind) {
          return {}
        }
      } 
      if (historical) {
        if (!elem.historical_ind) {
          return {}
        }
      } 
      if (city) {
        if (!elem.city_ind) {
          return {}
        }
      } 
      if (regulationLevels && regulationLevels.length > 0) {
        if (!regulationLevels.filter((a: any) => a === elem.regulation_amount)[0]) {
          return {}
        }  
      }
      if (elem.valuation) {
        if (minPrice && !maxPrice && !(elem.valuation > minPrice)) {
          return {}
        }
        if (maxPrice && !minPrice && !(elem.valuation < maxPrice)) {
          return {}
        }
        if (maxPrice && minPrice && !(elem.valuation > minPrice && elem.valuation < maxPrice)) {
          return {}
        }
      }

      return {
        type: "Feature",
        properties: {
          market_id: elem.id,
          city: elem.city,
          state: elem.state,
        },
        geometry: {
          type: "Point",
          coordinates: [parseFloat(elem.center?.split(',')[0]), parseFloat(elem.center?.split(',')[1])]
        }
      };
    });
    return response;
  }, [global.tableRows]);

  const { clusters, supercluster } = useSupercluster({
    points: data,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  useEffect(() => {
    setClusters(clusters)
    setSuperCluster(supercluster)
  }, [clusters, supercluster])

  useEffect(() => {
    if (superclusterToUse && clustersToUse.length > 0) {
      let marketIds: any[] = [];
      for (let cluster of clustersToUse) {
        // Dont go crazy on this threshold for performance reasons
        if (cluster.properties.cluster_id) {
          const leaves = superclusterToUse.getLeaves(cluster.properties.cluster_id, 10000, 0);
          for (let leaf of leaves) {
            marketIds.push(leaf.properties.market_id)
          }
        }
        if (cluster.properties.market_id) {
            marketIds.push(cluster.properties.market_id)
        }
      }

      const getData = setTimeout(() => {
        dispatch.setFilters('ids', marketIds.length === 0 ? [''] : marketIds)
        track('Map Mode Zoom Search', {
          user: {
            name: localStorage.getItem('userName'),
            email: localStorage.getItem('email'),
          }
        })
        dispatch.search({ ids: marketIds.length === 0 ? [''] : marketIds });
      }, 2000)

      return () => clearTimeout(getData)
    }
  }, [clustersToUse, superclusterToUse]);

  return (
    <div>
      <ConfigureColumns></ConfigureColumns>
      <Stack
        spacing={1}
        direction='column'
        zIndex={1}
        position={'absolute'}
      >
        {/* <Switch {...label} defaultChecked /> */}

        <DashboardFiltersMapMode
          filterdStates={props.filters.state}
          filters={props.filters}
          useBedrooms={props.useBedrooms}
        ></DashboardFiltersMapMode>
      </Stack>
      <div
        style={{
          width: '100%',
          height: '70vh',
        }}
      >
        <GoogleMapReact
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat
            ]);
          }}
          onClick={(a) => { }}
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          key={'map'}
          bootstrapURLKeys={{
            key: 'AIzaSyDPWL6h3SpdOLOJxCm4iRK9I75BL4NIT_Y',
            language: 'en',
            region: 'US',
          }}
          center={{ lat: 33.64566, lng: -86.6836 }}
          defaultZoom={2}
          options={{
            zoomControlOptions: {
              position: 7,
            },
            styles: theme.palette.mode === 'dark' ? [{
              "featureType": "all",
              "elementType": "labels.text.fill",
              "stylers": [{
                "saturation": 36
              }, {
                "color": "#000000"
              }, {
                "lightness": 40
              }]
            }, {
              "featureType": "all",
              "elementType": "labels.text.stroke",
              "stylers": [{
                "visibility": "on"
              }, {
                "color": "#000000"
              }, {
                "lightness": 16
              }]
            }, {
              "featureType": "all",
              "elementType": "labels.icon",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "administrative",
              "elementType": "geometry.fill",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 20
              }]
            }, {
              "featureType": "administrative",
              "elementType": "geometry.stroke",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 17
              }, {
                "weight": 1.2
              }]
            }, {
              "featureType": "administrative",
              "elementType": "labels",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "administrative.country",
              "elementType": "all",
              "stylers": [{
                "visibility": "simplified"
              }]
            }, {
              "featureType": "administrative.country",
              "elementType": "geometry",
              "stylers": [{
                "visibility": "simplified"
              }]
            }, {
              "featureType": "administrative.country",
              "elementType": "labels.text",
              "stylers": [{
                "visibility": "simplified"
              }]
            }, {
              "featureType": "administrative.province",
              "elementType": "all",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "administrative.locality",
              "elementType": "all",
              "stylers": [{
                "visibility": "simplified"
              }, {
                "saturation": "-100"
              }, {
                "lightness": "30"
              }]
            }, {
              "featureType": "administrative.neighborhood",
              "elementType": "all",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "administrative.land_parcel",
              "elementType": "all",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [{
                "visibility": "simplified"
              }, {
                "gamma": "0.00"
              }, {
                "lightness": "74"
              }]
            }, {
              "featureType": "landscape",
              "elementType": "geometry",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 20
              }]
            }, {
              "featureType": "landscape.man_made",
              "elementType": "all",
              "stylers": [{
                "lightness": "3"
              }]
            }, {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 21
              }]
            }, {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [{
                "visibility": "simplified"
              }]
            }, {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 17
              }]
            }, {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 29
              }, {
                "weight": 0.2
              }]
            }, {
              "featureType": "road.arterial",
              "elementType": "geometry",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 18
              }]
            }, {
              "featureType": "road.local",
              "elementType": "geometry",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 16
              }]
            }, {
              "featureType": "transit",
              "elementType": "geometry",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 19
              }]
            }, {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [{
                "color": "#000000"
              }, {
                "lightness": 17
              }]
            }] : []
          }}
        >
          {clustersToUse &&
            clustersToUse.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const {
                cluster: isCluster,
                point_count: pointCount
              } = cluster.properties;

              if (isCluster) {
                let size = (pointCount * 20) / data.length;
                if (pointCount && pointCount !== 0) {
                  return (
                    <Marker
                      lat={latitude}
                      lng={longitude}
                      key={`cluster-${cluster.id}`}
                      className="cluster-marker"
                    >
                      <div
                        className="cluster-marker"
                        style={{ width: size + "px", height: size + "px" }}
                        onClick={() => {
                          const expansionZoom = Math.min(
                            superclusterToUse.getClusterExpansionZoom(cluster.id),
                            20
                          );
                          mapRef.current.setZoom(expansionZoom);
                          mapRef.current.panTo({ lat: latitude, lng: longitude });


                        }}
                      >
                        {pointCount}
                      </div>
                    </Marker>
                  );
                }

              }
              else {
                return (
                  <MapModeMarker 
                      data={{
                        city: cluster.properties.city,
                        state: cluster.properties.state
                      }}                    
                      lat={latitude}
                      lng={longitude}/>
                );
              }
            })}
        </GoogleMapReact>

        <div style={{ marginTop: 20 }}>
          <Accordion className='dashboard-filterer-container-map-mode'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Results</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MarketTable
                rows={global.tableRows}
                headerCells={global.headerCells}
                useBedrooms={props.useBedrooms}
                revenuePercentile={props.revenuePercentile}
                mapMode={true}
              ></MarketTable>
            </AccordionDetails>
          </Accordion>
        </div>

      </div>
    </div>
  )
}
