import { useState } from 'react'
import {useLocation} from "react-router-dom";

const useConferenceMode = () => {
  const search = useLocation().search
  const [isConferenceMode, setIsConferenceMode] = useState(() => {
    if (sessionStorage.getItem('conferenceMode') === 'true') {
      return true
    }

    const conferenceMode = new URLSearchParams(search).get('conferenceMode') !== null
    sessionStorage.setItem('conferenceMode', conferenceMode ? 'true' : 'false')
    return conferenceMode
  })
  return isConferenceMode
}

export default useConferenceMode
