import React, { useState, useEffect, useContext } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import InputAdornment from '@mui/material/InputAdornment'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Modal, Paper, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import './filters.css'
import { strFetch } from '../../utils/api'
import _ from 'lodash'
import { useSearchParams, createSearchParams, useNavigate, useLocation } from 'react-router-dom'
import useDemoMode from 'hooks/useDemoMode'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import useGlobal from 'hooks/useGlobal'
import { track } from 'mixpanel'
import { FilterRounded } from '@mui/icons-material'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />
const regulations = ['Little to none', 'Moderate', 'Heavy', 'Restricted']
const destinations = [
  'beach',
  'mountain',
  'ski',
  'college',
  'river',
  'lake',
  'national park',
  'state park',
  'theme park',
  'historical',
  'city',
]

const marketSizes = ['small', 'medium', 'large']

// 0 is not available as we don't need to show 
// Studios that are out in the market
const bedroomCounts = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
]

const regions = [
  'northeast',
  'new england',
  'mid-atlantic',
  'midwest',
  'south',
  'mountain west',
  'pacific',
]

const states = [
  {
    state: 'Iowa',
    abbreviation: 'IA',
    region: 'Midwest',
  },
  {
    state: 'Illinois',
    abbreviation: 'IL',
    region: 'Midwest',
  },
  {
    state: 'Indiana',
    abbreviation: 'IN',
    region: 'Midwest',
  },
  {
    state: 'Kansas',
    abbreviation: 'KS',
    region: 'Midwest',
  },
  {
    state: 'Michigan',
    abbreviation: 'MI',
    region: 'Midwest',
  },
  {
    state: 'Minnesota',
    abbreviation: 'MN',
    region: 'Midwest',
  },
  {
    state: 'Missouri',
    abbreviation: 'MO',
    region: 'Midwest',
  },
  {
    state: 'North Dakota',
    abbreviation: 'ND',
    region: 'Midwest',
  },
  {
    state: 'Nebraska',
    abbreviation: 'NE',
    region: 'Midwest',
  },
  {
    state: 'Ohio',
    abbreviation: 'OH',
    region: 'Midwest',
  },
  {
    state: 'South Dakota',
    abbreviation: 'SD',
    region: 'Midwest',
  },
  {
    state: 'Wisconsin',
    abbreviation: 'WI',
    region: 'Midwest',
  },
  {
    state: 'Connecticut',
    abbreviation: 'CT',
    region: 'Northeast',
  },
  {
    state: 'Delaware',
    abbreviation: 'DE',
    region: 'Northeast',
  },
  {
    state: 'Massachusetts',
    abbreviation: 'MA',
    region: 'Northeast',
  },
  {
    state: 'Maine',
    abbreviation: 'ME',
    region: 'Northeast',
  },
  {
    state: 'Maryland',
    abbreviation: 'MD',
    region: 'Northeast',
  },
  {
    state: 'New Hampshire',
    abbreviation: 'NH',
    region: 'Northeast',
  },
  {
    state: 'New Jersey',
    abbreviation: 'NJ',
    region: 'Northeast',
  },
  {
    state: 'New York',
    abbreviation: 'NY',
    region: 'Northeast',
  },
  {
    state: 'Pennsylvania',
    abbreviation: 'PA',
    region: 'Northeast',
  },
  {
    state: 'Rhode Island',
    abbreviation: 'RI',
    region: 'Northeast',
  },
  {
    state: 'Vermont',
    abbreviation: 'VT',
    region: 'Northeast',
  },
  {
    state: 'Alabama',
    abbreviation: 'AL',
    region: 'South',
  },
  {
    state: 'Arkansas',
    abbreviation: 'AR',
    region: 'South',
  },
  {
    state: 'District of Columbia',
    abbreviation: 'DC',
    region: 'South',
  },
  {
    state: 'Florida',
    abbreviation: 'FL',
    region: 'South',
  },
  {
    state: 'Georgia',
    abbreviation: 'GA',
    region: 'South',
  },
  {
    state: 'Kentucky',
    abbreviation: 'KY',
    region: 'South',
  },
  {
    state: 'Louisiana',
    abbreviation: 'LA',
    region: 'South',
  },
  {
    state: 'Mississippi',
    abbreviation: 'MS',
    region: 'South',
  },
  {
    state: 'North Carolina',
    abbreviation: 'NC',
    region: 'South',
  },
  {
    state: 'Oklahoma',
    abbreviation: 'OK',
    region: 'South',
  },
  {
    state: 'South Carolina',
    abbreviation: 'SC',
    region: 'South',
  },
  {
    state: 'Tennessee',
    abbreviation: 'TN',
    region: 'South',
  },
  {
    state: 'Texas',
    abbreviation: 'TX',
    region: 'South',
  },
  {
    state: 'Virginia',
    abbreviation: 'VA',
    region: 'South',
  },
  {
    state: 'West Virginia',
    abbreviation: 'WV',
    region: 'South',
  },
  {
    state: 'Alaska',
    abbreviation: 'AK',
    region: 'West',
  },
  {
    state: 'Arizona',
    abbreviation: 'AZ',
    region: 'West',
  },
  {
    state: 'California',
    abbreviation: 'CA',
    region: 'West',
  },
  {
    state: 'Colorado',
    abbreviation: 'CO',
    region: 'West',
  },
  {
    state: 'Hawaii',
    abbreviation: 'HI',
    region: 'West',
  },
  {
    state: 'Idaho',
    abbreviation: 'ID',
    region: 'West',
  },
  {
    state: 'Montana',
    abbreviation: 'MT',
    region: 'West',
  },
  {
    state: 'New Mexico',
    abbreviation: 'NM',
    region: 'West',
  },
  {
    state: 'Nevada',
    abbreviation: 'NV',
    region: 'West',
  },
  {
    state: 'Oregon',
    abbreviation: 'OR',
    region: 'West',
  },
  {
    state: 'Utah',
    abbreviation: 'UT',
    region: 'West',
  },
  {
    state: 'Washington',
    abbreviation: 'WA',
    region: 'West',
  },
  {
    state: 'Wyoming',
    abbreviation: 'WY',
    region: 'West',
  },
]

interface DashboardFilterProps {
  filters: any
  filterdStates?: string[]
  useBedrooms: boolean
}

const DashboardFilters = (props: DashboardFilterProps) => {
  let isDemo = useDemoMode()
  const search = useLocation().search
  const destinationTypesParam = new URLSearchParams(search).get('dts')
  const bedroomCountsParam = new URLSearchParams(search).get('bcs')
  const marketSizesParam = new URLSearchParams(search).get('mszs')
  const statesParam = new URLSearchParams(search).get('sts')
  const citiesParam = new URLSearchParams(search).get('cts')
  const regulationAmountsParam = new URLSearchParams(search).get('rgamts')
  const minPriceParam = new URLSearchParams(search).get('mnprc')
  const maxPriceParam = new URLSearchParams(search).get('mxprc')

  const defaultFilterStateGroupsOpen = {
    West: false,
    Midwest: false,
    Northeast: false,
    South: false,
  }

  const defaultFilterStateGroupsChecked = {
    West: false,
    Midwest: false,
    Northeast: false,
    South: false,
  }
  const sendDataToGTM = useGTMDispatch()
  const [showDemoModal, setShowDemoModal] = useState(false)

  const [filterCities, setFilterCities] = useState<string[]>([])
  const [filterStateGroupsOpen, setFilterStateGroupsOpen] = useState<any>(
    defaultFilterStateGroupsOpen
  )
  const [filterStateGroupsChecked, setFilterStateGroupsChecked] = useState<any>(
    defaultFilterStateGroupsChecked
  )
  const [params, setParams] = useState<any>({})
  const [filters, setFilters] = useState<any>({})

  const { state: global, dispatch } = useGlobal()

  let navigate = useNavigate()

  useEffect(() => {
    ; (async function getData() {
      dispatch.setFilters('ids', null)

      if (isDemo) {
        return
      }
      const strippedFilters = _.omit(props.filters, 'cities')
      const result = await strFetch(`/getFilterCities`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filterdStates: props.filterdStates,
          filters: strippedFilters,
        }),
      })
      const cityRows = result.map((city: any) => city.city_name)
      setFilterCities(cityRows.sort())
    })()
  }, [props.filterdStates])

  useEffect(() => {
    let filters: any = {}

    if (destinationTypesParam) {
      filters.destinationType = destinationTypesParam?.split(',');
      params.dts = destinationTypesParam;
      setParams(params)
    }

    if (marketSizesParam) {
      filters.marketSize = marketSizesParam?.split(',');
      params.mszs = marketSizesParam;
      setParams(params)
    } else if (global.filters.marketSize) {
      filters.marketSize = global.filters.marketSize
      params.mszs = global.filters.marketSize.join(',');
      setParams(params)
    }

    if (bedroomCountsParam) {
      filters.bedroomCounts = bedroomCountsParam?.split(',').map(a => Number.parseInt(a));
      params.bcs = bedroomCountsParam
      setParams(params)
    }

    if (statesParam) {
      filters.state = statesParam?.split(',');
      params.sts = statesParam;
      setParams(params)
    }

    if (citiesParam) {
      filters.cities = citiesParam?.split(',');
      params.cts = citiesParam;
      setParams(params)
    }

    if (regulationAmountsParam) {
      filters.regulationLevels = regulationAmountsParam?.split(',');
      params.rgamts = regulationAmountsParam;
      setParams(params)
    }

    if (minPriceParam) {
      filters.minPrice = minPriceParam;
      params.mnprc = minPriceParam;
      setParams(params)
    }

    if (maxPriceParam) {
      filters.maxPrice = maxPriceParam;
      params.mxprc = maxPriceParam;
      setParams(params)
    }

    setFilters(filters)
    if (bedroomCountsParam) {
      dispatch.setUseBedrooms(true)
    }
    dispatch.search({ filters, useBedrooms: bedroomCountsParam ? true : false });
  }, [])

  function sendToGTM() {
    sendDataToGTM({
      event: 'DemoGetStarted',
    })
  }

  function doDashSearch() {
    const options = {
      pathname: '/',
      search: `?${createSearchParams(params)}`,
    };
    navigate(options, { replace: true });

    let property: keyof typeof filters;

    for (property in filters) {
      if (Array.isArray(filters[property]) && filters[property].length === 0) {
        delete filters[property]
      }
    }

    dispatch.search({ filters });
    track('Dashboard Search', {
      user: {
        name: localStorage.getItem('userName'),
        email: localStorage.getItem('email'),
      }
    })
  }

  return (
    <Container maxWidth={'md'}>
      <Paper
        className='dashboard-filterer-container'
        sx={{ pb: 6 }}
      >
        <Modal
          disableAutoFocus={true}
          open={showDemoModal}
          onClose={() => setShowDemoModal(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Get Started!
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2 }}>
              Sign up to filter by city
            </Typography>
            <Button
              variant='contained'
              style={{ marginTop: 20 }}
              onClick={() => {
                sendToGTM()
                setShowDemoModal(false)
                navigate('/login')
              }}
            >
              Get Started
            </Button>
          </Box>
        </Modal>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ width: '100%' }}
            spacing={2}
            justifyContent='space-evenly'
          >
            <Autocomplete
              size='small'
              sx={{ flexGrow: 1, flexBasis: 0 }}
              multiple
              id='destination-types'
              options={destinations}
              disableCloseOnSelect
              value={params.dts ? params.dts.split(',') : []}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ textTransform: 'capitalize' }}
              renderInput={(params) => (
                <TextField {...params} label='Destination Types' />
              )}
              onChange={(event: any, value: string[]) => {
                dispatch.setFilters('destinationType', value)
                params.dts = value.join(',');
                filters.destinationType = value;
                setFilters(filters)
                setParams(params)
              }}
            />
            <Autocomplete
              size='small'
              sx={{ flexGrow: 1, flexBasis: 0 }}
              multiple
              disabled={!global.useBedrooms}
              value={params.bcs ? params.bcs.split(',') : []}
              id='bedroom-count'
              options={bedroomCounts}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ textTransform: 'capitalize' }}
              renderInput={(params) => (
                <TextField {...params} label='Bedroom Counts' />
              )}
              onChange={(event: any, value: string[]) => {
                dispatch.setFilters('bedroomCounts', value.map(a => Number.parseInt(a)))
                params.bcs = value.join(',');
                filters.bedroomCounts = value.map(a => Number.parseInt(a));
                setFilters(filters)
                setParams(params)
              }}
            />
            <Autocomplete
              size='small'
              sx={{ flexGrow: 1, flexBasis: 0 }}
              multiple
              id='market-sizes'
              value={params.mszs ? params.mszs.split(',') : []}
              options={marketSizes}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ textTransform: 'capitalize' }}
              renderInput={(params) => (
                <TextField {...params} label='Market Size' />
              )}
              onChange={(event: any, value: string[]) => {
                dispatch.setFilters('marketSize', value)
                params.mszs = value.join(',');
                filters.marketSize = value;
                setFilters(filters)
                setParams(params)
              }}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ width: '100%' }}
            spacing={2}
          >
            <Stack
              direction={{ xs: 'row', sm: 'row' }}
              sx={{ width: '100%' }}
              spacing={2}
            >
              <Autocomplete
                size='small'
                sx={{ flexGrow: 1, flexBasis: 0 }}
                multiple
                id='filter-cities'
                options={states}
                disableCloseOnSelect
                filterOptions={createFilterOptions({ limit: 100 })}
                groupBy={(option) => option.region}
                value={params.sts ? params.sts.split(',') : []}
                limitTags={3}
                isOptionEqualToValue={() => true} //we're just controlling this, function here to suppress warnings
                renderTags={(value: any[], tagProps: any) => {
                  let selectedStates = filters.state || []

                  return selectedStates.map((selectedState: any) => (
                    <Chip
                      key={selectedState}
                      label={selectedState}
                      onDelete={(value: any) => {
                        let selectedStates = filters.state || []
                        selectedStates = selectedStates.filter(
                          (f: any) => f !== selectedState
                        )
                        dispatch.setFilters('state', selectedStates)
                        params.sts = selectedStates.join(',');
                        filters.state = selectedStates;
                        setFilters(filters)
                        setParams(params)
                      }}
                    />
                  ))
                }}
                onChange={(event, newInputValue, reason) => {
                  if (reason === 'clear') {
                    dispatch.setFilters('state', [])
                    params.sts = '';
                    filters.state = [];
                    setFilters(filters)
                    setParams(params)

                    setFilterStateGroupsChecked(defaultFilterStateGroupsChecked)
                    setFilterStateGroupsOpen(defaultFilterStateGroupsOpen)
                  }
                }}
                renderGroup={(group) => (
                  <div>
                    <li
                      style={{
                        background: 'rgba(222, 222, 230, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      className='group-list'
                      onClick={() => {
                        setFilterStateGroupsOpen({
                          ...filterStateGroupsOpen,
                          [group.group]: !filterStateGroupsOpen[group.group],
                        })
                      }}
                    >
                      <span>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={filterStateGroupsChecked[group.group]}
                          onChange={(
                            event: SelectChangeEvent,
                            checked: boolean
                          ) => {
                            let values = filters.state || []
                            let statesInRegion = states
                              .filter((f) => f.region === group.group)
                              .map((s) => s.state)
                            if (checked) {
                              values = _.uniq(values.concat(statesInRegion))
                            } else {
                              values = values.filter(
                                (f: any) => !statesInRegion.includes(f)
                              )
                            }
                            dispatch.setFilters('state', values)
                            params.sts = values.join(',');
                            filters.state = values;
                            setFilters(filters)
                            setParams(params)

                            setFilterStateGroupsChecked({
                              ...filterStateGroupsChecked,
                              [group.group]:
                                !filterStateGroupsChecked[group.group],
                            })
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                        {group.group}
                      </span>
                      {filterStateGroupsOpen[group.group] ? (
                        <ExpandLess sx={{ color: 'rgba(0,0,0,.6)' }} />
                      ) : (
                        <ExpandMore sx={{ color: 'rgba(0,0,0,.6)' }} />
                      )}
                    </li>
                    <Collapse in={filterStateGroupsOpen[group.group]}>
                      {group.children}
                    </Collapse>
                  </div>
                )}
                getOptionLabel={(option) => option.state}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{ marginLeft: '-15px !important' }}
                    className='compatct-list'
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={(filters.state || []).includes(
                        option.state
                      )}
                      onChange={(event: SelectChangeEvent, checked: boolean) => {
                        let values = filters.state || []
                        if (checked) {
                          values = values.concat(option.state)
                        } else {
                          values = values.filter((f: any) => f !== option.state)
                        }
                        dispatch.setFilters('state', values)
                        params.sts = values.join(',');
                        filters.state = values;
                        setFilters(filters)
                        setParams(params)

                      }}
                    />
                    {option.state}
                  </li>
                )}
                style={{ textTransform: 'capitalize' }}
                renderInput={(params) => {
                  return <TextField {...params} label='State' />
                }}
                onClick={(e) => e.stopPropagation()}
              />
              {!isDemo && (
                <Autocomplete
                  size='small'
                  sx={{ flexGrow: 1, flexBasis: 0 }}
                  multiple
                  id='filter-cities'
                  options={filterCities}
                  filterOptions={createFilterOptions({ limit: 100 })}
                  disableCloseOnSelect
                  value={params.cts ? params.cts.split(',') : []}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  style={{ textTransform: 'capitalize' }}
                  renderInput={(params) => <TextField {...params} label='City' />}
                  onChange={(event: any, value: string[]) => {
                    dispatch.setFilters('cities', value)
                    params.cts = value.join(',');
                    filters.cities = value;
                    setFilters(filters)
                    setParams(params)

                  }}
                  limitTags={3}
                />
              )}
              {isDemo && (
                <div
                  style={{ width: '100%', flexGrow: 1, flexBasis: 0 }}
                  onClick={() => setShowDemoModal(true)}
                >
                  <Autocomplete
                    size='small'
                    sx={{ flexGrow: 1, flexBasis: 0 }}
                    id='filter-cities'
                    options={[]}
                    disableCloseOnSelect
                    style={{ textTransform: 'capitalize' }}
                    renderInput={(params) => (
                      <TextField {...params} label='City' />
                    )}
                    limitTags={3}
                    readOnly
                  />
                </div>
              )}
            </Stack>
            <Autocomplete
              size='small'
              sx={{ flexGrow: 1, flexBasis: 0, minWidth: 175 }}
              multiple
              id='regulations'
              options={regulations}
              disableCloseOnSelect
              value={params.rgamts ? params.rgamts.split(',') : []}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ textTransform: 'capitalize' }}
              renderInput={(params) => (
                <TextField {...params} label='Regulation Amount' />
              )}
              onChange={(event: any, value: string[]) => {
                dispatch.setFilters('regulationLevels', value)
                params.rgamts = value.join(',');
                filters.regulationLevels = value;
                setFilters(filters)
                setParams(params)
              }}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ width: '100%' }}
            spacing={2}
          >
            <Stack
              direction={'row'}
              alignItems='center'
              sx={{ width: '100%' }}
              spacing={2}
            >
              <TextField
                id='price-from'
                placeholder='Min price'
                size='small'
                sx={{ flexGrow: 1, flexBasis: 0 }}
                type='number'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                }}
                value={params.mnprc}
                variant='outlined'
                onChange={(event: any) => {
                  const parsedValue = _.isNil(event.target.value)
                    ? 0
                    : event.target.value.replace(/[^0-9]/g, '')
                  dispatch.setFilters('minPrice', parsedValue)
                  params.mnprc = parsedValue;
                  filters.minPrice = parsedValue;
                  setFilters(filters)
                  setParams(params)

                }}
              />
              <div>to</div>
              <TextField
                id='price-to'
                placeholder='Max price'
                size='small'
                sx={{ flexGrow: 1, flexBasis: 0 }}
                type='number'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                }}
                value={params.mxprc}
                variant='outlined'
                onChange={(event: any) => {
                  const parsedValue = _.isNil(event.target.value)
                    ? 0
                    : event.target.value.replace(/[^0-9]/g, '')
                  dispatch.setFilters('maxPrice', parsedValue)
                  params.mxprc = parsedValue;
                  filters.maxPrice = parsedValue;
                  setFilters(filters)
                  setParams(params)

                }}
              />
            </Stack>
            <Button
              className='linear-gradient-styling'
              variant='contained'
              startIcon={<SearchIcon />}
              onClick={doDashSearch}
              size='medium'
              sx={{ flexGrow: 1, flexBasis: 0, minWidth: 200 }}
            >
              Search
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Container>

  )
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
}

export { DashboardFilters }
